import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  ButtonsWrapper,
  DownloadButton,
  DownloadWrapper,
} from "./DownloadSection.style";
// import styled from "styled-components";

const DownloadSection = () => {
  const { seeDetailsData } = useSelector(state => state.quotes);
  const { documents } = seeDetailsData;
  return (
    <>
      <DownloadWrapper>
        <Row>
          <Col md={12} lg={8}>
            <h6>Downloads</h6>
            <p style={{ fontSize: "15px" }}>
              To find out more about the company and its products, kindly refer
              the documents mentioned.
            </p>
          </Col>
          <Col md={12} lg={4}>
            <ButtonsWrapper className="d-flex justify-content-around">
              <DownloadButton href={documents.brochure_url} target={"_blank"}>
                Product Brochure{" "}
                <i className="far fa-arrow-alt-circle-down"></i>
              </DownloadButton>
              <DownloadButton href={documents.claim_url} target={"_blank"}>
                Claim Form
                <i
                  style={{ marginLeft: "5px" }}
                  className="far fa-arrow-alt-circle-down"
                ></i>
              </DownloadButton>
            </ButtonsWrapper>
          </Col>
        </Row>
      </DownloadWrapper>
    </>
  );
};

export default DownloadSection;
