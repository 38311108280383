import React from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  AdditionalFeatureCard,
  AdditionalFeatureWrap,
} from "./AdditionalFeatures.style";

const AdditionalFeatures = () => {
  const { additionalFeatures } = useSelector(
    state => state.quotes.seeDetailsData,
  );
  const [active, setActive] = React.useState("Grace Period");

  return (
    <Container>
      <AdditionalFeatureWrap>
        {Object.values(additionalFeatures).map(item => (
          <AdditionalFeatureCard
            onMouseOver={() => setActive(item.feature_key_title)}
            onMouseOut={() => setActive("")}
            //onClick={() => setActive(item.feature_key_title)}
            active={active === item.feature_key_title}
          >
            <span
              active={active === item.feature_key_title}
              className="additional-features-title"
            >
              {item.feature_key_title}
            </span>

            <p className="additional-features-description">
              {item.feature_key_description}
            </p>
          </AdditionalFeatureCard>
        ))}
      </AdditionalFeatureWrap>
    </Container>
  );
};

export default AdditionalFeatures;
