import styled from "styled-components";
import * as mq from "../../../../styles/mediaQueries";
export const AdditionalFeatureWrap = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  ${mq.sm} {
    flex-direction: column;
    margin-bottom: 80px;
    padding-top: 60px;
  }
`;
export const AdditionalFeatureCard = styled.div`
  width: calc(50% - 16px);
  height: auto;
  margin-bottom: 24px;
  border-radius: 8px;
  border: 1px solid transparent;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 20px;

  ${mq.sm} {
    border: ${props =>
      props.active === true && "1px solid var(--primary-color-dark)"};
  }

  &:hover {
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    border: 1px solid var(--primary-color-dark);
    .additional-features-title {
      color: var(--primary-color);
    }
  }
  & .additional-features-title {
    font-family: Inter;
    font-weight: 700;
    font-size: 18px;
    color: #000;
    ${mq.sm} {
      font-size: 15px;
      font-weight: 600;
      color: ${props => props.active === true && "var(--primary-color)"};
    }
  }
  & .additional-features-description {
    font-family: Inter;
    font-weight: 400;
    font-size: 13px;
    color: var(--primary-shade);
    margin: 20px 0;
    padding-right: 10px;
    ${mq.sm} {
      font-size: 12px;
      margin-bottom: 0;
      margin-top: 5px;
    }
  }

  ${mq.sm} {
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
    height: 100%;
  }
`;
