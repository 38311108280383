import React from "react";
import styled from "styled-components/macro";
import Navbar from "../../../components/navbar/Navbar-component";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import { StyledBackButton } from "./TermLifeInsurance";

const RidersInformation = () => {
  const { termquotepage } = useSelector(
    state => state.frontendBoot.frontendData,
  );
  return (
    <div className="life-insurance">
      <Navbar />
      <Wrap className="container">
        <StyledBackButton toPage="/quote" />
        {termquotepage?.riderdefinitiondata ? (
          parse(termquotepage.riderdefinitiondata)
        ) : (
          <div
            className="container"
            css={`
              margin-top: 20%;
              text-align: center;
              font-size: 30px;
              font-weight: 600;
            `}
          >
            No Data
          </div>
        )}
      </Wrap>
    </div>
  );
};

export default RidersInformation;

const Wrap = styled.div`
  padding: 20px 40px;
  margin-bottom: 10px;
  font-family: "Inter" !important;
  font-weight: 400;
  h2 {
    font-size: 24px;
    margin-top: 50px;
    font-weight: 700;
    color: rgb(74, 89, 113);
  }
  p {
    width: 80%;
    font-size: 15px;
  }
  table,
  td,
  th {
    border: 2px solid rgb(190, 190, 190);
  }

  tr td:first-child {
    width: 25%;
  }

  table {
    width: 80% !important;
    border-collapse: collapse;
    margin-top: 40px;
  }
  td {
    padding: 10px;
    font-size: 14px;
  }
  th {
    padding: 10px;
    font-size: 16px;
    font-weight: 700;
  }
  li {
    font-size: 14px;
  }
`;
