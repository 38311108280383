import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import styled from "styled-components";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#e8e8e8",
    color: "var(--primary-color)",
    maxWidth: 220,
    fontSize: "12px !important",
    border: "1px solid #dadde9",
  },
}));

export default HtmlTooltip;
