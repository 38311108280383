import { useEffect, useState } from "react";
import CancelCircular from "../../../assets/svg-icons/CancelCircular";
import { Modal } from "react-bootstrap";
import styled from "styled-components/macro";
import { ShareQuotePopup } from "../components/ShareQuotePopup";
import * as mq from "../../../styles/mediaQueries";
import { useSelector } from "react-redux";
import {
  selectDifferentQuotes,
  selectQuotes,
  selectSelectedQuotesToShare,
  setSelectedQuotesToShare,
  setShareQuotesCanvas,
} from "../Quotes/quotes.slice";
import { Checkbox } from "@material-ui/core";
import QuotesTable from "./QuotesTable";
import { useHistory, useLocation } from "react-router-dom";
import useUrlQuery from "../../../custom-hooks/useUrlQuery";
import { useDispatch } from "react-redux";
import ShareQuotesMobile from "./ShareQuotesMobile";
import useMediaQuery from "../../../custom-hooks/useMediaQuery";
import { capitalize } from "../../../utils";
import ShareQuotesCanvas from "./ShareQuotesCanvas";
// import { border } from "@mui/system";

const ShareQuotesCard = ({
  show,
  handleClose,
  currentSection,
  setCurrentSection,
  // quoteData,
  isActive,
}) => {
  // const { data, isLoading, isFetching } = useGetQuoteQuery({
  //   company_alias,
  //   termFilters,
  //   differentQuote: false,
  // });
  //  const quotes = useSelector(selectQuotes);

  const dispatch = useDispatch();
  const [previous, setPrevious] = useState(null);
  const [shareAll, setShareAll] = useState(false);
  const [selected, setSelected] = useState([]);
  const { selectCompareQuoteToShare } = useSelector(state => state?.newCompare);
  const location = useLocation();
  const isNewCompare = location.pathname.includes("new-compare");

  const quotes = useSelector(selectQuotes);
  const differentQuotes = useSelector(selectDifferentQuotes);
  //for diff quotes
  let diffQuotes = Object.keys(differentQuotes).map(
    item => differentQuotes[item],
  );
  let differentProductIds = diffQuotes.map(
    item => item !== null && item[0]?.product?.id,
  );
  let differentQuotesData = diffQuotes
    .map(quote => quote !== null && quote[0])
    .filter(item => item !== false);
  //for normal quotes
  let usualQuotes = Object.keys(quotes).map(item => quotes[item]);
  let usualProductIds = usualQuotes
    .map(
      item =>
        item !== null &&
        (item.length === 1
          ? item[0]?.product?.id
          : item.map(i => i?.product?.id)),
    )
    .flat();
  let usualQuotesData = usualQuotes
    .map(
      quote =>
        quote !== null && (quote.length === 1 ? quote[0] : quote.map(i => i)),
    )
    .flat()
    .filter(item => item !== false);
  //merged diff and normal quotes
  let allProductIds = [
    ...usualProductIds.filter(item => item !== false),
    ...differentProductIds.filter(item => item !== false),
  ].filter(item => item);

  let allQuotesData = [...usualQuotesData, ...differentQuotesData];
  const allProducts = useSelector(selectSelectedQuotesToShare);
  const { data } = useSelector(state => state.inputForm);
  const matches = useMediaQuery(`${mq.smForHook}`);

  useEffect(() => {
    if (shareAll) {
      dispatch(setSelectedQuotesToShare(allProductIds));
    } else dispatch(setSelectedQuotesToShare([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareAll]);
  const [nodesToSend, setNodesToSend] = useState({});
  const history = useHistory();
  const enquiryId = useUrlQuery("enquiryId");
  const proposerName = capitalize(data?.name?.split(" ")[0]);

  const handleReplace = () => {
    isActive && history.replace(`?enquiryId=${enquiryId}`);
  };

  useEffect(() => {
    if (currentSection === 1) {
      setPrevious(0);
    }
  }, [currentSection]);

  return (
    <div
      className="w-100 
    d-flex flex-column justify-content-center align-items-center bg-danger"
    >
      <Modal
        css={`
          & .modal-dialog {
            max-width: 1000px;
            ${mq.sm} {
              max-width: 100%;
              margin: 0.5em;
            }

            // max-width: ${currentSection !== 2 ? "1000px" : "650px"};
          }
        `}
        show={show}
        onHide={() => {
          handleClose();
          handleReplace();
        }}
        centered
      >
        <Modal.Header>
          <Modal.Title
            css={`
              font-size: 1.2rem;

              font-family: Inter;
              font-weight: 500;
              padding-right: 2.67em;
              ${mq.sm} {
                font-size: 1rem;
                padding-right: 0px;
              }

              &::before {
                content: "";
                position: absolute;
                height: 30px;
                width: 10px;
                top: 1;
                left: 0;
                background: #ffcb00;
                border-radius: 0 45px 45px 0;
              }
            `}
          >
            Hi {proposerName}, Please choose the way you wish to share your Life
            Insurance Quotes.
          </Modal.Title>
          <CancelCircular
            width="27"
            onClick={() => {
              handleClose();
              handleReplace();
            }}
            css={`
              position: absolute;
              right: 20px;
              top: 15px;
              ${mq.sm} {
                right: 1px;
                top: 2px;
              }
            `}
          />
        </Modal.Header>
        <Modal.Body
          css={`
            padding: 0.7rem;
            ${mq.sm} {
              padding: 0.7rem 0.2rem;
            }
            ${mq.md} {
              padding: 1.7rem 0.2rem;
            }
          `}
        >
          <div
            css={`
              display: flex;
              gap: 2em;
              justify-content: center;

              ${mq.sm} {
                flex-wrap: wrap;
                // flex-direction: column;
                align-items: center;
              }
            `}
          >
            {currentSection !== 0 && !shareAll && (
              <Progress contentToDisplay={currentSection} />
            )}
            {currentSection === 0 && (
              <>
                <div
                  css={`
                    padding: 1.7rem;
                    display: flex;
                    flex-direction: column;
                    gap: 1em;
                  `}
                >
                  <StyledButton
                    className="btn btn-primary"
                    onClick={() => {
                      setShareAll(true);
                      setCurrentSection(2);
                      setPrevious(0);
                    }}
                  >
                    Share All Quotes{" "}
                  </StyledButton>
                  <StyledButton
                    className="btn btn-outline-primary"
                    onClick={() => {
                      setShareAll(false);
                      setCurrentSection(1);
                      setPrevious(0);
                    }}
                  >
                    Share Selected Quotes Only
                  </StyledButton>
                </div>
              </>
            )}
          </div>

          {currentSection === 1 && matches && allProducts.length === 0 && (
            <>
              <div
                css={`
                  color: red;
                  display: inline-block;
                  position: relative;
                  top: 30px;
                `}
              >
                * Select atleast one quote.
              </div>
            </>
          )}
          {matches && currentSection === 1 && (
            <div
              css={`
                text-align: right;
                display: block;
              `}
            >
              <Checkbox
                checked={allProductIds.length === selected.length}
                onClick={e => {
                  if (e.target.checked) {
                    setSelected(allProductIds);
                  } else {
                    setSelected([]);
                  }
                }}
              />{" "}
              Select All
            </div>
          )}

          {currentSection === 1 && (
            <TableWrapper>
              {matches ? (
                <ShareQuotesMobile
                  allProductIds={allProductIds}
                  selected={selected}
                  setSelected={setSelected}
                  currentSection={currentSection}
                  allQuotesData={allQuotesData}
                />
              ) : (
                <QuotesTable
                  allProductIds={allProductIds}
                  allQuotesData={allQuotesData}
                />
              )}
            </TableWrapper>
          )}
          {currentSection === 2 && (
            <LastComp>
              {" "}
              <ShareQuotePopup
                currentSection={currentSection}
                shareAll={shareAll}
              />
            </LastComp>
          )}
          <ShareQuotesCanvas />
        </Modal.Body>
        <Modal.Footer
          css={`
            padding: 0;
            margin: 0;
          `}
        >
          {currentSection !== 0 && (
            <>
              <BackButton
                onClick={() => {
                  setCurrentSection(previous);
                }}
              >
                Back
              </BackButton>
            </>
          )}
          {currentSection === 1 && (
            <>
              {isNewCompare ? (
                <NextButton
                  className="btn btn-primary"
                  disabled={selectCompareQuoteToShare.length < 1}
                  onClick={() => {
                    setCurrentSection(2);
                    setPrevious(1);
                  }}
                >
                  Next
                </NextButton>
              ) : (
                <NextButton
                  css={`
                    background-color: ${currentSection === 1 &&
                    allProducts.length === 0 &&
                    "#dddddd !important"};
                    color: ${currentSection === 1 &&
                    allProducts.length === 0 &&
                    "var(--secondary-text) !important"};
                    border: ${currentSection === 1 &&
                    allProducts.length === 0 &&
                    "none"};
                  `}
                  className="btn btn-primary"
                  onClick={() => {
                    setCurrentSection(2);
                    setPrevious(1);
                  }}
                  disabled={currentSection === 1 && allProducts.length === 0}
                >
                  Next
                </NextButton>
              )}
            </>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default ShareQuotesCard;

function Progress({ contentToDisplay, ...props }) {
  return (
    <div
      css={`
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.3em;
        margin: 5px 0 10px 0;
        ${mq.sm} {
          font-size: 10px;
          margin: 0;
        }
      `}
      {...props}
    >
      <Dot state={contentToDisplay >= 1 ? "past" : "future"} />
      <ProgressText>Selected Plans</ProgressText>
      <Line state={contentToDisplay >= 2 ? "past" : "future"} />
      <Dot state={contentToDisplay >= 2 ? "past" : "future"} />
      <ProgressText>Share with</ProgressText>
    </div>
  );
}

const StyledButton = styled.button`
  width: 250px;
  padding: 10px;
  font-size: 15px;
`;
const NextButton = styled.button`
  padding: 5px 30px;
  margin-right: 25px;
  font-size: 15px;
  // background-color: ${props => props.disabled && "var(--primary-color)"};
  ${mq.sm} {
  }
`;

const TableWrapper = styled.div`
  ${mq.sm} {
    height: 340px;
    overflow-y: scroll;
  }
`;

const ProgressText = styled.div`
  font-weight: 700;
  font-size: 13px;
`;
const BackButton = styled.button`
  font-size: 15px;
  padding: 5px 30px;
  color: #5e5e5e;
`;

const PROGRESS_STATE_COLORS = {
  past: "var(--primary-color)",
  present: "white",
  future: "#dedede",
};

const Dot = styled.div`
  height: 1em;
  width: 1em;
  border-radius: 50%;

  background-color: ${({ state = "present" }) => PROGRESS_STATE_COLORS[state]};
`;

const Line = styled.div`
  height: 0.21em;
  width: 2em;
  border-radius: 1em;
  background-color: ${({ state = "present" }) => PROGRESS_STATE_COLORS[state]};
`;

const LastComp = styled.div`
  padding: 1rem 1rem 0;
  ${mq.sm} {
    padding-top: 2em;
    padding-left: 0px;
  }
`;
