import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { annualIncome, toWords } from "../../../../../utils";
import { setData } from "../../../input.slice";
import { Button, InputWrapper } from "../../../Landing.style";
import { form2 } from "../../../validations";
import "styled-components/macro";
import FormWithTitle from "../../FormWithTitle/FormWithTitle";
import { useHistory } from "react-router";
import Select from "../../Select/Select";
import * as mq from "../../../../../styles/mediaQueries";
import { patchEnquiries } from "../../../../product-page/Filters/serviceApi";
import useUrlQuery from "../../../../../custom-hooks/useUrlQuery";

const getLocalData = () => {
  const field_2 = localStorage.getItem("Eligibility Info");
  if (field_2) return JSON.parse(field_2);
  else
    return {
      term_annual_income: 1000000,
    };
};

const Eligibility = ({ setCurrent }) => {
  const { terminputpage } = useSelector(
    state => state.frontendBoot.frontendData,
  );
  const {
    frontendData: {
      termeducation,
      termoccupation,
      termageband,
      termannualincome,
    },
  } = useSelector(state => state.frontendBoot);
  const { data } = useSelector(state => state.inputForm);
  const patchData = useSelector(state => state.inputForm?.data);
  const {
    trigger,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(form2),
    defaultValues: getLocalData(),
  });
  const dispatch = useDispatch();
  const onSubmit = data => {
    dispatch(setData(data));
    localStorage.setItem(
      "Eligibility Info",
      JSON.stringify({
        education: data?.education,
        occupation: data.occupation,
        term_annual_income: termAnnualIncome,
      }),
    );
    if (terminputpage?.termsuitabilityflagstatus === "Yes")
      setCurrent(prev => prev + 1);
    else setCurrent(prev => prev + 2);
  };
  const education = watch("education");
  const occupation = watch("occupation");
  const termAnnualIncome = watch("term_annual_income");
  let age = moment().diff(moment(data.dob, "YYYY-MM-DD"), "years");
  const [ageBand] = termageband.filter(
    item => item.min <= age && item.max >= age,
  );
  useEffect(() => {
    let min = termannualincome[occupation + "+" + education]
      ? termannualincome[occupation + "+" + education][ageBand.band_name]
      : 2.5;
    if (min * 100000 > termAnnualIncome) {
      setValue("term_annual_income", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ageBand, education, occupation]);

  const occupationTypes = termoccupation.map(item => ({
    code: item.code,
    display_name: item.display_name,
  }));
  const educationTypes = termeducation.map(item => ({
    code: item.code,
    display_name: item.display_name,
  }));
  const termOptions = annualIncome(
    termannualincome[occupation + "+" + education]
      ? termannualincome[occupation + "+" + education][ageBand.band_name]
      : 2.5,
  ).map(item => ({
    code: item,
    display_name: toWords(item),
  }));
  const isEnquiry = window.location.href.includes("enquiryId");
  const history = useHistory();
  const enquiryId = useUrlQuery("enquiryId");
  return (
    <>
      <FormWithTitle
        title={
          terminputpage?.terminputpage2
            ? terminputpage?.terminputpage2
            : "<h1>Tell us about your professional details</h1>"
        }
        current={1}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <InputWrapper>
            <Select
              noSearch
              label="Occupation*"
              options={occupationTypes}
              error={errors.occupation && errors.occupation.message}
              handleChange={val => {
                setValue("occupation", val);
                trigger("occupation");
              }}
              selectedValue={occupation}
            />
          </InputWrapper>
          <InputWrapper>
            <Select
              label="Education*"
              options={educationTypes}
              error={errors.education && errors.education.message}
              handleChange={val => {
                setValue("education", val);
                trigger("education");
              }}
              noSearch
              selectedValue={education}
            />
          </InputWrapper>
          <InputWrapper>
            <Select
              noSearch
              label="Annual Income*"
              options={termOptions}
              error={
                errors.term_annual_income && errors.term_annual_income.message
              }
              handleChange={val => {
                setValue("term_annual_income", val);
                trigger("term_annual_income");
              }}
              selectedValue={termAnnualIncome}
            />
          </InputWrapper>
        </div>
        <div
          css={`
            display: flex;
            gap: 30px;
            width: 100%;
            margin: auto;
            ${mq.sm} {
              width: 100%;
              gap: 1px;
            }
          `}
        >
          <Button
            css={`
              flex: 1 1;
              background-color: rgba(220, 220, 220, 0.5);
              color: var(--secondary-text);
              font-weight: 900;
              height: 50px;
              width: 5rem;
              padding-top: 10px;
              ${mq.sm} {
                padding-top: 16px;
              }
            `}
            onClick={() => {
              setCurrent(prev => prev - 1);
            }}
          >
            Back
          </Button>
          {isEnquiry ? (
            <Button
              style={{
                display: "flex",
                flex: "1 1",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "900",
                height: "50px",
              }}
              onClick={async e => {
                e.preventDefault();
                await patchEnquiries({
                  input: {
                    ...patchData,
                  },
                }).then(() => {
                  history.push(`/quote?enquiryId=${enquiryId}`);
                });
              }}
            >
              View Quotes{" "}
            </Button>
          ) : (
            <Button
              style={{
                display: "flex",
                flex: "1 1",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "900",
                height: "50px",
              }}
              type="submit"
            >
              Continue{" "}
            </Button>
          )}
        </div>
      </FormWithTitle>
    </>
  );
};

export default Eligibility;
