import { useCallback, useEffect } from "react";
import { useState } from "react";
// import { useDidMountEffect } from "../../../custom-hooks";
import { useDispatch, useSelector } from "react-redux";
import { amount } from "../../../utils";
import {
  useGetQuoteQuery,
  useGetRiderPremiumMutation,
} from "../../riders/services/rider";
import useMediaQuery from "../../../custom-hooks/useMediaQuery";
import renderTooltip from "../../../components/Tooltip/Tooltip";
import "styled-components/macro";
import * as mq from "../../../styles/mediaQueries";
import { isCIRider, sendRiderFormat } from "../../riders/helpers";
import { Spin } from "../../product-page/Quotes/QuoteCard.style";
import {
  insertAddedRiders,
  removeAddedRiders,
  removeShortName,
  setDiabeticFalse,
  updateRiderListPremium,
  updateRiderShortName,
} from "../../product-page/Quotes/quotes.slice";
import { OverlayTrigger } from "react-bootstrap";
import ButtonLoader from "../../../components/CardSkeletonLoader/ButtonLoader/ButtonLoader";
import CardPopup from "../../riders/component/CardPopup";
import { useDidMountEffect } from "../../../custom-hooks";
function RiderAddRemove({
  item,
  product,
  addToReCalculateRiders,
  removeFromReCalculateRiders,
  hideRiders,
  addToHideRiders,
  removeFromHideRiders,
  recalculateRiders,
  productId,
  ...props
}) {
  const dispatch = useDispatch();
  const matches = useMediaQuery(`${mq.smForHook}`);
  const { product_id, different_quote, company_alias } = product;
  const [getRiderPremium, { isLoading: isRiderPremiumLoading }] =
    useGetRiderPremiumMutation();
  const [showDiabetic, setShowDiabetic] = useState(null);
  const { data: quoteData } = useGetQuoteQuery({
    company_alias,
    differentQuote: different_quote === "Y",
  });
  const {
    addedRiders,
    addedShortNames,
    shortNameToRemove,
    isDiabetic,
    ciVariant,
  } = useSelector(state => state.quotes);
  const termInsuranceId = quoteData?.data[0]?.term_insurance_id;
  const [showTooltip, setShowToolTip] = useState(false);
  const [finalRider, setFinalRider] = useState(item);
  useEffect(() => {
    if (item) setFinalRider(item);
  }, [item]);
  const { rider_shortname } = finalRider;
  const [CIVariant, setCIVariant] = useState(isCIRider(item) ? 22 : undefined);

  const rider = sendRiderFormat({
    policy_term: item.default_policy_term,
    sum_assured: item.default_sum_assured,
    premiumPayingTerm: item.default_ppt,
    extra_input: { ci_variant: isCIRider(item) ? ciVariant : CIVariant },
    ...item,
  });

  useEffect(() => {
    if (
      productId !== addedShortNames.lastFetched &&
      addedShortNames.data.includes(rider_shortname) &&
      company_alias !== "max_life"
    ) {
      if (item.rider_type === "Free") return;
      if (!isRiderAdded(rider)) add(rider);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedShortNames, productId, item, rider_shortname]);
  useEffect(() => {
    if (shortNameToRemove === rider_shortname && company_alias !== "max_life") {
      remove(rider);
      dispatch(removeShortName(""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shortNameToRemove, rider_shortname]);
  useEffect(() => {
    if (hideRiders.includes(rider_shortname) && isRiderAdded(rider)) {
      remove(rider);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideRiders]);

  const updatePremium = useCallback(() => {
    getRiderPremium({
      termInsuranceId,
      productId,
      riders: [
        {
          rider_shortname,
          rider_id: item.rider_id,
          sum_assured: item.default_sum_assured,
          policy_term: item.default_policy_term,
          premium_payment_term: item.default_ppt,
          total_premium: finalRider.net_premium,
          extra_input: { ci_variant: isCIRider(item) ? ciVariant : CIVariant },
        },
      ],
      addedRiders: addedRiders[productId]
        ? addedRiders[productId].map(item => ({
            total_premium: item.total_premium,
            rider_shortname: item.rider_shortname,
            rider_id: item.rider_id,
            sum_assured: item.term_sum_assured,
            policy_term: item.term_policy_term,
            premium_payment_term: item.term_limited_pay_term,
            premium: item.premium,
            extra_input: {
              ci_variant: isCIRider(item) ? ciVariant : CIVariant,
            },
            service_tax: item.service_tax,
          }))
        : [],
    }).then(res => {
      if (res.error) {
        return;
      }
      const {
        data: {
          data: [newRider],
        },
      } = res;
      if (newRider) setFinalRider(newRider);

      dispatch(
        updateRiderListPremium({
          rider_id: item.rider_id,
          data: newRider,
          productId,
        }),
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedRiders[productId], CIVariant]);

  useEffect(() => {
    if (recalculateRiders.includes(rider_shortname)) updatePremium();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recalculateRiders, rider_shortname, CIVariant]);

  useEffect(() => {
    if (showTooltip && matches) {
      setTimeout(() => {
        setShowToolTip(false);
      }, 4000);
    }
  }, [showTooltip, matches]);

  const isRiderAdded = rider => {
    if (!addedRiders[productId]) {
      return false;
    } else {
      const isAdded = !!addedRiders[productId].find(
        item => item.id === rider.id,
      );
      return isAdded;
    }
  };
  useDidMountEffect(() => {
    if (
      isRiderAdded(rider) &&
      isDiabetic &&
      company_alias === "max_life" &&
      rider.rider_shortname !== "ADB"
    ) {
      remove(rider);
    }
  }, [isRiderAdded, isDiabetic]);
  const add = rider => {
    dispatch(insertAddedRiders({ productId, rider }));
    if (item.hide_riders) {
      addToHideRiders(item.hide_riders);
    }
    if (item.recalc_rider) {
      addToReCalculateRiders(item.recalc_rider);
    }
    if (!addedShortNames.data.includes(rider_shortname))
      dispatch(
        updateRiderShortName({
          data: [...addedShortNames.data, rider_shortname],
          productId: productId,
        }),
      );
  };
  const remove = rider => {
    if (item.hide_riders) {
      removeFromHideRiders(item.hide_riders);
    }

    dispatch(
      removeAddedRiders({
        id: rider.id,
        productId,
      }),
    );
    if (item.recalc_rider) {
      removeFromReCalculateRiders(item.recalc_rider);
    }
    if (shortNameToRemove !== rider_shortname) {
      dispatch(removeShortName(rider_shortname));
    }
  };
  const onHandleAddOrRemove = rider => {
    if (isRiderAdded(rider)) {
      remove(rider);
    } else add(rider);
  };

  const toggleToolTip = () => {
    setShowToolTip(!showTooltip);
  };

  const isHidden = hideRiders.includes(rider_shortname);

  useDidMountEffect(() => {
    updatePremium();
  }, [CIVariant]);
  if (finalRider.rider_type === "Free") return <></>;
  // if (isHidden) return <></>;

  let rider_desc;
  if (
    item.rider_description != null &&
    (item.rider_shortname === "CC" ||
      item.rider_shortname === "CIP" ||
      item.rider_shortname === "CI" ||
      item.rider_shortname === "WOP")
  ) {
    rider_desc = item.rider_description.replace(/<\/?[^>]+(>|$)/g, "");
  } else rider_desc = item.rider_description;

  const closePopup = () => {
    setShowDiabetic(null);
    dispatch(setDiabeticFalse());
  };
  const isAdded = isRiderAdded(rider);
  const handleClick = evt => {
    if (
      company_alias === "max_life" &&
      ["CI", "CIP", "WOP"].includes(rider_shortname)
    ) {
      setShowDiabetic("diabetic");
      return;
    }
    evt.stopPropagation();
    onHandleAddOrRemove(rider);
  };
  return (
    <OverlayTrigger
      placement="bottom"
      show={showTooltip && !showDiabetic}
      delay={{ show: 0, hide: 100 }}
      overlay={renderTooltip({
        desc: rider_desc,
      })}
    >
      <div
        onMouseEnter={() => !matches && setShowToolTip(true)}
        onMouseLeave={() => !matches && setShowToolTip(false)}
        onClick={() => (!matches ? handleClick : toggleToolTip())}
        css={`
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 1em;
          padding: 0.5em 2em;
          font-size: 0.76rem;
          margin: 0 1em;
          margin-bottom: 1em;
          text-align: left;
          height: auto;
          background-color: #f1f2f3;
          ${mq.sm} {
            padding: 0.5em;
            font-size: 10px;
            min-height: 30px;
            height: auto;
            align-items: flex-start;
          }
        `}
        {...props}
      >
        <div
          css={`
            display: flex;
            align-items: center;
            gap: 1em;
            ${mq.sm} {
              gap: 0.5em;
              align-items: flex-start;
            }
          `}
        >
          {!isHidden ? (
            isRiderPremiumLoading ? (
              <Spin />
            ) : (
              <div>
                <ul className="list-group">
                  <li
                    className="list-group-item"
                    css={`
                      padding: 0px 0px;

                      height: 14px;
                      width: 14px;

                      ${mq.sm} {
                        padding: 0px 0px;
                      }
                    `}
                  >
                    <input
                      css={`
                        margin: 0px;
                        height: 15px;
                        width: 15px;
                        position: relative;
                        right: 2px;
                        bottom: 2px;
                        ${mq.sm} {
                          margin: 0px;
                        }
                      `}
                      className="form-check-input "
                      type="checkbox"
                      onClick={handleClick}
                      id={rider_shortname + product_id}
                      checked={isRiderAdded(rider)}
                      disabled={isHidden}
                      value=""
                      aria-label="..."
                    />
                  </li>
                </ul>

                {/* <input
                type="checkbox"
                onClick={e => {
                  e.stopPropagation();
                  onHandleAddOrRemove(rider);
                }}
                id={rider_shortname + product_id}
                checked={isRiderAdded(rider)}
                disabled={isHidden}
              /> */}
              </div>
            )
          ) : (
            <i className="fas fa-times" style={{ paddingRight: "4.5px" }}></i>
          )}

          <label>{item.rider_name}</label>
        </div>
        <div
          css={`
            min-width: max-content;
          `}
        >
          {isRiderPremiumLoading ? <ButtonLoader /> : amount(item.net_premium)}
        </div>
        <CardPopup
          show={showDiabetic === "diabetic"}
          handleClose={closePopup}
          handleContinue={() => onHandleAddOrRemove(rider)}
          rider={item}
          handleCICountChange={setCIVariant}
          isLoading={isRiderPremiumLoading}
          isAdded={isAdded}
          CIVariant={CIVariant}
        />
      </div>
    </OverlayTrigger>
  );
}

export { RiderAddRemove };
