//  import { debounce } from "@material-ui/core";
// import { useCallback, useEffect } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAge } from "../../../../components/navbar/helper";
import {
  selectFilterOptions,
  selectFilters,
  selectTermFilters,
} from "../../../product-page/Filters/Filters.slice";
import { setSelectedQuote } from "../../../product-page/Quotes/quotes.slice";
import { sendUpdatedProductFormat } from "../../helpers";
import { useGetCartQuery, useGetQuoteQuery } from "../../services/rider";

function usePlanCard() {
  const { data: cartData } = useGetCartQuery();

  const dispatch = useDispatch();
  const {
    data: quoteData,
    isLoading,
    isFetching,
  } = useGetQuoteQuery(
    {
      company_alias: cartData?.data?.product?.company.alias,
      differentQuote: false,
    },
    { refetchOnMountOrArgChange: true },
  );
  useEffect(() => {
    if (!isLoading && !isFetching && quoteData?.data[0]) {
      dispatch(setSelectedQuote(quoteData?.data[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isFetching]);
  const quote = quoteData?.data?.find(
    item => item.product.id === cartData?.data?.product?.id,
  );

  const selectedProduct = cartData;
  const premiumLoading = isLoading || isFetching;
  const ridersAdded = cartData?.term_riders;

  const filters = useSelector(selectFilters);
  // eslint-disable-next-line no-unused-vars
  const sendProductData = sendUpdatedProductFormat(quote, cartData);

  if (!cartData || !quoteData)
    return {
      totalGst: 0,
      totalPremium: 0,
      basePremium: 0,
      ridersAdded: [],
      quote: {},
      premiumLoading,
      selectedProduct: { product: {} },
      filters,
    };

  // const { total_premium: basePremium, tax_amount: basePremiumTax } = quote;

  const totalRidersPremium = ridersAdded.reduce(
    (sum, rider) => parseInt(sum) + parseInt(rider?.total_premium),
    0,
  );

  const totalRidersTax = ridersAdded.reduce(
    (taxSum, rider) => parseInt(taxSum) + parseInt(rider?.tax_amount),
    0,
  );

  const totalGst = parseInt(cartData?.service_tax) + parseInt(totalRidersTax);

  const totalPremium =
    parseInt(cartData?.total_premium) + parseInt(totalRidersPremium);

  return {
    totalGst,
    totalPremium,
    premiumLoading,
    basePremium: cartData?.total_premium - cartData?.service_tax,
    ridersAdded,
    selectedProduct,
    filters,
  };
}

const paymentOptions = {
  regularPay: "Regular Pay",
  limitedPay: "Limited Pay",
  singlePay: "Single Pay",
};

function usePlanDetails() {
  const { data: cartData } = useGetCartQuery();
  const { isLoading } = useGetQuoteQuery(
    {
      company_alias: cartData?.data?.product?.company.alias,
      differentQuote: false,
    },
    { refetchOnMountOrArgChange: true },
  );

  // const { totalPremium } = usePlanCard();
  const termFilters = useSelector(selectTermFilters);

  const { selectedQuote } = useSelector(state => state?.quotes);

  const { isPayTill60, term_pay_till } = selectedQuote;

  const {
    // term_sum_assured,
    // term_coverupto,
    term_pay_frequency,
  } = useSelector(selectFilterOptions);

  const {
    data: { dob },
  } = useSelector(state => state.inputForm);
  const age = parseInt(getAge(dob));
  // const coverAmountObject = term_sum_assured.find(
  //   sumAssured => sumAssured.code === termFilters.term_sum_assured,
  // );
  const coverAmountObject = () => {
    let nums = [];
    if (String(selectedQuote?.term_sum_assured).length >= 8) {
      let value = selectedQuote?.term_sum_assured / 10000000;
      nums.push({
        code: selectedQuote?.term_sum_assured,
        display_name: `${value} Crore`,
      });
    } else if (String(selectedQuote?.term_sum_assured).length >= 6) {
      let value = selectedQuote?.term_sum_assured / 100000;
      nums.push({
        code: selectedQuote?.term_sum_assured,
        display_name: `${value} Lacs`,
      });
    }
    return nums;
  };

  // const coverUptoObject = term_coverupto?.find(
  //   coverUpto => coverUpto?.code === termFilters?.term_coverupto,
  // );

  const coverUptoObject = selectedQuote?.term_coverupto;

  const payFrequencyObject = term_pay_frequency?.find(
    payFrequency =>
      parseInt(payFrequency.code) === termFilters.term_pay_frequency,
  );

  const [{ display_name: coverAmount }] = coverAmountObject();
  // const { code: coverUptoValue, display_name: coverUpto } = coverUptoObject;

  const { display_name: payFrequency } = payFrequencyObject || {};

  const limitedPayText = (term_pay_till, age, paytTill60) => {
    if (term_pay_till === 60 && paytTill60 === "Y") {
      return "Pay till 60 yrs";
    } else return `Limited Pay ${term_pay_till - age} `;
  };

  let paymentOption =
    term_pay_till === 1
      ? "Single Pay"
      : term_pay_till < coverUptoObject
      ? limitedPayText(term_pay_till, age, isPayTill60)
      : "Regular Pay";

  return {
    coverAmount,
    coverUptoObject,
    paymentOption,
    payFrequency,
    // isLoading: !totalPremium,
    isLoading,
  };
}

function redirectToIc(redirectionData) {
  if (redirectionData) {
    const { redirection_url, redirection_method, redirection_params } =
      redirectionData;

    const form = document.createElement("form");

    form.setAttribute("action", redirection_url);
    form.setAttribute("method", redirection_method);
    if (redirection_method === "POST") {
      Object.entries(redirection_params).forEach(([key, value]) => {
        const inputData = document.createElement("input");
        inputData.name = key;
        inputData.value = value;
        form.appendChild(inputData);
      });

      document.body.appendChild(form);
      form.submit();

      document.body.removeChild(form);
    } else if (redirection_method === "GET") {
      window.open(redirection_url, "_self");
    }
  }
}

export { usePlanCard, usePlanDetails, paymentOptions, redirectToIc };
