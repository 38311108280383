import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import telephone from "./../../../../assets/images/telephone.png";
import envelope from "./../../../../assets/images/envelope.png";
import "styled-components/macro";
import {
  ClaimDetails,
  ContactDetails,
  ContactDetailsWrapper,
  DocumentsDetail,
  SectionTitle,
} from "./ClaimProcess.style";
import "styled-components/macro";
import * as mq from "../../../../styles/mediaQueries";
const ClaimProcess = () => {
  const { otherDetails, claimProcess } = useSelector(
    state => state.quotes.seeDetailsData,
  );
  return (
    <>
      <Container>
        <ContactDetailsWrapper>
          <Row>
            <Col md={5}>
              <SectionTitle>Important Number and Email Address</SectionTitle>
            </Col>
            <Col md={7}>
              <ContactDetails>
                {" "}
                <section>
                  <img
                    className="contact-icon"
                    src={telephone}
                    alt="telephone"
                  />
                  <div
                    style={{
                      color: "#000",
                      width: "80%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span className="contact-details-heading">
                      Toll Free Number
                    </span>

                    <span className="contact-details-value">
                      {otherDetails.company_toll_free_number}
                    </span>
                  </div>
                </section>
                <section>
                  <img src={envelope} alt="envelope" />

                  <div
                    style={{
                      color: "#000",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span className="contact-details-heading">
                      Drop Us An Email
                    </span>

                    <span className="contact-details-value">
                      {otherDetails.company_email}
                    </span>
                  </div>
                </section>
              </ContactDetails>
            </Col>
          </Row>
        </ContactDetailsWrapper>
        <ClaimDetails>
          <SectionTitle>Claim Process</SectionTitle>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              margin: "10px auto",
            }}
          >
            <section className="claim-process-icons">
              <i
                css={`
                  flex: 1 1;
                `}
                className="fas fa-phone-alt"
              ></i>
              <i
                css={`
                  flex: 1 1;
                `}
                className="far fa-file-alt"
              ></i>
              <i
                css={`
                  flex: 1 1;
                `}
                className="fas fa-hand-holding-usd"
              ></i>
            </section>
            <div className="stepper-wrapper">
              <div className="stepper-item">
                <div className="step-counter">1</div>
                <div className="step-name">INTIMATE CLAIM</div>
              </div>
              <div className="stepper-item">
                <div className="step-counter">2</div>
                <div className="step-name">SUBMIT DOCUMENTS</div>
              </div>
              <div className="stepper-item">
                <div className="step-counter">3</div>
                <div className="step-name">SETTLEMENT OF CLAIM</div>
              </div>
            </div>
          </div>
          <div
            css={`
              font-family: Inter;
              font-weight: 400;
              font-size: 14px;
              ${mq.sm} {
                font-size: 12px;
                color: grey;
              }
            `}
          >
            {
              claimProcess.find(
                item => item.feature_key_title === "How to File a Claim",
              )?.feature_key_description
            }
          </div>
        </ClaimDetails>
        <DocumentsDetail>
          <SectionTitle>List of Documents</SectionTitle>
          <ol
            css={`
              white-space: pre;
              font-size: 14px;
              font-family: Inter;
              font-weight: 400;
              ${mq.sm} {
                font-size: 12px;
                margin-left: -20px;

                white-space: pre-line;
              }
            `}
            dangerouslySetInnerHTML={{
              __html: claimProcess.find(
                item => item.feature_key_title === "List Of Documents",
              )?.feature_key_description,
            }}
          ></ol>
          <div
            css={`
              font-size: 14px;
              font-family: Inter;
              font-weight: 500;
              ${mq.sm} {
                font-size: 13px;
              }
            `}
          >
            {
              claimProcess.find(
                item => item.feature_key_title === "List Of Documents",
              )?.feature_key_value
            }
          </div>
        </DocumentsDetail>
      </Container>
    </>
  );
};

export default ClaimProcess;
