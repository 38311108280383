import React, { useState } from "react";
import AutoComplete from "@mui/lab/Autocomplete";
import { useEffect } from "react";

import { styled } from "@material-ui/styles";
import TextInput from "../TextInput/TextInput";
import { createFilterOptions, Popper } from "@mui/material";
const Select = ({
  options = [{ code: "", display_name: "" }],
  handleChange = () => {},
  selectedValue,
  label,
  error,
  readOnly,
  ...props
}) => {
  const [value, setValue] = useState(null);

  const [inputValue, setInputValue] = useState("");
  const onChange = (e, value) => {
    setValue(value);
    handleChange(value.code);
  };
  useEffect(() => {
    if (selectedValue) {
      const x = options.find(item => item.code === selectedValue);
      setValue(x || null);
      setInputValue(x?.display_name || "");
    } else {
      setValue(null);
      setInputValue("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);
  const onInputChange = (e, newValue) => {
    setInputValue(newValue);
  };
  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: option => option.display_name,
  });
  return (
    <>
      {" "}
      {readOnly ? (
        <TextInput
          value={inputValue}
          label={label}
          inputProps={{ readOnly: true }}
        />
      ) : (
        <StyledAutoComplete
          filterOptions={filterOptions || ""}
          options={options}
          disablePortal
          disableClearable
          blurOnSelect
          onChange={onChange}
          onInputChange={onInputChange}
          getOptionLabel={item => item.display_name || ""}
          value={value}
          clearOnBlur
          PopperComponent={({ style, ...props }) => (
            <Popper {...props} style={{ ...style, height: 0 }} />
          )}
          ListboxProps={{ style: { maxHeight: "30vh" } }}
          inputValue={inputValue || ""}
          isOptionEqualToValue={(item, value) => item.code === value.code}
          renderInput={params => (
            <TextInput
              {...params}
              label={label}
              error={error}
              inputProps={{
                ...params.inputProps,
                readOnly: props?.noSearch,
              }}
            />
          )}
        />
      )}
    </>
  );
};
const StyledAutoComplete = styled(AutoComplete)({
  "& .MuiOutlinedInput-root": {
    padding: "0px 12px !important",
  },
  "& .MuiOutlinedInput-input": {
    padding: "0 !important",
  },
});
export default Select;
