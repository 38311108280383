import { InputAdornment, styled } from "@material-ui/core";
import { TextField } from "@mui/material";
import { useEffect } from "react";
import { AgeWrapper } from "../ProposalPage.style";

const TextInput = ({
  label,
  value,
  error,
  capitalize,
  age,
  adornment,
  extraMsg,
  ...props
}) => {
  const errorElem = document.querySelector(".Mui-error");
  useEffect(() => {
    if (errorElem) {
      errorElem.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [errorElem]);

  return (
    <>
      <CustomField
        label={label}
        variant={"outlined"}
        value={value || ""}
        fullWidth
        autoComplete="off"
        capitalize={capitalize}
        error={Boolean(error)}
        helperText={error}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <span style={{ color: "var(--primary-color)" }}>
                {adornment && adornment}
              </span>
            </InputAdornment>
          ),
        }}
        {...props}
      />

      {extraMsg && !error && <span>{extraMsg}</span>}
      {age > 0 && !error ? <AgeWrapper>{age + " years"}</AgeWrapper> : <></>}
    </>
  );
};

export default TextInput;
const CustomField = styled(TextField)({
  "& .MuiOutlinedInput-input": {
    padding: "14px 14px",
    backgroundColor: "rgb(255, 255, 255)",
    textTransform: prop => prop.capitalize && "capitalize",
    "@media (max-width: 576px)": {
      height: "1em !important",
    },
  },

  "& label.Mui-focused, & label.MuiFormLabel-filled, & label.Mui-focused.Mui-error":
    {
      color: "var(--primary-color)",
    },
  "& label.Mui-error": {
    color: "rgba(0, 0, 0, 0.6)",
  },

  "& .MuiOutlinedInput-root": {
    borderRadius: "0",
    "@media (max-width: 576px)": {
      fontSize: "14px !important",
    },

    "&.Mui-focused fieldset": {
      borderColor: "var(--primary-color)",
    },
    "&.Mui-focused.Mui-error fieldset": {
      borderColor: "#d32f2f",
    },
  },
});
