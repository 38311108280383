import React from "react";
import "styled-components/macro";
import { DynamicWrap, MessageContainer } from "../../Landing.style";
import * as mq from "../../../../styles/mediaQueries";
import { useState } from "react";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import { BiRightArrow } from "react-icons/bi";

const ListItem = ({ children }) => {
  return (
    <>
      <div
        css={`
          display: flex;
          align-items: center;

          margin-bottom: 12px;
          font-size: 16px;
          & i {
            color: var(--primary-color);
            display: inline;
          }
          & p {
            margin: 0px 8px;
            line-height: 16px;
            ${mq.sm} {
              margin: 0px;
              padding: 0 5px;
            }
          }
          ${mq.sm} {
            font-size: 9px;
            margin-bottom: 0;
          }
        `}
      >
        <i className="fas fa-check-circle"></i>
        <p>{children}</p>
      </div>
    </>
  );
};
const LandingMessage = () => {
  const { frontendData } = useSelector(state => state.frontendBoot);
  const [show, setShow] = useState(false);
  return (
    <>
      <div
        css={`
          width: 50%;
          padding: 0 20px;
          ${mq.sm} {
            width: 100%;
          }
          ${mq.md} {
            width: 100%;
            padding: 0;
          }
        `}
      >
        <MessageContainer>
          <h3>Term Insurance</h3>
          {frontendData?.terminputpage?.terminsurancedescription ? (
            <>
              <div
                css={`
                  display: flex;
                  gap: 5px;
                `}
              >
                <BiRightArrow
                  css={`
                    display: none;
                    ${mq.xs} {
                      display: inline;
                      height: 13px;
                      width: 13px;
                      cursor: pointer;
                      color: rgb(50, 50, 50);
                      transition: all 0.2s ease;
                      transform: ${show ? "rotate(90deg)" : "rotate(0deg)"};
                    }
                  `}
                  onClick={e => setShow(!show)}
                />
                <DynamicWrap show={show}>
                  {parse(frontendData?.terminputpage?.terminsurancedescription)}
                </DynamicWrap>
              </div>
            </>
          ) : (
            <>
              <h1>
                Buy Term Insurance plan in few simple steps
                <i
                  className="fas fa-info-circle down"
                  onClick={e => setShow(!show)}
                />
              </h1>
              <div
                css={`
                  display: auto;
                  ${mq.md} {
                    display: auto;
                    width: 100%;
                  }
                  ${mq.sm} {
                    display: auto;
                  }
                  ${mq.xs} {
                    display: ${show ? "block" : "none"};
                    width: 100%;
                    transition: all 0.2s ease;
                  }
                `}
              >
                <ListItem>Compare Term Insurance Plans</ListItem>
                <ListItem>Instant Policy Issuance</ListItem>
                <ListItem>Free Claims Assistance</ListItem>
                <ListItem>Get Plan Recommendation in Seconds</ListItem>
              </div>
            </>
          )}
        </MessageContainer>
      </div>
    </>
  );
};

export default LandingMessage;
