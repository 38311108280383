import React from "react";
import "styled-components/macro";
import { Modal } from "react-bootstrap";
// import { Accordion } from "@material-ui/core";
import CancleCirculerIcon from "../../../assets/svg-icons/CancelCircular";
import PlanCard from "./PlanCard/PlanCard";
const PlanCardPopup = ({ show, handleClose, isProposalFooter }) => {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        css={`
          animation-duration: 1s;
          animation-name: slidein;
          @keyframes slidein {
            from {
              margin-top: 200%;
            }
          }

          & .modal-dialog {
            position: absolute;
            bottom: 0;
            left: 0;
            margin: 0;
            width: 100%;
            max-width: 100%;
          }
          & .modal-content {
            border-top-left-radius: 35px;
            border-top-right-radius: 35px;
          }
        `}
      >
        {/* <Slide direction="up" in={show}> */}
        <CancleCirculerIcon
          width="27"
          onClick={() => handleClose()}
          style={{
            position: "absolute",
            right: "20px",
            top: "10px",
            zIndex: "100",
            background: "none",
            boxShadow: "none",
          }}
        />
        <Modal.Body
          css={`
            background: transparent;

            padding: 0;
          `}
        >
          {isProposalFooter ? <PlanCard hideBuy /> : <PlanCard />}
        </Modal.Body>
        {/* </Slide> */}
      </Modal>
    </>
  );
};

export default PlanCardPopup;
