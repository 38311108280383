import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { images } from "../../../assets/images/logo/logo";
import { getAnnualIncome } from "../../../components/navbar/helper";
import { usePlanDetails } from "../../riders/component/PlanCard/planCard.util";

const ShareQuotesCanvas = () => {
  const { shareQuotesCanvas } = useSelector(state => state.quotes);
  return (
    <CanvasWrapper>
      <div className="canvas" id="share-quotes">
        <ShareTableHeader>
          <ShareTableHeaderItem
            css={`
              width: 15%;
            `}
          >
            Company
          </ShareTableHeaderItem>
          <ShareTableHeaderItem
            css={`
              width: 22%;
            `}
          >
            Plan Name
          </ShareTableHeaderItem>
          <ShareTableHeaderItem>Sum Assured</ShareTableHeaderItem>
          <ShareTableHeaderItem>Cover Till Age</ShareTableHeaderItem>
          <ShareTableHeaderItem>Payment Type</ShareTableHeaderItem>
          <ShareTableHeaderItem>Buy</ShareTableHeaderItem>
        </ShareTableHeader>
        {shareQuotesCanvas?.map((quote, index) => (
          <CanvasQuote
            key={quote?.product?.id}
            quote={quote}
            company_alias={quote?.company_alias}
          />
        ))}
      </div>
    </CanvasWrapper>
  );
};
export default ShareQuotesCanvas;
const CanvasQuote = ({ quote: quoteData, company_alias }) => {
  const icLogo = images[company_alias];

  const { paymentOption } = usePlanDetails();
  console.log({ quoteData, icLogo, paymentOption });

  if (!quoteData || !quoteData?.company_alias) return <></>;
  return (
    <CanvasQuoteWrapper>
      <CanvasQuoteItem
        css={`
          width: 15%;
        `}
      >
        <img className="insurer" src={icLogo} alt="quote" />
      </CanvasQuoteItem>
      <CanvasQuoteItem
        css={`
          display: flex;
          flex-direction: column;
          width: 22%;
          align-items: flex-start;
        `}
      >
        <div>
          {quoteData?.product?.name?.split("-")?.length === 2
            ? quoteData?.product?.name?.split("-")[1]
            : quoteData?.product?.name?.split("-")[1] +
              "-" +
              quoteData?.product?.name?.split("-")[2]}
        </div>
        <div
          css={`
            font-size: 11px;
          `}
        >
          {quoteData?.product?.name?.split("-")[0]}
        </div>
      </CanvasQuoteItem>

      <CanvasQuoteItem>
        ₹{" "}
        {quoteData?.term_sum_assured
          ? getAnnualIncome(quoteData?.term_sum_assured)
          : "NO value"}
      </CanvasQuoteItem>

      <CanvasQuoteItem>
        {quoteData?.term_coverupto || "No value"} yrs
      </CanvasQuoteItem>
      <CanvasQuoteItem>{paymentOption}</CanvasQuoteItem>
      <BuyButton className="buy-btn">Buy</BuyButton>
    </CanvasQuoteWrapper>
  );
};

const CanvasWrapper = styled.div`
  width: 800px;
  position: absolute;
  left: -1000%;
  z-index: 9999999;
`;

const ShareTableHeader = styled.div`
  display: flex;

  background: var(--primary-color);
  padding: 8px;
  color: #fff;
`;

const ShareTableHeaderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
  width: 20%;
  font-weight: bold;
  font-size: 14px;
`;

const ShareQuoteItem = styled.div`
  padding: 8px;
  border-bottom: 1px solid #dcdcdc;
  align-items: center;

  & .insurer {
    height: 35px;
    width: 70px;
  }
`;

const BuyButton = styled.div`
  background: var(--primary-color);
  color: #fff;
  width: 20%;
  padding: 6px 5px;
  border-radius: 5px;
  font-size: 12px;
  text-align: center !important;
`;
const CanvasQuoteWrapper = styled(ShareQuoteItem)`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  border: 1px solid var(--primary-color);
  background: var(--primary-color-light);

  margin: 2px 0;

  & .insurer {
    border: 1px solid var(--primary-color);
    padding: 2px;
    border-radius: 5px;
    background: var(--primary-color-light);
  }
`;

const CanvasQuoteItem = styled.div`
  color: #000;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
  width: 20%;
  font-weight: bold;
  font-size: 14px;
`;
