import { createSlice } from "@reduxjs/toolkit";

const newCompare = createSlice({
  name: "newCompare",
  initialState: {
    insuranceCompany: {},
    product: {},
    isPremiumLoading: "",
    sumAssured: {},
    coverTillAge: {},
    payTillAge: {},
    frequency: {},
    redirectFrom: "",
    selectedQuoteToBuy: {},
    quotesList: [],
    selectCompareQuoteToShare: [],
  },
  reducers: {
    setRedirectFrom: (state, action) => {
      state.redirectFrom = action?.payload;
    },
    setSelectedQuoteToBuy: (state, action) => {
      state.selectedQuoteToBuy = action?.payload;
    },
    setInsuranceCompany: (state, action) => {
      state.insuranceCompany = action.payload;
      state.product = {};
      state.sumAssured = {};
      state.coverTillAge = {};
      state.payTillAge = {};
      state.frequency = {};
    },
    setProduct: (state, action) => {
      state.product = action.payload;
      state.sumAssured = {};
      state.coverTillAge = {};
      state.payTillAge = {};
      state.frequency = {};
    },
    setIsPremiumLoading: (state, action) => {
      state.isPremiumLoading = action.payload;
    },
    setSumAssured: (state, action) => {
      state.sumAssured = action.payload;
      state.coverTillAge = {};
      state.payTillAge = {};
      state.frequency = {};
    },
    setCoverTillAge: (state, action) => {
      state.coverTillAge = action.payload;
      state.payTillAge = {};
      state.frequency = {};
    },
    setPayTillAge: (state, action) => {
      state.payTillAge = action.payload;
      state.frequency = {};
    },
    setFrequency: (state, action) => {
      state.frequency = action.payload;
    },
    setSelectCompareQuoteToShare: (state, action) => {
      state.selectCompareQuoteToShare = action.payload;
    },
    addQuoteInList: (state, action) => {
      state.quotesList = [...state.quotesList, action.payload];
    },
    removeQuoteInList: (state, action) => {
      state.quotesList = state.quotesList?.filter((quote, i) => {
        return i !== action.payload;
      });
    },
    updateQuoteInList: (state, action) => {
      state.quotesList = state?.quotesList?.map((ele, i) => {
        return i === action?.payload?.i ? { ...action?.payload?.quote } : ele;
      });
    },
    resetStates: state => {
      state.insuranceCompany = {};
      state.product = {};
      state.sumAssured = {};
      state.coverTillAge = {};
      state.payTillAge = {};
      state.frequency = {};
    },
  },
});

export const {
  resetStates,
  setProduct,
  setPayTillAge,
  setFrequency,
  setSumAssured,
  setCoverTillAge,
  addQuoteInList,
  updateQuoteInList,
  removeQuoteInList,
  setIsPremiumLoading,
  setInsuranceCompany,
  setSelectCompareQuoteToShare,
  setRedirectFrom,
  setSelectedQuoteToBuy,
} = newCompare.actions;

export default newCompare.reducer;
