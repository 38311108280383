import React, { useState } from "react";
import { Accordion } from "@material-ui/core";
import { FaChevronDown } from "react-icons/fa";
import { AccordionSummary, AccordionDetails } from "@material-ui/core";
import styled from "styled-components/macro";
import { useSelector } from "react-redux";

function PlanDetailsMobileUI() {
  const { planDetails } = useSelector(state => state.quotes.seeDetailsData);

  const [expanded, setExpanded] = useState(false);
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div
      css={`
        width: 90%;
        margin: 0 auto 5px auto;
        padding-top: 60px;
      `}
    >
      {planDetails.map((item, index) => (
        <Accordion
          expanded={expanded === index}
          onChange={handleChange(index)}
          style={{
            boxShadow: "2px 6px 12px -3px rgba(107,102,102,0.75)",
            marginBottom: "10px",
            border: "none",
            borderRadius: "10px",
            marginTop: "10px",
          }}
        >
          <AccordionSummary
            style={{ height: "8em" }}
            expandIcon={<FaChevronDown />}
          >
            <Title>
              <span
                css={`
                  color: var(--primary-color);
                  font-weight: bold;
                `}
              >
                {item.feature_key}
              </span>{" "}
              <span
                css={`
                  font-weight: 600;
                  font-size: 11px;
                `}
              >
                {item.feature_description}
              </span>
            </Title>
          </AccordionSummary>
          <Info>
            {item.feature_values.map(i => (
              <AccordionDetails
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span
                  css={`
                    color: var(--secondary-text);
                    font-weight: 600;
                  `}
                >
                  {i.feature_key_title}
                  {" : "}
                  {i.feature_key_value}
                </span>

                <span
                  css={`
                    padding-bottom: 10px;
                    border-bottom: 1px dashed black;
                  `}
                >
                  {" "}
                  {i.feature_key_description}
                </span>
              </AccordionDetails>
            ))}
          </Info>
        </Accordion>
      ))}
    </div>
  );
}

export default PlanDetailsMobileUI;

const Info = styled.div`
  background: var(--tertiary-shade);

  text-align: left;
  border-radius: 10px;
  margin-top: 15px;
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
`;
// const Desc = styled.div``;
