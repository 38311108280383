import styled from "styled-components";
import * as mq from "../../../../styles/mediaQueries";
export const ContactDetailsWrapper = styled.div`
  padding: 20px 0;
  border-bottom: 1px solid rgba(100, 111, 128, 0.55);
  ${mq.sm} {
    padding-top: 60px;
  }
`;
export const ContactDetails = styled.div`
  border-radius: 18px;
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;

  box-shadow: 0 5px 15px 0 rgb(0 0 0 / 16%);
  background-color: #fff;
  display: flex;
  ${mq.sm} {
    flex-direction: column;
    padding: 15px;
  }
  & img {
    width: 40px;
    height: 40px;
    margin-right: 12px;
  }
  & section {
    padding: 5px 6px;
    display: flex;
    width: 55%;
  }
  & section:first-child {
    border-right: 1px solid rgb(186, 195, 207);
    width: 45%;
    ${mq.sm} {
      border-right: none;
      border-bottom: 1px solid rgb(186, 195, 207);
      width: 100%;
      font-size: 14px;
      padding-bottom: 5px;
      margin-bottom: 10px;
    }
  }
  & .contact-details-value {
    font-family: Inter;
    font-weight: 600;
    font-size: 0.85rem;
    ${mq.sm} {
      font-size: 12px;
      font-weight: 700;
    }
  }
`;
export const SectionTitle = styled.div`
  font-family: Inter;
  font-weight: 700;
  font-size: 18px;
  background-image: linear-gradient(to left, #fff, var(--primary-color-light));
  color: #000;
  padding: 12px 16px;
  border-radius: 20px;
  margin-bottom: 20px;
  ${mq.sm} {
    font-size: 14px;
    padding: 5px 10px;
  }
`;
export const ClaimDetails = styled.div`
  padding: 30px 0;
  & .claim-process-icons {
    display: flex;
    flex-direction: row;
    margin: 10px 0 0;
    justify-content: space-between;
    font-size: 35px;
    text-align: center;
    color: var(--primary-color);
    ${mq.sm} {
      font-size: 15px;
      margin: 0;
      margin-bottom: -23px;
    }
  }
  & .stepper-wrapper {
    margin: 30px auto;
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    font-family: Inter;
    font-weight: 400;
    ${mq.sm} {
      font-size: 8px;
      font-weight: normal;
    }
  }
  & .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }

  & .stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 1px solid #000;
    width: 100%;
    top: 15px;
    left: -50%;
    ${mq.sm} {
      top: 10px;
    }
  }

  & .stepper-item .step-counter {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #fff;
    margin-bottom: 6px;
    border: 2px solid #000;
    ${mq.sm} {
      width: 20px;
      height: 20px;
      border: 1px solid #000;
    }
  }

  & .stepper-item:first-child::before {
    content: none;
  }
  & .stepper-item:last-child::after {
    content: none;
  }
`;
export const DocumentsDetail = styled.div`
  font-family: Inter;
  font-weight: 400;
  padding: 30px 0;
  margin-bottom: 70px;
`;
