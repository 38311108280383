import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { CloseButton } from "../../../components";
import { AddCompareLoader } from "../../../components/CardSkeletonLoader/ComparePageLoader/ComparePageLoader";
import { amount } from "../../../utils";
import {
  selectCompanies,
  selectICLogo,
} from "../../frontendBoot/frontendBoot.slice";
import {
  selectSelectedInsurersFilter,
  selectTermFilters,
} from "../../product-page/Filters/Filters.slice";
import {
  useGetCompareProductsQuery,
  useGetDifferentQuoteQuery,
  useGetQuoteQuery,
  usePutCompareProductsMutation,
} from "../../riders/services/rider";
// import { useSortQuotes } from "../../product-page/Quotes/Quotes";
import * as mq from "../../../styles/mediaQueries";
import { usePlanDetails } from "../../riders/component/PlanCard/planCard.util";
// import { selectQuotes } from "../../product-page/Quotes/quotes.slice";
import useMediaQuery from "../../../custom-hooks/useMediaQuery";
import { toast } from "react-toastify";
import { selectDifferentQuotes } from "../../product-page/Quotes/quotes.slice";
const AddPlanPopupContext = React.createContext();
AddPlanPopupContext.displayName = "AddPlanPopupContext";

function useAddPlanPopup() {
  const context = React.useContext(AddPlanPopupContext);

  if (!context)
    throw new Error(
      `useAddPlanPopup must be used inside AddPlanPopupContextProvider`,
    );

  return context;
}

function AddPlanPopup({ show = false, onClose = () => {}, ...props }) {
  const { data, isLoading } = useGetCompareProductsQuery();
  const [putCompareQuotes] = usePutCompareProductsMutation();
  const { payFrequency, paymentOption } = usePlanDetails();
  const [compareQuotes, setCompareQuotes] = React.useState([]);
  const diffQuotes = useSelector(selectDifferentQuotes);
  const { showDifferentQuotes } = useSelector(state => state.quotes);
  const matches = useMediaQuery(mq.smForHook);

  let maxQuotesLength = matches ? 2 : 3;

  const addQuoteToCompare = product => {
    if (compareQuotes.length >= maxQuotesLength) {
      toast.warning(`Maximum ${maxQuotesLength} plans can be selected!`, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      return;
    }
    setCompareQuotes(prevQuotes => [...prevQuotes, product]);
  };

  const removeQuoteFromCompare = ({ product_id }) => {
    setCompareQuotes(prevQuotes =>
      prevQuotes.filter(product => product.product_id !== product_id),
    );
  };

  const toggleCompareQuote = ({
    company_alias,
    product_id,
    term_insurance_id,
    different,
  }) => {
    if (compareQuotes.some(product => product.product_id === product_id)) {
      removeQuoteFromCompare({ product_id });
      return;
    }
    addQuoteToCompare({
      differentQuote: different ? "Y" : "N",
      product_id,
      company_alias,
      term_insurance_id,
    });
  };

  const updateCompareQuotes = () => {
    putCompareQuotes({ products: compareQuotes }).then(() => {
      onClose();
    });
  };

  useEffect(() => {
    if (data) {
      const { products } = data.data;

      setCompareQuotes(products);
    }
  }, [data]);

  return (
    <AddPlanPopupContext.Provider
      value={{
        addQuoteToCompare,
        removeQuoteFromCompare,
        compareQuotes,
        updateCompareQuotes,
        toggleCompareQuote,
      }}
    >
      <Modal
        show={show}
        onHide={onClose}
        size="lg"
        centered
        css={`
          max-height: 700px;
          color: #233d63;
          ${mq.sm} {
            max-height: 750px;
          }
        `}
        {...props}
      >
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <>
            <Modal.Header>
              <span
                css={`
                  width: 5px;
                  height: 30px;
                  background: #ffcb00;
                  position: absolute;
                  left: 0;
                  border-radius: 0 50px 50px 0;
                `}
              ></span>
              <Title>Add Any {maxQuotesLength} plans to compare</Title>
              <CloseButton
                css={`
                  ${mq.sm} {
                    right: 20px;
                    top: 20px;
                  }
                `}
                onClick={onClose}
              />
            </Modal.Header>
            <Modal.Body
              css={`
                padding: auto;
                ${mq.sm} {
                  padding: 0 8px 0;
                }
              `}
            >
              <AddQuoteCards
                paymentOption={paymentOption}
                payFrequency={payFrequency}
              />
              {showDifferentQuotes && diffQuotes && (
                <div
                  css={`
                    text-align: center;
                    font-size: 18px;
                    font-weight: 800;
                  `}
                >
                  Plans with slightly different criteria
                </div>
              )}
              <div>
                <AddQuoteCards
                  paymentOption={paymentOption}
                  payFrequency={payFrequency}
                  different={true}
                />
              </div>
            </Modal.Body>

            <Modal.Footer
              css={`
                ${mq.sm} {
                  width: 100%;
                  background-color: inherit;
                  padding: 0;
                }
              `}
            >
              <StyledCompareButton onClick={updateCompareQuotes}>
                Compare
              </StyledCompareButton>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </AddPlanPopupContext.Provider>
  );
}

function AddQuoteCards({ payFrequency, paymentOption, different = false }) {
  let companies = useSelector(selectCompanies);
  companies = Object.keys(companies);

  let selected_insurers = useSelector(selectSelectedInsurersFilter);

  // const quotes = useSelector(selectQuotes);

  // const { sortedQutoes } = useSortQuotes(
  //   companies.map(company => quotes[company]).flat(Infinity),
  // );

  // const sortedAndFilteredQuotes = sortedQutoes.filter(
  //   quote =>
  //     quote &&
  //     companies.includes(quote.company_alias) &&
  //     Boolean(quotes[quote.company_alias]),
  // );

  if (selected_insurers && selected_insurers.length > 0)
    companies = companies.filter(company =>
      selected_insurers.includes(company),
    );
  return (
    <div
      css={`
        display: flex;
        align-item: center;
        justify-content: justified;
        flex-wrap: wrap;
        max-height: 600px;
        padding: 0 30px;
        overflow: auto;
        ${mq.sm} {
          // padding: 0 5px 60px 5px;
          padding: 0 5px 0px 5px;
          margin-bottom: 2em;
          max-height: 400px;

          justify-content: center;
        }
      `}
    >
      {companies.map(company => (
        <AddQuoteCard
          payFrequency={payFrequency}
          paymentOption={paymentOption}
          company_alias={company}
          different={different}
          //total_premium={company.total_premium}
        />
      ))}
    </div>
  );
}

function AddQuoteCard({
  company_alias,
  paymentOption,
  payFrequency,
  different,
  ...props
}) {
  // const { isLoading: isCompareProductsLoading, data: compareProductsData } =
  //   useGetCompareProductsQuery();
  const { selected_insurers, ...termFilters } = useSelector(selectTermFilters);
  const { isLoading, isUninitialized, isFetching, data } = useGetQuoteQuery(
    {
      company_alias,
      termFilters,
      // ...(different ? { differentQuote: different } : {}),
    },
    // {skip:different
    // }
  );

  const {
    isLoading: isDifferentQuoteLoading,
    isFetching: isDifferentQuoteFetching,
    data: differentQuoteData,
  } = useGetDifferentQuoteQuery(
    { company_alias },
    {
      skip: !different,
    },
  );

  const { toggleCompareQuote, compareQuotes } = useAddPlanPopup();

  const logoSrc = useSelector(selectICLogo(company_alias));

  if (
    isLoading ||
    isFetching ||
    isUninitialized ||
    isDifferentQuoteFetching ||
    isDifferentQuoteLoading
  )
    return (
      <AddQuoteCardWrap>
        <AddCompareLoader />
      </AddQuoteCardWrap>
    );

  // const quote =
  // different === true
  // ?differentQuoteData.data[0]
  // :null
  // ?data.data[0]
  // :null

  // const quote = data ? data.data[0] : null;
  const quoteData =
    different === true
      ? differentQuoteData
        ? differentQuoteData
        : null
      : data
      ? data
      : null;

  // const isAddedToCompare = compareProductsData.data.products.some(
  //   product => product.product_id === product_id,
  // );

  const handleCardClick = ({ product_id, term_insurance_id }) => {
    toggleCompareQuote({
      company_alias,
      product_id,
      differentQuote: different ? "Y" : "N",
      term_insurance_id,
      different,
    });
  };
  return (
    // eslint-disable-next-line array-callback-return
    quoteData?.data?.map(item => {
      if (item) {
        const {
          total_premium,
          term_insurance_id,
          product: { id: product_id, name },
        } = item;
        const isAddedToCompare = compareQuotes.some(
          product => product.product_id === product_id,
        );
        return (
          <AddQuoteCardWrap
            checked={isAddedToCompare}
            onClick={() => handleCardClick({ product_id, term_insurance_id })}
            {...props}
          >
            {isAddedToCompare ? <CheckMarkIcon /> : <EmptyCircle />}
            <div
              css={`
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-around;
                ${mq.sm} {
                  align-items: center;
                  width: 0;
                  max-height: 5em;
                }
              `}
            >
              <img
                css={`
                  width: 80px;
                  height: 35px;
                  ${mq.sm} {
                    width: 70px;
                    height: 30px;
                  }
                `}
                src={logoSrc}
                alt="company"
              />
              <div
                css={`
                  font-size: 10px;
                  text-align: left;
                  width: 115px;
                  font-family: "Inter";
                  font-weight: 700;
                  display: block;
                  padding-top: 2px;
                  ${mq.sm} {
                    text-align: center;
                    padding-top: 4px;
                  }
                `}
              >
                {name}
              </div>
            </div>
            <div
              css={`
                text-align: right;
                width: 50%;

                ${mq.sm} {
                  display: flex;
                  text-align: center;
                  flex-direction: column;
                  align-items: center;
                  width: 0;
                }
              `}
            >
              <div
                css={`
                  font-size: 18px;
                  width: 100px;
                  ${mq.sm} {
                    color: #0182ff;
                    font-size: 0.9rem;
                  }
                `}
              >
                {amount(total_premium)}
              </div>
              <div
                css={`
                  display: block;
                  font-family: Inter;
                  font-weight: 700;
                  font-size: 9px;
                  ${mq.sm} {
                    color: #000;
                    font-size: 0.7rem;
                  }
                `}
              >
                {` ${
                  paymentOption === "Single Pay" ? "Pay Once" : payFrequency
                }`}
              </div>
            </div>
          </AddQuoteCardWrap>
        );
      }
    }) || <></>
  );
}

function EmptyCircle() {
  return (
    <div
      css={`
        ${mq.sm} {
          height: 2em;
          width: 2em;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          border: 1px solid #a3a3a3;
          position: absolute;
          right: 0;
          top: 0;
          transform: translate(30%, -30%);
          background-color: #fff;
        }
      `}
    ></div>
  );
}

function CheckMarkIcon({ ...props }) {
  return (
    <div
      css={`
        height: 2em;
        width: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid #0caeee;
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(30%, -30%);
        background-color: #fff;
      `}
      {...props}
    >
      <FaCheck />
    </div>
  );
}

const AddQuoteCardWrap = styled.button`
  position: relative;
  color: inherit;
  font-family: "Inter";
  font-weight: 700;
  background: none;
  max-width: 30%;
  min-height: 8em;
  flex: 1 0 30%;
  box-shadow: 0 2px 15px 5px #e2e3ed;
  margin: 0.6em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  border: 1px solid;
  border-color: ${props => (props.checked ? "#0caeee" : "transparent")};
  padding: 1em;
  &:hover {
    border-color: #0caeee;
  }
  ${mq.sm} {
    flex: 50%;
    max-width: 40%;
    flex-direction: column;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
`;

export { AddPlanPopup };

const Title = styled.h1`
  margin: 0;
  font-size: 1.27rem;
  font-family: "Inter";
  font-weight: 700;
  ${mq.sm} {
    font-size: 1rem;
    padding-left: 5px;
  }
`;

const StyledCompareButton = styled.button`
  background-color: var(--primary-color);
  font-family: "Inter";
  font-weight: 700;
  border: none;
  padding: 0.6em 1em;
  border-radius: 0.37em;
  color: #fff;
  ${mq.sm} {
    width: 100%;
  }
`;
