/* eslint-disable no-useless-computed-key */
import "styled-components/macro";
import { useEffect, useState } from "react";
import {
  checkDocType,
  checkSize,
  convert,
} from "../../../../DocumentUpload-page/docUtils";
import { uploadDoc } from "../../../../DocumentUpload-page/serviceApi";
import Select from "../../../components/Select";
import {
  useGetCartQuery,
  useGetEnquiriesQuery,
  useGetProposalDataQuery,
} from "../../../../riders/services/rider";
import {
  addressProofOptions,
  ageProofOptions,
  idProofOption,
  incomeProofOption,
} from "../../../options/bajaj/occupationDetailsOptions";
import {
  ErrorMsg,
  FileInputWrapper,
  UploaderBody,
  UploadWrap,
} from "../../../ProposalPage.style";
import { HR } from "../../../components/Divider";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  clearDocumentUploadLoading,
  initDocumentUploadLoading,
} from "../../../ProposalPage.slice";
import ButtonLoader from "../../../../../components/CardSkeletonLoader/ButtonLoader/ButtonLoader";
import moment from "moment";
const BajajDocumentUpload = ({
  setDisable,
  disable,
  setSuccessBajajDocUpload,
  successBajajDocUpload,
}) => {
  // Queries
  const { isDocumentUploadLoading } = useSelector(state => state.proposal);
  const { data, isLoading: isEnquiryLoading } = useGetEnquiriesQuery();
  const { data: cartData } = useGetCartQuery();
  const { data: proposalData } = useGetProposalDataQuery();

  const occupationData = proposalData?.data["Insured Occupation Details"];
  const { doc_required, isLoading, proposal_reference_id } = useSelector(
    state => state.proposal,
  );
  const mainDoc = [
    "address_proof",
    "recent_photograph",
    "age_proof",
    "id_proof",
    "income_proof",
  ];
  const appNo = proposal_reference_id;
  const requiredDocuments =
    isLoading === false
      ? doc_required?.map(val => val?.document_type)
      : mainDoc;

  // useState
  const [documents, setDocuments] = useState({});
  const [errors, setErrors] = useState({});
  const [previews, setPreviews] = useState({});
  const [income, setIncome] = useState();
  const [uploaded, setUploaded] = useState("");
  const currentDate = moment().format("DDMMYYYY");
  const [docName, setDocName] = useState({
    address_proof: `${appNo}_AddressProof_${currentDate}_1.pdf`,
    age_proof: `${appNo}_AgeProof_${currentDate}_2.pdf`,
    id_proof: `${appNo}_IdProof_${currentDate}_3.pdf`,
    income_proof: `${appNo}_IncomeProof_${currentDate}_4.pdf`,
    recent_photograph: `${appNo}_recentPhotograph_${currentDate}_5.pdf`,
  });

  // useDispatch
  const dispatch = useDispatch();

  // useEffects
  useEffect(() => {
    if (!isEnquiryLoading && data) {
      setIncome(occupationData?.income_proof);
      setDocuments({
        ...documents,
        ["Address Proof"]: {
          ...(documents["Address Proof"] ? documents["Address Proof"] : {}),
          file_type: occupationData?.address_proof,
        },
        ["Age Proof"]: {
          ...(documents["Age Proof"] ? documents["Age Proof"] : {}),
          file_type: occupationData?.age_proof,
        },
        ["ID Proof"]: {
          ...(documents["ID Proof"] ? documents["ID Proof"] : {}),
          file_type: occupationData?.id_proof,
        },
        ["Income Proof"]: {
          ...(documents["Income Proof"] ? documents["Income Proof"] : {}),
          file_type: occupationData?.income_proof,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnquiryLoading, occupationData]);

  useEffect(() => {
    if (
      documents["Age Proof"]?.upload_file === undefined ||
      documents["ID Proof"]?.upload_file === undefined ||
      documents["Address Proof"]?.upload_file === undefined ||
      documents["Income Proof"]?.upload_file === undefined
    ) {
      return setDisable(prev => ({
        ...prev,
        ["Age Proof"]: true,
        ["ID Proof"]: true,
        ["Address Proof"]: true,
        ["Income Proof"]: true,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (
  //     proposalData?.data["Bank Details"]?.renewal_deposit === "NACH" &&
  //     documents["NACH Form"]?.upload_file === undefined
  //   ) {
  //     return setDisable(prev => ({
  //       ...prev,
  //       ["NACH Form"]: true,
  //     }));
  //   }
  // }, []);

  // Blob
  function createPreview(fileInput) {
    let blob = new Blob(fileInput?.target?.files, {
      type: fileInput?.target?.files[0]?.type,
    });

    return URL.createObjectURL(blob);
  }
  function removeDocument(document) {
    setDocuments(prev => {
      let temp = { ...prev };
      temp[document].upload_file = null;
      temp[document].upload_file_id = null;
      temp[document].upload_file_name = null;
      return temp;
    });

    setPreviews(prev => {
      let temp = { ...prev };
      temp[document] = {};
      return temp;
    });
    setDisable(prev => ({ ...prev, [document]: true }));
  }

  // onSubmit
  const onSubmit = async (e, item) => {
    const newData = await convert(documents[item].upload_file);
    const docShortName = documents[item]?.file_type;
    try {
      dispatch(initDocumentUploadLoading());
      // eslint-disable-next-line no-unused-vars
      const { data } = await uploadDoc({
        company_alias: cartData?.product?.company?.alias,
        upload_file_name: documents[item]?.upload_file_name,
        upload_file_id: documents[item]?.upload_file_id,
        upload_file: newData.split(",")[1],
        shortname: docShortName ? docShortName : "recent_photograph",
        docType: "IP",
      });
      setDisable(prev => ({ ...prev, [item]: false }));
      toast.success(`${item} Uploaded!`, {
        toastId: item,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
      });
      setSuccessBajajDocUpload(successBajajDocUpload + 1);
      dispatch(clearDocumentUploadLoading());
      return (document.getElementById(item).innerHTML = "Uploaded");
    } catch (err) {
      toast.error(`${item} upload failed`, {
        toastId: `${item}_error`,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
      });
      dispatch(clearDocumentUploadLoading());
    }
  };

  // final return
  return (
    <div className="doc-form__wrapper my-4">
      {/* ================================= Age ============================ */}
      {requiredDocuments.includes("age_proof") && (
        <UploaderBody
          className="col-md-12 select_wrapper"
          active={documents["Age Proof"]?.upload_file ? true : false}
        >
          <div className="upload__body">
            <UploadWrap>
              <Select
                options={ageProofOptions}
                label={"Age Proof*"}
                selectedValue={occupationData?.age_proof}
                noSearch
                readOnly={true}
                // handleChange={value =>
                //   setDocuments({
                //     ...documents,
                //     ["Age Proof"]: {
                //       ...(documents["Age Proof"] ? documents["Age Proof"] : {}),
                //       file_type: value,
                //     },
                //   })
                // }
              />
            </UploadWrap>
            <FileInputWrapper className="col-md-6">
              <input
                type="file"
                name="myfile"
                id={docName.age_proof}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    !checkSize(e, 5) ||
                    !checkDocType(e, ["jpeg", "pdf", "tif", "tiff", "jpg"])
                  ) {
                    setErrors({
                      ...errors,
                      ["Age Proof"]: "File type or size not allowed",
                    });
                  } else {
                    setDocName(prev => ({
                      ...prev,
                      age_proof: `${appNo}_AgeProof_${currentDate}_1.${
                        e.target.files[0]?.name?.split(".")?.reverse()[0]
                      }`,
                    }));
                    setDocuments({
                      ...documents,
                      ["Age Proof"]: {
                        ...(documents["Age Proof"]
                          ? documents["Age Proof"]
                          : {}),
                        upload_file: e.target.files[0],
                        upload_file_id: 1,
                        upload_file_name: docName.age_proof,
                        file_type: occupationData?.age_proof,
                      },
                    });
                    setPreviews({
                      ...previews,
                      ["Age Proof"]: {
                        ...(previews["Age Proof"] ? previews["Age Proof"] : {}),
                        blob_file: createPreview(e),
                      },
                    });
                  }
                }}
              />
              <span className="w-100 file_name_container">
                {docName.age_proof}
              </span>
            </FileInputWrapper>
            {!documents["Age Proof"]?.upload_file ? (
              <label className="Choose_file_btn" htmlFor={docName.age_proof}>
                Choose a file
              </label>
            ) : (
              <button
                disabled={disable["Age Proof"] === false}
                className={`col-4 Choose_file_btn btn`}
                id="upload_btn"
                onClick={e => {
                  return (
                    documents["Age Proof"]?.upload_file?.name === undefined ||
                    isDocumentUploadLoading
                      ? null
                      : onSubmit(e, "Age Proof"),
                    setUploaded("Age Proof")
                  );
                }}
              >
                {uploaded === "Age Proof" && isDocumentUploadLoading ? (
                  <ButtonLoader />
                ) : (
                  <p id="Age Proof">Upload</p>
                )}
              </button>
            )}

            {previews["Age Proof"]?.blob_file && (
              <a
                className={`col-4 upload_btn btn `}
                href={previews["Age Proof"]?.blob_file}
                target="_blank"
                rel="noreferrer"
              >
                Preview
              </a>
            )}

            {documents["Age Proof"]?.upload_file && (
              <i
                className="fas fa-trash-alt trash-icon"
                onClick={() => removeDocument("Age Proof")}
              />
            )}
          </div>
          {errors["Age Proof"] && <ErrorMsg>{errors["Age Proof"]}</ErrorMsg>}
        </UploaderBody>
      )}

      <HR style={{ marginTop: "12px" }} />

      {/* ================================= ID ============================ */}
      {requiredDocuments.includes("id_proof") && (
        <UploaderBody
          className="col-md-12 select_wrapper"
          active={documents["ID Proof"]?.upload_file ? true : false}
        >
          <div className="upload__body">
            <UploadWrap>
              <Select
                options={idProofOption}
                label={"ID Proof*"}
                selectedValue={occupationData?.id_proof}
                readOnly={true}
              />
            </UploadWrap>
            <FileInputWrapper className="col-md-6">
              <input
                type="file"
                name="myfile"
                id={docName.id_proof}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    !checkSize(e, 5) ||
                    !checkDocType(e, ["jpeg", "pdf", "tif", "tiff", "jpg"])
                  ) {
                    setErrors({
                      ...errors,
                      ["ID Proof"]: "File type or size not allowed",
                    });
                  } else {
                    setDocName(prev => ({
                      ...prev,
                      id_proof: `${appNo}_IDProof_${currentDate}_2.${
                        e.target.files[0]?.name?.split(".")?.reverse()[0]
                      }`,
                    }));
                    setErrors({
                      ...errors,
                      ["ID Proof"]: "",
                    });
                    setDocuments({
                      ...documents,
                      ["ID Proof"]: {
                        ...(documents["ID Proof"] ? documents["ID Proof"] : {}),
                        upload_file: e.target.files[0],
                        upload_file_id: 2,
                        upload_file_name: docName.id_proof,
                        file_type: occupationData?.id_proof,
                      },
                    });
                    setPreviews({
                      ...previews,
                      ["ID Proof"]: {
                        ...(previews["ID Proof"] ? previews["ID Proof"] : {}),
                        blob_file: createPreview(e),
                      },
                    });
                  }
                }}
              />
              <span className="w-100 file_name_container">
                {docName.id_proof}
              </span>
            </FileInputWrapper>

            {!documents["ID Proof"]?.upload_file ? (
              <label className="Choose_file_btn" htmlFor={docName.id_proof}>
                Choose a file
              </label>
            ) : (
              <button
                disabled={disable["ID Proof"] === false}
                className={`col-4 Choose_file_btn btn `}
                onClick={e => {
                  return (
                    documents["ID Proof"]?.upload_file?.name === undefined ||
                    isDocumentUploadLoading
                      ? null
                      : onSubmit(e, "ID Proof"),
                    setUploaded("ID Proof")
                  );
                }}
              >
                {uploaded === "ID Proof" && isDocumentUploadLoading ? (
                  <ButtonLoader />
                ) : (
                  <p id="ID Proof">Upload</p>
                )}
              </button>
            )}

            {previews["ID Proof"]?.blob_file && (
              <a
                className={`col-4 upload_btn btn `}
                href={previews["ID Proof"]?.blob_file}
                target="_blank"
                rel="noreferrer"
              >
                Preview
              </a>
            )}

            {documents["ID Proof"]?.upload_file && (
              <i
                className="fas fa-trash-alt trash-icon"
                onClick={() => removeDocument("ID Proof")}
              />
            )}
          </div>
          {errors["ID Proof"] && <ErrorMsg>{errors["ID Proof"]}</ErrorMsg>}
        </UploaderBody>
      )}

      <HR style={{ marginTop: "10px" }} />

      {/* ================================= Address ============================ */}
      {requiredDocuments.includes("address_proof") && (
        <UploaderBody
          className="col-md-12 select_wrapper"
          active={documents["Address Proof"]?.upload_file ? true : false}
        >
          <div className="upload__body">
            <UploadWrap>
              <Select
                options={addressProofOptions}
                label={"Address Proof*"}
                selectedValue={occupationData?.address_proof}
                readOnly={true}
              />
            </UploadWrap>
            <FileInputWrapper className="col-md-6">
              <input
                type="file"
                name="myfile"
                id={docName.address_proof}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    !checkSize(e, 5) ||
                    !checkDocType(e, ["jpeg", "pdf", "tif", "tiff", "jpg"])
                  ) {
                    setErrors({
                      ...errors,
                      ["Address Proof"]: "File type or size not allowed",
                    });
                  } else {
                    setDocName(prev => ({
                      ...prev,
                      address_proof: `${appNo}_AddressProof_${currentDate}_3.${
                        e.target.files[0]?.name?.split(".")?.reverse()[0]
                      }`,
                    }));
                    setErrors({
                      ...errors,
                      ["Address Proof"]: "",
                    });
                    setDocuments({
                      ...documents,
                      ["Address Proof"]: {
                        ...(documents["Address Proof"]
                          ? documents["Address Proof"]
                          : {}),
                        upload_file: e.target.files[0],
                        upload_file_id: 3,
                        upload_file_name: docName.address_proof,
                        file_type: occupationData?.address_proof,
                      },
                    });
                    setPreviews({
                      ...previews,
                      ["Address Proof"]: {
                        ...(previews["Address Proof"]
                          ? previews["Address Proof"]
                          : {}),
                        blob_file: createPreview(e),
                      },
                    });
                  }
                }}
              />
              <span className="w-100 file_name_container">
                {docName.address_proof}
              </span>
            </FileInputWrapper>

            {!documents["Address Proof"]?.upload_file ? (
              <label
                className="Choose_file_btn"
                htmlFor={docName.address_proof}
              >
                Choose a file
              </label>
            ) : (
              <button
                disabled={disable["Address Proof"] === false}
                className={`col-4 Choose_file_btn btn `}
                onClick={e => {
                  return (
                    documents["Address Proof"]?.upload_file?.name ===
                      undefined || isDocumentUploadLoading
                      ? null
                      : onSubmit(e, "Address Proof"),
                    setUploaded("Address Proof")
                  );
                }}
              >
                {uploaded === "Address Proof" && isDocumentUploadLoading ? (
                  <ButtonLoader />
                ) : (
                  <p id="Address Proof">Upload</p>
                )}
              </button>
            )}

            {previews["Address Proof"]?.blob_file && (
              <a
                className={`col-4 upload_btn btn `}
                href={previews["Address Proof"]?.blob_file}
                target="_blank"
                rel="noreferrer"
              >
                Preview
              </a>
            )}

            {documents["Address Proof"]?.upload_file && (
              <i
                className="fas fa-trash-alt trash-icon"
                onClick={() => removeDocument("Address Proof")}
              />
            )}
          </div>
          {errors["Address Proof"] && (
            <ErrorMsg>{errors["Address Proof"]}</ErrorMsg>
          )}
        </UploaderBody>
      )}

      <HR style={{ marginTop: "12px" }} />

      {/* ================================= Income ============================ */}
      {requiredDocuments.includes("income_proof") && (
        <UploaderBody
          className="col-md-12 select_wrapper"
          active={documents["Income Proof"]?.upload_file ? true : false}
        >
          <div className="upload__body">
            <UploadWrap>
              <Select
                options={incomeProofOption}
                label={"Income Proof*"}
                selectedValue={income}
                readOnly={true}
                handleChange={value =>
                  setDocuments({
                    ...documents,
                    ["Income Proof"]: {
                      ...(documents["Income Proof"]
                        ? documents["Income Proof"]
                        : {}),
                      file_type: value,
                    },
                  })
                }
              />
            </UploadWrap>
            <FileInputWrapper className="col-md-6">
              <input
                type="file"
                name="myfile"
                id={docName.income_proof}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    !checkSize(e, 5) ||
                    !checkDocType(e, ["jpeg", "pdf", "tif", "tiff", "jpg"])
                  ) {
                    setErrors({
                      ...errors,
                      ["Income Proof"]: "File type or size not allowed",
                    });
                  } else {
                    setDocName(prev => ({
                      ...prev,
                      income_proof: `${appNo}_IncomeProof_${currentDate}_4.${
                        e.target.files[0]?.name?.split(".")?.reverse()[0]
                      }`,
                    }));
                    setErrors({
                      ...errors,
                      ["Income Proof"]: "File type or size not allowed",
                    });
                    setDocuments({
                      ...documents,
                      ["Income Proof"]: {
                        ...(documents["Income Proof"]
                          ? documents["Income Proof"]
                          : {}),
                        upload_file: e.target.files[0],
                        upload_file_id: 4,
                        upload_file_name: docName.income_proof,
                        file_type: occupationData?.income_proof,
                      },
                    });
                    setPreviews({
                      ...previews,
                      ["Income Proof"]: {
                        ...(previews["Income Proof"]
                          ? previews["Income Proof"]
                          : {}),
                        blob_file: createPreview(e),
                      },
                    });
                  }
                }}
              />
              <span className="w-100 file_name_container">
                {docName.income_proof}
              </span>
            </FileInputWrapper>

            {!documents["Income Proof"]?.upload_file ? (
              <label className="Choose_file_btn" htmlFor={docName.income_proof}>
                Choose a file
              </label>
            ) : (
              <button
                disabled={disable["Income Proof"] === false}
                className={`col-4 Choose_file_btn btn `}
                onClick={e => {
                  return (
                    documents["Income Proof"]?.upload_file?.name ===
                      undefined || isDocumentUploadLoading
                      ? null
                      : onSubmit(e, "Income Proof"),
                    setUploaded("Income Proof")
                  );
                }}
              >
                {uploaded === "Income Proof" && isDocumentUploadLoading ? (
                  <ButtonLoader />
                ) : (
                  <p id="Income Proof">Upload</p>
                )}
              </button>
            )}

            {previews["Income Proof"]?.blob_file && (
              <a
                className={`col-4 upload_btn btn `}
                href={previews["Income Proof"]?.blob_file}
                target="_blank"
                rel="noreferrer"
              >
                Preview
              </a>
            )}

            {documents["Income Proof"]?.upload_file && (
              <i
                className="fas fa-trash-alt trash-icon"
                onClick={() => removeDocument("Income Proof")}
              />
            )}
          </div>
          {errors["Income Proof"] && (
            <ErrorMsg>{errors["Income Proof"]}</ErrorMsg>
          )}
        </UploaderBody>
      )}

      <HR style={{ marginTop: "12px" }} />
      {requiredDocuments.includes("recent_photograph") && (
        <>
          <h6>Recent Photograph</h6>

          <UploaderBody
            className="col-md-12 select_wrapper"
            active={documents["Recent Photograph"]?.upload_file ? true : false}
          >
            <div className="additional-body">
              <FileInputWrapper className="col-md-6 additional-doc-input">
                <input
                  type="file"
                  name="myfile"
                  id={docName.recent_photograph}
                  onClick={e => {
                    e.target.value = null;
                  }}
                  onChange={e => {
                    if (
                      !checkSize(e, 5) ||
                      !checkDocType(e, ["jpeg", "pdf", "tif", "tiff", "jpg"])
                    ) {
                      setErrors({
                        ...errors,
                        ["Recent Photograph"]: "File type or size not allowed",
                      });
                    } else {
                      setDocName(prev => ({
                        ...prev,
                        recent_photograph: `${appNo}_recentPhotograph_${currentDate}_5.${
                          e.target.files[0]?.name?.split(".")?.reverse()[0]
                        }`,
                      }));
                      setErrors({
                        ...errors,
                        ["Recent Photograph"]: "",
                      });
                      setDocuments({
                        ...documents,
                        ["Recent Photograph"]: {
                          ...(documents["Recent Photograph"]
                            ? documents["Recent Photograph"]
                            : {}),
                          upload_file: e.target.files[0],
                          upload_file_id: 5,
                          upload_file_name: docName.recent_photograph,
                        },
                      });
                      setPreviews({
                        ...previews,
                        ["Recent Photograph"]: {
                          ...(previews["Recent Photograph"]
                            ? previews["Recent Photograph"]
                            : {}),
                          blob_file: createPreview(e),
                        },
                      });
                    }
                  }}
                />

                <span className="w-100 file_name_container">
                  {docName.recent_photograph}
                </span>
              </FileInputWrapper>

              {!documents["Recent Photograph"]?.upload_file ? (
                <label
                  className="Choose_file_btn"
                  htmlFor={docName.recent_photograph}
                >
                  Choose a file
                </label>
              ) : (
                <button
                  disabled={disable["Recent Photograph"] === false}
                  id="Recent Photograph"
                  className={`col-4 Choose_file_btn btn `}
                  onClick={e => {
                    return (
                      documents["Recent Photograph"]?.upload_file?.name ===
                        undefined || isDocumentUploadLoading
                        ? null
                        : onSubmit(e, "Recent Photograph"),
                      setUploaded("Recent Photograph")
                    );
                  }}
                >
                  {uploaded === "Recent Photograph" &&
                  isDocumentUploadLoading ? (
                    <ButtonLoader />
                  ) : (
                    <p id="Recent Photograph">Upload</p>
                  )}
                </button>
              )}

              {previews["Recent Photograph"]?.blob_file && (
                <a
                  className={`col-4 upload_btn btn `}
                  href={previews["Recent Photograph"]?.blob_file}
                  target="_blank"
                  rel="noreferrer"
                >
                  Preview
                </a>
              )}
            </div>

            {errors["Recent Photograph"] && (
              <ErrorMsg rel>{errors["Recent Photograph"]}</ErrorMsg>
            )}
          </UploaderBody>
        </>
      )}
      {/* ================================= Additional ================================= */}

      {/* ================================= Additional Buttons ================================= */}

      <HR style={{ marginTop: "12px" }} />
      {/* {proposalData?.data["Bank Details"]?.renewal_deposit === "NACH" && (
        <UploaderBody
          className="col-md-12 select_wrapper"
          active={documents["NACH Form"]?.upload_file ? true : false}
        >
          <div className="upload__body">
            <UploadWrap>
              <Select
                options={[
                  { code: "NACH", display_name: "Soft Copy of NACH Form" },
                ]}
                label={"NACH Form"}
                selectedValue={"NACH"}
                noSearch
                readOnly={true}
              // handleChange={value =>
              //   setDocuments({
              //     ...documents,
              //     ["NACH Form"]: {
              //       ...(documents["NACH Form"] ? documents["NACH Form"] : {}),
              //       file_type: value,
              //     },
              //   })
              // }
              />
            </UploadWrap>
            <FileInputWrapper className="col-md-6">
              <input
                type="file"
                name="myfile"
                id={docName.nach_form}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    !checkSize(e, 5) ||
                    !checkDocType(e, ["jpeg", "pdf", "tif", "tiff", "jpg"])
                  ) {
                    setErrors({
                      ...errors,
                      ["NACH Form"]: "File type or size not allowed",
                    });
                  } else {
                    setDocName(prev => ({
                      ...prev,
                      nach_form: `${appNo}_NACHForm_${currentDate}_7.${e.target.files[0]?.name?.split(".")?.reverse()[0]
                        }`,
                    }));
                    setErrors({
                      ...errors,
                      ["NACH Form"]: "",
                    });
                    setDocuments({
                      ...documents,
                      ["NACH Form"]: {
                        ...(documents["NACH Form"]
                          ? documents["NACH Form"]
                          : {}),
                        upload_file: e.target.files[0],
                        upload_file_id: 5,
                        upload_file_name: docName.nach_form,
                        file_type: occupationData?.id_proof,
                      },
                    });
                    setPreviews({
                      ...previews,
                      ["NACH Form"]: {
                        ...(previews["NACH Form"] ? previews["NACH Form"] : {}),
                        blob_file: createPreview(e),
                      },
                    });
                  }
                }}
              />
              <span className="w-100 file_name_container">
                {docName.nach_form}
              </span>
            </FileInputWrapper>

            {!documents["NACH Form"]?.upload_file ? (
              <label className="Choose_file_btn" htmlFor={docName.nach_form}>
                Choose a file
              </label>
            ) : (
              <button
                disabled={disable["NACH Form"] === false}
                className={`col-4 Choose_file_btn btn `}
                onClick={e => {
                  return (
                    documents["NACH Form"]?.upload_file?.name === undefined ||
                      isDocumentUploadLoading
                      ? null
                      : onSubmit(e, "NACH Form"),
                    setUploaded("NACH Form")
                  );
                }}
              >
                {uploaded === "NACH Form" && isDocumentUploadLoading ? (
                  <ButtonLoader />
                ) : (
                  <p id="NACH Form">Upload</p>
                )}
              </button>
            )}

            {previews["NACH Form"]?.blob_file && (
              <a
                className={`col-4 upload_btn btn `}
                href={previews["NACH Form"]?.blob_file}
                target="_blank"
              >
                Preview
              </a>
            )}

            {documents["NACH Form"]?.upload_file && (
              <i
                className="fas fa-trash-alt trash-icon"
                onClick={() => removeDocument("NACH Form")}
              />
            )}
          </div>
          {errors["NACH Form"] && <ErrorMsg>{errors["NACH Form"]}</ErrorMsg>}
        </UploaderBody>
      )} */}
    </div>

    // ================================= FOOTER ============================
  );
};

export default BajajDocumentUpload;
