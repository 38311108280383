import styled from "styled-components";
import * as mq from "../../../styles/mediaQueries";

export const QuotesWrapper = styled.div`
  margin-top: 10px;
  width: 100%;
  /* padding-bottom: 150px; */
`;
export const QuoteCardWrapper = styled.div`
  display: flex;
  height: 160px;
  width: 100%;
  padding: 0 10px;
  justify-content: space-between;
  border-bottom: 1px solid rgba(204, 204, 204, 0.6);
`;
export const CardSection = styled.div`
  width: ${props => (props.width ? "24%" : "18%")};
  height: 100%;
  position: relative;
  &:not(:last-child) {
    border-right: 1px solid rgba(204, 204, 204, 0.6);
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > div,
  & > button {
    margin-left: -8%;
  }
  &:not(:first-child) > div {
    margin-left: ${props => (props.width || props.wide ? "-4%" : "-12%")};
  }
  & > button {
    margin-left: ${props => props.wide && "0"};
  }
`;
export const Medical = styled.div`
  position: absolute;
  min-width: 140px;
  font-size: 12px;
  background: rgb(234, 238, 242);
  padding: 5px;
  text-align: center;
  top: 0px;
  right: -6%;
  clip-path: polygon(100% 0px, 100% 50%, 100% 100%, 0% 100%, 10% 50%, 0% 0%);
`;
export const Value = styled.div`
  font-family: sans-serif;
  font-size: 20px;
`;
export const SubValue = styled.div`
  font-size: 12px;
  margin-top: 6px;
  color: rgb(87, 102, 126);
  font-family: sans-serif;
  width: 100%;
  padding: 0 10px;
  & > div {
    text-align: left;
    display: inline-block;
  }
  & > span,
  & > select {
    float: right;
    color: rgb(87, 102, 126);
    font-weight: 700;
  }
`;
export const LogoWrapper = styled.div`
  & img {
    width: 100%;
    height: 50px;
  }
`;
export const ClaimRatio = styled.div`
  font-size: 10px;
  margin-top: ${props => (props.margin ? "12px" : "4px")};
  border-radius: 8px;
  padding: 2px 6px;
  background-color: rgb(204, 204, 204);
  margin-bottom: 6px;
`;
export const ProductName = styled.div`
  font-size: 12px;
  margin-top: 0px;
  text-align: center;
  padding: 0 4px;
`;
export const BuyNow = styled.button`
  &:focus {
    outline: none;
  }
  min-width: 140px;
  text-align: center;
  padding: 6px 0;
  border-radius: 4px;
  border: none;
  color: #fff;
  background-color: var(--primary-color);
`;
export const OverallWrapper = styled.div`
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.16);
  margin-bottom: 10px;
  position: relative;
  ${mq.sm} {
    box-shadow: rgb(0 0 0 / 7%) 0px 1px 2px, rgb(0 0 0 / 7%) 0px 2px 4px,
      rgb(0 0 0 / 7%) 0px 4px 8px, rgb(0 0 0 / 7%) 0px 8px 16px,
      rgb(0 0 0 / 7%) 0px 16px 32px, rgb(0 0 0 / 7%) 0px 32px 64px;
  }
  & .claims__banner {
    position: absolute;
    min-width: 150px;
    font-size: 9px;
    background: #00a364;
    color: #fff;
    padding: 4px 10px;
    text-align: center;
    top: 8px;
    left: 0;
    font-family: "Inter";
    font-weight: 400;

    clip-path: polygon(100% 0, 90% 50%, 100% 100%, 0 100%, 0 0);
  }

  ${mq.sm} {
    padding: 2.37em 1em 2.37em 1em;
    border-radius: 9px;
    & .quote-card__wrapper .quote-card__know-plan-button {
      display: none;
    }

    & .quote-card__wrapper {
      display: none;
    }

    & .quote-card__section {
      width: auto;
      position: static;
      border: none;
      font-size: 1em;

      display: flex;
      align-items: center;
      gap: 0.36em;
      padding: 0;

      & .quote-card__logo {
        position: static;
        transform: none;
        max-width: 3.67em;
        height: auto;
        max-height: none;
      }

      & .quote-card__name-wrap {
        position: static;
        text-align: left;
        font-size: 0.76em;

        & .quote-card__plan-name {
          font-size: 0.87em;
        }
      }
    }
  }
  .quote-card__payout-data-value,
  .quote-card__policy-data-main {
    color: ${props => (props.differentQuote ? "red" : "#000")};
  }
`;
export const InnerCardWrapper = styled.div`
  width: 100%;
  background-color: rgb(233, 236, 239);
  border-radius: 8px;
  & select {
    background-color: transparent;
  }
`;
export const QuoteCardBottomWrapper = styled.div`
  margin-bottom: 16px;
  align-items: center;
  margin-left: 0;
  margin-right: 0;
  & div {
    text-align: center;
  }
  & .addons {
    cursor: pointer;
  }
  & > .addons span {
    background-color: rgb(204, 204, 204);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
    top: 4px;
    margin-left: 6px;
    font-size: 12px;
    & i {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export const Spin = styled.div`
  position: relative;
  top: 2.5px;
  display: inline-block;
  border: 2px solid #0042a4;
  border-radius: 50%;
  border-top: 2px solid #727d8c;
  border-right: 2px solid #a8acb3;
  border-bottom: 2px solid #c6c8cc;
  border-left: 2px solid #d6d6d6;
  margin: 1px 3.8px 1px 0px;
  width: 11.5px;
  height: 11.5px;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Refresh = styled.div`
  @-moz-keyframes throbber-loader {
    0% {
      background: #dde2e7;
    }
    10% {
      background: #6b9dc8;
    }
    40% {
      background: #dde2e7;
    }
  }
  @-webkit-keyframes throbber-loader {
    0% {
      background: #dde2e7;
    }
    10% {
      background: #6b9dc8;
    }
    40% {
      background: #dde2e7;
    }
  }
  @keyframes throbber-loader {
    0% {
      background: #dde2e7;
    }
    10% {
      background: #b8c3cc;
    }
    40% {
      background: #dde2e7;
    }
  }
  /* :not(:required) hides these rules from IE9 and below */
  :not(:required) {
    -moz-animation: throbber-loader 2000ms 300ms infinite ease-out;
    -webkit-animation: throbber-loader 2000ms 300ms infinite ease-out;
    animation: throbber-loader 2000ms 300ms infinite ease-out;
    background: #dde2e7;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    text-indent: -9999px;
    top: 2px;
    width: 10.5px;
    height: 8.5px;
  }
  :not(:required):before,
  :not(:required):after {
    background: #dde2e7;
    content: "\x200B";
    display: inline-block;
    width: 10.5px;
    height: 10.5px;
    border-radius: 50%;
    position: absolute;
  }
  :not(:required):before {
    -moz-animation: throbber-loader 2000ms 150ms infinite ease-out;
    -webkit-animation: throbber-loader 2000ms 150ms infinite ease-out;
    animation: throbber-loader 2000ms 150ms infinite ease-out;
    left: 11px;
  }
  :not(:required):after {
    -moz-animation: throbber-loader 2000ms 450ms infinite ease-out;
    -webkit-animation: throbber-loader 2000ms 450ms infinite ease-out;
    animation: throbber-loader 2000ms 450ms infinite ease-out;
    right: -21.5px;
  }
`;
