import React from "react";
import { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import useUrlQuery from "../../custom-hooks/useUrlQuery";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import useMediaQuery from "../../custom-hooks/useMediaQuery";
import MainContent from "./components/MainContent/MainContent";
import RidersProvider from "../../pages/riders/utils/RiderContext";
import { CloseBackButton } from "..";
import { BackButton } from "..";
import * as mq from "../../styles/mediaQueries";
import styled from "styled-components/macro";
const KnowMorePopup = () => {
  const [currentSection, setCurrentSection] = useState(0);
  const { selectedQuote } = useSelector(state => state.quotes);
  const enquiryId = useUrlQuery("enquiryId");
  const matches = useMediaQuery(`${mq.smForHook}`);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentSection]);
  if (!Object.keys(selectedQuote).length)
    return <Redirect to={`/quote?enquiryId=${enquiryId}`} />;
  return (
    <div
      css={`
        min-height: 690px;
        ${mq.sm} {
          min-height: 550px;
          overflow: hidden;
        }
      `}
    >
      <RidersProvider>
        <Header
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
        />
        <Container
          css={`
            ${mq.sm} {
              overflow-y: scroll;
              height: calc(100% - (130px+5em));
              padding-bottom: 20px;
            }
            ${mq.md} {
              max-width: 756px;
            }
          `}
        >
          <MainContent
            setCurrentSection={setCurrentSection}
            currentSection={currentSection}
          />
        </Container>
        <Footer />
      </RidersProvider>
      {matches ? (
        <StyledCloseButton toPage="/quote" />
      ) : (
        <StyledBackButton toPage="/quote" />
      )}
    </div>
  );
};

export default KnowMorePopup;

const StyledBackButton = styled(BackButton)`
  position: absolute;
  top: 4em;
  left: 2em;
  border-radius: 50%;
  height: 2em;
  width: 2em;
  font-size: 1.37rem;
  background: #fff;
`;

const StyledCloseButton = styled(CloseBackButton)`
  left: 88vw;
  float: right;
  top: 1em;
  position: fixed;
  z-index: 100;
  border-radius: 50%;
  height: 2em;
  width: 2em;
  background: #fff;
  font-size: 1em;
  font-weight: bold;
  ${mq.sm} {
    left: 88vw;
  }
  @media screen and (min-width: 768px) and (max-width: 884px) {
    left: 95vw;
  }
`;
