import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateEnquiries } from "../../../Landing/input.slice";
import { form3 } from "../../../input/validations";
import { createCart } from "../../Quotes/quotes.slice";
import {
  Details,
  //  ErrorMsg, Label,
  Wrapper,
} from "../EligibilityCard.style";
import useUrlQuery from "../../../../custom-hooks/useUrlQuery";
import "styled-components/macro";
import TextInput from "./TextInput/TextInput";
import * as mq from "../../../../styles/mediaQueries";
import { toCapitalize } from "../../../ProposalPage/inputUtils/onInput";
// import useMediaQuery from "../../../../custom-hooks/useMediaQuery";
import ButtonLoader from "../../../../components/CardSkeletonLoader/ButtonLoader/ButtonLoader";
import {
  riderApi,
  useGetRedirectionMutation,
} from "../../../riders/services/rider";
import { toast } from "react-toastify";
import { redirectToIc } from "../../../riders/helpers";

const PersonalDetails = ({ isActive, afterSubmit, goBack }) => {
  const [getRedirection, { status: redirectionApiStatus }] =
    useGetRedirectionMutation();

  const {
    data: { name, email, mobile, dob, gender },
    isEnquiryLoading,
  } = useSelector(state => state.inputForm);

  const [input, setInput] = useState({ name, email, mobile, dob, gender });

  const history = useHistory();

  useEffect(() => {
    setInput({ name, email, mobile, dob, gender });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(form3),
    defaultValues: {
      name: input.name,
      email: input.email,
      mobile: input.mobile,
    },
  });

  const { selectedQuote, cartLoading } = useSelector(state => state.quotes);
  const enquiryId = useUrlQuery("enquiryId");

  // const matches = useMediaQuery(mq.smForHook);
  // const { ridersAdded } = useSelector(state => state.compare);
  const dispatch = useDispatch();
  const onSubmit = async data => {
    await dispatch(updateEnquiries({ input: { data } })).then(() => {
      if (afterSubmit) {
        afterSubmit(data);
        return;
      }
      dispatch(
        createCart(selectedQuote, () => {
          if (selectedQuote.is_rider_available !== "N") {
            history.push(`/riders?enquiryId=${enquiryId}`);
            selectedQuote?.differentQuote === true &&
              dispatch(riderApi.util.invalidateTags(["Quote"]));
          } else {
            if (selectedQuote.is_user_journey !== "N")
              getRedirection({
                company_alias: selectedQuote?.company_alias,
                product_id: selectedQuote?.product?.id,
                term_insurance_id: selectedQuote?.term_insurance_id,
              }).then(res => {
                if (res.data && redirectionApiStatus !== "rejected") {
                  const { data } = res.data;
                  if (
                    data[0] &&
                    ["R", "PF"].includes(data[0].journey_type) &&
                    data[0].redirection_url
                  ) {
                    redirectToIc(res.data.data[0]);
                    return;
                  } else {
                    dispatch(riderApi.util.invalidateTags(["Enquiry"]));
                    history.push(`/proposal?enquiryId=${enquiryId}`);
                  }
                } else {
                  toast.error(
                    res?.error?.data?.message
                      ? res?.error?.data?.message
                      : "Something went wrong",
                    {
                      hideProgressBar: true,
                      toastId: "failed1",
                      autoClose: 3000,
                      position: "bottom-center",
                      theme: "colored",
                    },
                  );
                }
              });
          }
        }),
      );
    });
  };

  if (!isActive) return null;

  return (
    <>
      <Details onSubmit={handleSubmit(onSubmit)}>
        <Wrapper fullWidth>
          <TextInput
            capitalize
            label="Full Name*"
            style={{ textTransform: "capitalize" }}
            onInput={toCapitalize}
            inputProps={{ placeholder: "Your Name", readOnly: true }}
            value={input.name}
            {...register("name")}
            error={errors.name && errors.name.message}
          />
        </Wrapper>

        {/* <Wrapper fullWidth>
          <TextInput
            capitalize
            placeholder="ex. abc ds"
            defaultValue={dob.split("-").reverse().join("/")}
            readonly
            {...register("dob")}
            readOnly
          />
          <Label>Date of Birth</Label>
          {errors.dob && <ErrorMsg>{errors.dob.message}</ErrorMsg>}
        </Wrapper> */}
        {/* 
        <Wrapper style={{ pointerEvents: "none" }} fullWidth>
          <Label>Gender</Label>
          <Select defaultValue={gender}>
            <option value="M">Male</option>
            <option value="F">Female</option>
          </Select>
        </Wrapper> */}
        {/* 
        <Wrapper style={{ pointerEvents: "none" }} fullWidth>
          <Label>Tobacco Habit</Label>
          <Select defaultValue="N">
            <option value="N">Non Smoker</option>
          </Select>
        </Wrapper> */}

        <Wrapper fullWidth>
          <TextInput
            label="Email Id*"
            style={{ textTransform: "capitalize" }}
            inputProps={{ placeholder: "xyz@gmail.com", readOnly: true }}
            value={input.email}
            {...register("email")}
            error={errors.email && errors.email.message}
          />
        </Wrapper>

        <Wrapper fullWidth>
          <TextInput
            label="Mobile No*"
            inputProps={{
              placeholder: "9876543210",
              readOnly: true,
              maxLength: 10,
            }}
            error={errors.mobile && errors.mobile.message}
            value={input.mobile}
            readOnly
          />
        </Wrapper>

        <div
          css={`
            display: flex;
            gap: 0;
            width: 50%;
            margin: auto;
            ${mq.sm} {
              gap: 30px;
              width: 100%;
              margin: 1rem auto;
            }
          `}
        >
          <button
            css={`
              width: 45%;
              background: unset;
              color: var(--secondary-text);
              font-weight: 900;
              font-size: 16px;
            `}
            onClick={goBack}
          >
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="none"
                stroke-linecap="square"
                stroke-miterlimit="10"
                stroke-width="48"
                d="M244 400L100 256l144-144M120 256h292"
              ></path>
            </svg>{" "}
            Back
          </button>

          {/* <div
            css={`
              margin-top: 1em;
              width: 100%;
              ${mq.sm} {
                margin: 0;
                width: auto;
              }
            `}
          > */}
          <button
            css={`
              width: 6em;
              padding: 0.6em 0;
              font-size: 1em;
              border-radius: 0;
              :hover {
                background-color: var(--primary-color);
              }
            `}
            type="submit"
            className="btn btn-primary mx-auto d-block btn-lg shadow-none"
          >
            {isEnquiryLoading || cartLoading ? <ButtonLoader /> : "Next"}
          </button>
          {/* </div> */}
        </div>
      </Details>
    </>
  );
};

export default PersonalDetails;
