import * as yup from "yup";
import "yup-phone";
import moment from "moment";
import { mobileRegex } from "../ProposalPage/inputUtils/regex";
export const form1Schema = ({ minAge, maxAge }) =>
  yup.object({
    dob: yup
      .string()
      .required("Date of Birth is required")
      .test("dob", "Age should be between 18 and 65", value => {
        const age = moment().diff(moment(value, "DD/MM/YYYY"), "years");
        return age >= minAge && age <= maxAge;
      }),
  });
export const form2 = yup.object({
  occupation: yup.string().required("Occupation is required."),
  education: yup.string().required("Education is required."),
  term_annual_income: yup.string().required("Annual Income is required"),
});

export const form3 = yup.object({
  name: yup
    .string()
    .min(2, "Minimum 2 characters required")
    .max(30)
    .required("Name is required.")
    .test("name", "Please enter full name", val => {
      if (!val) return false;
      if (val)
        return val.split(" ").length >= 2 && val.split(" ")[1].length > 0;
    }),
  email: yup
    .string()
    .email("Please enter a valid email.")
    .required("Email is required.")

    .max(50, "Maximum length is 50 characters"),
  mobile: yup
    .string()
    .matches(mobileRegex, "Please enter a valid phone no.")
    .required("Phone is required."),
});

const checkValue = (str, max) => {
  if (str.charAt(0) !== "0" || str === "00") {
    var num = parseInt(str);
    if (isNaN(num) || num <= 0 || num > max) num = 1;
    str =
      num > parseInt(max.toString().charAt(0)) && num.toString().length === 1
        ? "0" + num
        : num.toString();
  }
  return str;
};
const getAge = value => {
  if (value) {
    let date = value.replace(/ /g, "");
    let a = moment();
    date = moment(value, "DD/MM/YYYY");
    return a.diff(date, "y");
  } else {
    return 0;
  }
};

export const dateUtil = event => {
  let input = event.target.value;
  if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
  var values = input.split("/").map(function (v) {
    return v.replace(/\D/g, "");
  });
  if (values[0]) values[0] = checkValue(values[0], 31);
  if (values[1]) values[1] = checkValue(values[1], 12);
  var output = "";

  output = values.map(function (v, i) {
    return v.length === 2 && i < 2 ? v + " / " : v;
  });
  event.target.value = output.join("").substr(0, 14);

  if (event.target.value.length === 14) {
    let age = getAge(event.target.value);
    event.target.blur();
    return age;
  }
};

export const modifyDetails = ({ minAge, maxAge }) =>
  yup.object({
    tobacco_habit: yup.string().required("This field is required."),
    dob: yup
      .string()
      .required("Date of Birth is required")
      .min(10, "Please enter a valid date")
      .test("dob", "Age should be between 18 and 65", value => {
        const age = moment().diff(moment(value, "DD/MM/YYYY"), "years");
        return age >= minAge && age <= maxAge;
      }),
    gender: yup.string().required("This field is required."),
    occupation: yup.string().required("Occupation is required."),
    education: yup.string().required("Education is required."),
    term_annual_income: yup.string().required("Annual Income is required"),
    name: yup.string().required("Name is required."),
    email: yup
      .string()
      .email("Please enter a valid email.")
      .required("Email is required."),
    mobile: yup
      .string()
      .matches(/^[5-9]\d{9}$/, "Please enter a valid phone no.")
      .required("Phone is required."),
  });
