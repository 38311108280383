import { SiWhatsapp } from "react-icons/si";
import { MdMailOutline, MdOutlinePhone } from "react-icons/md";
import { HiOutlineClipboardCheck } from "react-icons/hi";
import { useShareQuoteMutation } from "../../riders/services/rider";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { ErrorMsg } from "../EligibilityCard/EligibilityCard.style";
import styled from "styled-components/macro";
import { useNumberInput } from "../../../utils";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { InputAdornment } from "@material-ui/core";
import {
  selectAddedRiders,
  selectSelectedQuotesToShare,
} from "../Quotes/quotes.slice";
import * as mq from "../../../styles/mediaQueries";

import { patchEnquiries } from "./../../product-page/Filters/serviceApi";
import ButtonLoader from "../../../components/CardSkeletonLoader/ButtonLoader/ButtonLoader";
import QRCode from "qrcode.react";
import TextInput from "../../../pages/product-page/EligibilityCard/components/TextInput/TextInput";
import {
  allowOnlyNumbers,
  restrictSpace,
} from "../../ProposalPage/inputUtils/onInput";
import html2canvas from "html2canvas";
import { useLocation } from "react-router-dom";

function ShareQuotePopup({ show, handleClose, shareAll, currentSection }) {
  const location = useLocation();
  const isNewCompare = location.pathname.includes("new-compare");
  const [imageUrl, setImageUrl] = useState("");
  const allProducts = useSelector(selectSelectedQuotesToShare);
  const { data } = useSelector(state => state.inputForm);
  // eslint-disable-next-line no-unused-vars
  const [showMore, setShowMore] = useState(false);

  getQuotesPageImage(isNewCompare).then(data => {
    if (data) {
      setImageUrl(data);
    }
  });

  const { quotesList, selectCompareQuoteToShare } = useSelector(
    state => state?.newCompare,
  );

  const finalSelectQuoteList =
    shareAll && currentSection === 2 ? quotesList : selectCompareQuoteToShare;

  const productData = finalSelectQuoteList.map(quote => {
    const {
      termInsuranceId,
      product,
      companyAlias,
      termPayFrequency,
      termCoverupto,
      termPayTill,
      termSumAssured,
      totalPremium,
    } = quote;
    return {
      product_id: product?.code,
      company_alias: companyAlias?.code,
      term_insurance_id: termInsuranceId,
      term_pay_frequency: termPayFrequency?.code,
      term_coverupto: termCoverupto?.code,
      term_pay_till: termPayTill?.code,
      term_sum_assured: termSumAssured?.code,
      premium_amount: totalPremium,
    };
  });
  return (
    <div>
      <div
        css={`
          width: 100%;
          margin-top: -2em;
        `}
      >
        {/* <ShareOnMailForm />
        <ShareOnWhatsappForm mobile={data.mobile} />
        <MoreOptionsButton onClick={toggleShow}>
          {!showMore ? "View More Options" : "Hide "}
        </MoreOptionsButton> */}
        {
          <div
            css={`
              animation-duration: 0.9s;
              animation-name: fadein;
              @keyframes fadein {
                0% {
                  opacity: 0;
                }
                100% {
                  opacity: 1;
                }
              }
            `}
          >
            <ShareOnMailForm
              imageUrl={imageUrl}
              allProducts={allProducts}
              isNewCompare={isNewCompare}
              productData={productData}
            />
            <ShareOnWhatsappForm
              mobile={data.mobile}
              imageUrl={imageUrl}
              allProducts={allProducts}
            />
            <ShareViaText imageUrl={imageUrl} />
            {/* <MoreOptionsButton onClick={ViewMore}>
              {showMore ? "Hide" : "View More Options"}
            </MoreOptionsButton> */}
          </div>
        }
        {showMore && (
          <div>
            <QR />
            <CopyToClipboardForm />
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}

export const QR = () => {
  // const handleDownloadClick = () => {
  //   const canvas = document.getElementById("qr-code");
  //   const pngUrl = canvas
  //     .toDataURL("image/png")
  //     .replace("image/png", "image/octet-stream");

  //   let downloadLink = document.createElement("a");
  //   downloadLink.href = pngUrl;
  //   downloadLink.download = `${window.location.pathname.slice(1)}-link.png`;
  //   document.body.appendChild(downloadLink);
  //   downloadLink.click();
  //   document.body.removeChild(downloadLink);
  // };
  return (
    <div
      css={`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        margin: 20px auto 10px;
        ${mq.sm} {
          margin: 10px auto;
        }
      `}
    >
      <QRCode value={window.location.href} id="qr-code" size={130} />
      {/* <button
        onClick={handleDownloadClick}
        css={`
          width: max-content;
          background-color: var(--primary-color);
          border: none;
          color: #fff;
          padding: 0.8em;
          font-size: 12px;
          border-radius: 0;
        `}
      >
        Download QR Code
      </button> */}
    </div>
  );
};
export function CopyToClipboardForm() {
  const [allow, setAllow] = useState(true);

  const handleSubmit = evt => {
    evt.preventDefault();
    if (allow === true) {
      setAllow(false);
      navigator.clipboard.writeText(window.location).then(() =>
        toast.success("Copied!", {
          hideProgressBar: true,
          position: "bottom-right",
          autoClose: 1000,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
        }),
      );
      setTimeout(() => {
        setAllow(true);
      }, 2400);
    } else return null;
  };

  return (
    <ShareViaForm
      icon={<HiOutlineClipboardCheck />}
      onSubmit={handleSubmit}
      submitButtonText="Copy"
      submitButtonIcon="far fa-clipboard"
    >
      <div
        css={`
              font-size: 15px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
    }
              ${mq.sm} {
                font-size: 13px;
              }
            `}
      >
        {window.location.href}
      </div>
      {/* <ShareFormInput value={window.location.href} /> */}
    </ShareViaForm>
  );
}

const schema = yup.object({
  email: yup.string().email().required("Please enter email"),
});

const mobileSchema = yup.object({
  mobile: yup
    .number()
    .integer()
    .typeError("Please enter a valid phone number")
    .required("Required"),
});

async function getQuotesPageImage(isNewCompare) {
  const root = document.getElementById(
    isNewCompare ? "new-compare" : "quotes_section",
  );
  const canvas = await html2canvas(root);
  const image = canvas.toDataURL("image/png");

  return image.split(",")[1];
}

function ShareOnMailForm({ allProducts, imageUrl, isNewCompare, productData }) {
  const [shareQuotes, { isLoading }] = useShareQuoteMutation();
  // const allProducts = useSelector(selectSelectedQuotesToShare);
  const { data } = useSelector(state => state.inputForm);
  const addedRiders = useSelector(selectAddedRiders);
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { email: data.email || "" },
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmit = async formData => {
    // eslint-disable-next-line no-unused-vars
    const { data } = await patchEnquiries({
      input: {
        selected_addons: addedRiders,
      },
    });

    if (isNewCompare) {
      shareQuotes({
        purpose: "quote",
        email: formData.email,
        via: "email",
        product_data: productData,
        stage: "QUOTE",
        image_to_send: imageUrl,
      }).then(res => {
        if (res.error) {
          toast.error("Sharing Failed!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        } else {
          toast.success("Email Shared Successfully!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      });
    } else {
      shareQuotes({
        purpose: "quote",
        email: formData.email,
        via: "email",
        image_to_send: imageUrl,
        product_ids: allProducts,
        sms: "",
        mode: ["EMAIL"],
        stage: "QUOTE",
        whatsapp: "",
      }).then(res => {
        if (res.error) {
          toast.error("Sharing Failed!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        } else {
          toast.success("Email Shared Successfully!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      });
    }
  };
  const [email, setEmail] = useState(data.email);

  return (
    <ShareViaForm
      // icon={<MdMailOutline />}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
      // errorMessage={errors.email && "Please enter a valid email"}
    >
      <TextInput
        error={errors.email && errors.email.message}
        {...register("email")}
        value={email}
        onChange={evt => {
          register("email").onChange(evt);
          setEmail(evt.target.value);
          setValue("email", evt.target.value);
        }}
        label="Email*"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MdMailOutline className="fs-6" />
            </InputAdornment>
          ),
        }}
        onInput={restrictSpace}
      />

      {/* <ShareFormInput {...register("email")} placeholder="Enter your Email" /> */}
    </ShareViaForm>
  );
}

export function ShareOnWhatsappForm({ mobile, imageUrl, allProducts }) {
  const [phone, handleChange] = useNumberInput(mobile);
  const addedRiders = useSelector(selectAddedRiders);
  const [shareQuotes] = useShareQuoteMutation();
  const [error, setError] = useState(false);
  useEffect(() => {
    if (phone.length === 10) setError(false);
  }, [phone]);
  const handleSubmit = async evt => {
    evt.preventDefault();
    // eslint-disable-next-line no-unused-vars
    const { data } = await patchEnquiries({
      input: {
        selected_addons: addedRiders,
      },
    });
    if (phone.length === 10) {
      setError(false);
      shareQuotes({
        purpose: "quote",
        groupCode: "10003511",
        mode: ["WHATSAPP"],
        image_to_send: imageUrl,
        via: "whatsapp",
        product_ids: allProducts,
        stage: "QUOTE",
        whatsapp: phone,
      }).then(res => {
        if (res.error) {
          toast.error("Sharing Failed!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        } else {
          toast.success("Email Shared Successfully!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      });

      // form.setAttribute("action", "https://api.whatsapp.com/send");
      // form.setAttribute("target", "_blank");
      // form.setAttribute("method", "GET");

      // const phoneInput = document.createElement("input");
      // phoneInput.setAttribute("value", "91" + phone);
      // phoneInput.setAttribute("name", "phone");

      // const textInput = document.createElement("input");
      // textInput.setAttribute("value", window.location);
      // textInput.setAttribute("name", "text");

      // form.appendChild(phoneInput);
      // form.appendChild(textInput);

      // document.body.appendChild(form);
      // form.submit();
      // document.body.removeChild(form);
    } else setError("Please enter a valid whatsapp no.");
  };
  // const handleSubmit = async evt => {
  //   evt.preventDefault();
  //   const { data } = await patchEnquiries({
  //     input: {
  //       selected_addons: addedRiders,
  //     },
  //   });
  //   if (phone.length === 10) {
  //     setError(false);
  //     const form = document.createElement("form");
  //     form.setAttribute("action", "https://api.whatsapp.com/send");
  //     form.setAttribute("target", "_blank");
  //     form.setAttribute("method", "GET");

  //     const phoneInput = document.createElement("input");
  //     phoneInput.setAttribute("value", "91" + phone);
  //     phoneInput.setAttribute("name", "phone");

  //     const textInput = document.createElement("input");
  //     textInput.setAttribute("value", window.location);
  //     textInput.setAttribute("name", "text");

  //     form.appendChild(phoneInput);
  //     form.appendChild(textInput);

  //     document.body.appendChild(form);
  //     form.submit();
  //     document.body.removeChild(form);
  //   } else setError("Please enter a valid whatsapp no.");
  // };

  return (
    <ShareViaForm
      // icon={<SiWhatsapp />}
      onSubmit={handleSubmit}
      // errorMessage={error}
    >
      <TextInput
        label=" Whatsapp Number"
        variant="outlined"
        error={error}
        value={phone}
        name="phone"
        onChange={handleChange}
        inputProps={{
          minLength: 2,
          maxLength: 10,
          pattern: "[0-9]*",
          inputMode: "numeric",
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SiWhatsapp className="fs-6" />
            </InputAdornment>
          ),
        }}
      />
      {/* <ShareFormInput
        type="tel"
        maxLength={10}
        value={phone}
        onChange={handleChange}
        placeholder="Enter your Whatsapp Number"
      /> */}
    </ShareViaForm>
  );
}

function ShareViaText({ imageUrl, allProducts }) {
  const [shareQuotes, { isLoading }] = useShareQuoteMutation();
  // const allProducts = useSelector(selectSelectedQuotesToShare);
  const { data } = useSelector(state => state.inputForm);
  const addedRiders = useSelector(selectAddedRiders);
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { mobile: data.mobile },
    resolver: yupResolver(mobileSchema),
    mode: "onChange",
  });
  const onSubmit = async formData => {
    // eslint-disable-next-line no-unused-vars
    const { data } = await patchEnquiries({
      input: {
        selected_addons: addedRiders,
      },
    });
    shareQuotes({
      purpose: "quote",
      email: "",
      sms: formData.mobile,
      mode: ["SMS"],
      image_to_send: imageUrl,
      via: "sms",
      product_ids: allProducts,
      stage: "QUOTE",
      whatsapp: "",
    }).then(res => {
      if (res.error) {
        toast.error("Sharing Failed!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        toast.success("Message Shared Successfully!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    });
  };
  const [mobile, setMobile] = useState(data.mobile);

  return (
    <ShareViaForm
      // icon={<MdMailOutline />}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
      // errorMessage={errors.email && "Please enter a valid email"}
    >
      <TextInput
        error={errors.mobile && errors.mobile.message}
        {...register("mobile")}
        value={mobile}
        onChange={evt => {
          register("mobile").onChange(evt);
          setMobile(evt.target.value);
          setValue("mobile", evt.target.value);
        }}
        label="Mobile"
        onInput={allowOnlyNumbers}
        inputProps={{
          minLength: 2,
          maxLength: 10,
          pattern: "[0-9]*",
          inputMode: "numeric",
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MdOutlinePhone className="fs-6" />
            </InputAdornment>
          ),
        }}
      />

      {/* <ShareFormInput {...register("email")} placeholder="Enter your Email" /> */}
    </ShareViaForm>
  );
}

export const ShareFormInput = styled.input`
  border: none;
  outline: none;
  width: 100%;

  font-size: 15px;
  ${mq.sm} {
    width: 100%;
    font-size: 13px;
  }
`;

export function ShareViaForm({
  children,
  onSubmit = () => {},
  icon,
  submitButtonText = "Share",
  submitButtonIcon = "fas fa-share",
  isLoading = false,
  errorMessage = "",
  ...props
}) {
  return (
    <div
      css={`
        width: 100%;
        margin: 1em 0;
        ${mq.sm} {
          margin: 0;
        }
      `}
    >
      <form
        css={`
          display: flex;
          justify-content: space-between;
          gap: 1em;
        `}
        onSubmit={onSubmit}
        autoComplete="off"
        {...props}
      >
        <div
          css={`
            display: flex;
            align-items: center;
            flex: 1;
            // border: 1px solid #cfcfcf;
            gap: 1em;
            min-width: 0;

            padding: 0 1em;
            ${mq.sm} {
              gap: 0.5em;
              padding: 0 0 0 0.3em;
            }
          `}
        >
          <div
            css={`
              font-size: 2em;
              color: #56595e;
              ${mq.sm} {
                font-size: 1.5em;
              }
            `}
          >
            {icon}
          </div>
          {children}
        </div>
        <button
          type="submit"
          css={`
            width: 120px;
            background-color: var(--primary-color);
            border: none;
            color: #fff;
            padding: 0.8em;
            font-size: 15px;
            border-radius: 0;
            ${mq.sm} {
              width: 60px;
              height: 48px;
              border-radius: 3px;
              padding: 0.5em;
              font-size: 10px;
            }
          `}
        >
          {isLoading ? (
            <ButtonLoader />
          ) : (
            <>
              {" "}
              {submitButtonText}
              <i className={`${submitButtonIcon} ms-2`} />
            </>
          )}
        </button>
      </form>
      <ErrorMsg
        css={`
          font-size: 1rem;
          position: static;
        `}
      >
        {errorMessage}
      </ErrorMsg>
    </div>
  );
}

export { ShareQuotePopup };

export const MoreOptionsButton = styled.button`
  width: 100%;
  background: var(--primary-color);
  border: none;
  color: #fff;
  padding: 0.8em;
  font-size: 15px;
  ${mq.sm} {
    width: 98%;
    margin-left: 2%;
  }
`;
