import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MdMailOutline, MdOutlinePhone } from "react-icons/md";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  InputAdornment,
  // TextField
} from "@material-ui/core";

import {
  // CopyToClipboardForm,
  // MoreOptionsButton,
  // QR,
  // ShareFormInput,
  // ShareOnWhatsappForm,
  ShareViaForm,
} from "../product-page/components/ShareQuotePopup";

import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import {
  useGetCartQuery,
  useShareQuoteMutation,
} from "../riders/services/rider";
import TextInput from "../../pages/product-page/EligibilityCard/components/TextInput/TextInput";
import { allowOnlyNumbers, restrictSpace } from "./inputUtils/onInput";
// import { FaPhoneAlt } from "react-icons/fa";
import { useNumberInput } from "../../utils";
import { selectAddedRiders } from "../product-page/Quotes/quotes.slice";
import { patchEnquiries } from "../product-page/Filters/serviceApi";
import { SiWhatsapp } from "react-icons/si";

const ShareProposalPopup = ({ company_alias, productName, sumAssuured }) => {
  const { data: userData } = useSelector(state => state.inputForm);
  const [showMore, setShowMore] = useState(false);
  const toggleShow = () => setShowMore(!showMore);
  const [verifyError, setVerifyError] = useState(false);
  console.log("verifyError", verifyError?.email?.message);
  return (
    <div>
      <ShareProposalOnMailForm setVerifyError={setVerifyError} />
      {verifyError?.email?.message && <div style={{ height: "23px" }} />}
      <ShareOnWhatsappForm mobile={userData.mobile} />
      <ShareProposalViaText />
      {/* <MoreOptionsButton
        onClick={toggleShow}
        css={`
          margin-left: 8px;
        `}
      >
        {" "}
        {!showMore ? "View More Options" : "Hide"}
      </MoreOptionsButton> */}

      {/* {showMore && (
        <>
          <QR />
          <CopyToClipboardForm />
        </>
      )} */}
    </div>
  );
};

export default ShareProposalPopup;

const schema = yup.object({
  email: yup.string().email().required("Please enter email"),
});

const mobileSchema = yup.object({
  mobile: yup
    .number()
    .integer()
    .typeError("Please enter a valid phone number")
    .required("Required"),
});

function ShareProposalOnMailForm({
  setVerifyError,
  company_alias,
  productName,
  sumAssuured,
}) {
  const [shareQuotes, { isLoading }] = useShareQuoteMutation();
  const { data } = useSelector(state => state.inputForm);
  //const addedRiders = useSelector(selectAddedRiders);
  // const { showDifference, selectedFeatures } = useSelector(
  //   state => state.compare,
  // );
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { email: data.email || "" },
    resolver: yupResolver(schema),
  });
  const [email, setEmail] = useState(data.email);
  setVerifyError(errors);
  const onSubmit = async formData => {
    // const { data } = await patchEnquiries({
    //   input: {
    //     selected_addons: addedRiders,
    //   },
    // });
    shareQuotes({
      purpose: "proposal",
      email: formData.email,
      via: "email",
      // selected_riders: addedRiders,
      // show_difference: showDifference,
      // selected_features: selectedFeatures,
      sms: "",
      mode: ["EMAIL"],
      stage: "PROPOSAL",
      whatsapp: "",
    }).then(res => {
      if (res.error) {
        toast.error("Sharing Failed!", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        toast.success("Proposal Shared Successfully!", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    });
  };

  return (
    <ShareViaForm
      // icon={<MdMailOutline />}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
      // errorMessage={errors.email && "Please enter a valid email"}
    >
      <TextInput
        error={errors.email && errors.email.message}
        {...register("email")}
        value={email}
        onChange={evt => {
          register("email").onChange(evt);
          setEmail(evt.target.value);
          setValue("email", evt.target.value);
        }}
        label="Email*"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MdMailOutline className="fs-6" />
            </InputAdornment>
          ),
        }}
        onInput={restrictSpace}
      />
      {/* <ShareFormInput {...register("email")} /> */}
    </ShareViaForm>
  );
}
export function ShareOnWhatsappForm({ mobile, imageUrl, allProducts }) {
  const [phone, handleChange] = useNumberInput(mobile);
  const addedRiders = useSelector(selectAddedRiders);
  const [shareQuotes] = useShareQuoteMutation();
  const [error, setError] = useState(false);
  useEffect(() => {
    if (phone.length === 10) setError(false);
  }, [phone]);
  const handleSubmit = async evt => {
    evt.preventDefault();
    await patchEnquiries({
      input: {
        selected_addons: addedRiders,
      },
    });
    if (phone.length === 10) {
      setError(false);
      shareQuotes({
        purpose: "proposal",
        groupCode: "10003511",
        mode: ["WHATSAPP"],

        via: "whatsapp",
        stage: "PROPOSAL",
        whatsapp: phone,
      }).then(res => {
        if (res.error) {
          toast.error("Sharing Failed!", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        } else {
          toast.success("Proposal Shared Successfully!", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      });

      // form.setAttribute("action", "https://api.whatsapp.com/send");
      // form.setAttribute("target", "_blank");
      // form.setAttribute("method", "GET");

      // const phoneInput = document.createElement("input");
      // phoneInput.setAttribute("value", "91" + phone);
      // phoneInput.setAttribute("name", "phone");

      // const textInput = document.createElement("input");
      // textInput.setAttribute("value", window.location);
      // textInput.setAttribute("name", "text");

      // form.appendChild(phoneInput);
      // form.appendChild(textInput);

      // document.body.appendChild(form);
      // form.submit();
      // document.body.removeChild(form);
    } else setError("Please enter a valid whatsapp no.");
  };
  // const handleSubmit = async evt => {
  //   evt.preventDefault();
  //   const { data } = await patchEnquiries({
  //     input: {
  //       selected_addons: addedRiders,
  //     },
  //   });
  //   if (phone.length === 10) {
  //     setError(false);
  //     const form = document.createElement("form");
  //     form.setAttribute("action", "https://api.whatsapp.com/send");
  //     form.setAttribute("target", "_blank");
  //     form.setAttribute("method", "GET");

  //     const phoneInput = document.createElement("input");
  //     phoneInput.setAttribute("value", "91" + phone);
  //     phoneInput.setAttribute("name", "phone");

  //     const textInput = document.createElement("input");
  //     textInput.setAttribute("value", window.location);
  //     textInput.setAttribute("name", "text");

  //     form.appendChild(phoneInput);
  //     form.appendChild(textInput);

  //     document.body.appendChild(form);
  //     form.submit();
  //     document.body.removeChild(form);
  //   } else setError("Please enter a valid whatsapp no.");
  // };

  return (
    <ShareViaForm
      // icon={<SiWhatsapp />}
      onSubmit={handleSubmit}
      // errorMessage={error}
    >
      <TextInput
        label=" Whatsapp Number"
        variant="outlined"
        error={error}
        value={phone}
        name="phone"
        onChange={handleChange}
        inputProps={{
          minLength: 2,
          maxLength: 10,
          pattern: "[0-9]*",
          inputMode: "numeric",
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SiWhatsapp className="fs-6" />
            </InputAdornment>
          ),
        }}
      />
      {/* <ShareFormInput
        type="tel"
        maxLength={10}
        value={phone}
        onChange={handleChange}
        placeholder="Enter your Whatsapp Number"
      /> */}
    </ShareViaForm>
  );
}
function ShareProposalViaText() {
  const [shareQuotes, { isLoading }] = useShareQuoteMutation();
  const { data: cartData } = useGetCartQuery();
  const { data } = useSelector(state => state.inputForm);
  //const addedRiders = useSelector(selectAddedRiders);
  // const { showDifference, selectedFeatures } = useSelector(
  //   state => state.compare,
  // );
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { mobile: data.mobile },
    resolver: yupResolver(mobileSchema),
  });
  const [mobile, setMobile] = useState(data.mobile);

  const onSubmit = async formData => {
    // const { data } = await patchEnquiries({
    //   input: {
    //     selected_addons: addedRiders,
    //   },
    // });
    shareQuotes({
      purpose: "proposal",
      mobile: formData.mobile,
      sms: formData.mobile,
      via: "sms",
      mode: ["SMS"],
      stage: "PROPOSAL",
      whatsapp: "",
      product_id: cartData?.product?.id,
    }).then(data => {
      if (data.error) {
        toast.error("Sharing Failed!", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        toast.success("Compare Shared Successfully!", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    });
  };

  return (
    <ShareViaForm
      // icon={<MdMailOutline />}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
      // errorMessage={errors.email && "Please enter a valid email"}
    >
      <TextInput
        error={errors.mobile && errors.mobile.message}
        {...register("mobile")}
        value={mobile}
        onChange={evt => {
          register("mobile").onChange(evt);
          setMobile(evt.target.value);
          setValue("mobile", evt.target.value);
        }}
        onInput={allowOnlyNumbers}
        label="Mobile"
        inputProps={{
          minLength: 2,
          maxLength: 10,
          pattern: "[0-9]*",
          inputMode: "numeric",
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MdOutlinePhone className="fs-6" />
            </InputAdornment>
          ),
        }}
      />
      {/* <ShareFormInput {...register("email")} /> */}
    </ShareViaForm>
  );
}
