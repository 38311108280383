import { useDispatch, useSelector } from "react-redux";
import { getTermSumAssured } from "../../product-page/Filters/helper";
import {
  useGetCompareFiltersQuery,
  useGetProductsNameQuery,
} from "../../riders/services/rider";
import { addQuoteInList } from "../slices/NewCompare.slice";

const useNewCompare = () => {
  const {
    insuranceCompany,
    product,
    sumAssured,
    coverTillAge,
    payTillAge,
    frequency,
  } = useSelector(state => state?.newCompare);
  const dispatch = useDispatch();

  // Insurance Company List
  const {
    frontendData: { termcompanies = {} },
  } = useSelector(state => state?.frontendBoot);
  const termCompaniesList = Object.values(termcompanies)?.map(company => {
    const { short_name, id, alias } = company;
    return {
      code: alias,
      display_name: short_name,
      id,
    };
  });

  // Product Name List
  const { data: productData = {}, isLoading: isProductLoading } =
    useGetProductsNameQuery({ alias: insuranceCompany?.code });

  const productsList =
    productData?.data?.map(product => {
      const { name, id } = product;
      return {
        code: id,
        display_name: name,
      };
    }) || [];

  const { data: filtersData = {}, isLoading: isFilterLoading } =
    useGetCompareFiltersQuery({
      id: product?.code,
    });

  // Sum Assured List
  const { minimum_sum_assured: min, maximum_sum_assured: max } =
    filtersData?.data?.[0] || {};
  const sumAssuredList = getTermSumAssured(min, max);

  // Cover Till Age List
  const { options: coverTillAgeListOfObjects = {} } =
    filtersData?.data?.[1] || {};
  const coverTillAgeList =
    Object.values(coverTillAgeListOfObjects)?.map(e => e) || [];

  // Pay Till Age List
  const payTillAgeObject = coverTillAge?.ppt_options || {};
  const payTillAgeList = Object.values(payTillAgeObject)?.map(e => e) || [];

  // Frequency List
  const frequencyList = filtersData?.data?.[2]?.options || [];

  const isCompany = Object.keys(insuranceCompany)?.length ? true : false;
  const isProduct = Object.keys(product)?.length ? true : false;
  const isSumAssured = Object.keys(sumAssured)?.length ? true : false;
  const isCoverTillAge = Object.keys(coverTillAge)?.length ? true : false;
  const isPayTillAge = Object.keys(payTillAge)?.length ? true : false;
  const isFrequency = Object.keys(frequency)?.length ? true : false;

  // Validation
  const checkValues = () => {
    return (
      payTillAge?.code === 1
        ? isCompany &&
          isProduct &&
          isSumAssured &&
          isCoverTillAge &&
          isPayTillAge
        : isCompany &&
          isProduct &&
          isSumAssured &&
          isCoverTillAge &&
          isPayTillAge &&
          isFrequency
    )
      ? false
      : true;
  };

  // Add to List Handler
  const handleAddToList = () => {
    dispatch(
      addQuoteInList({
        companyAlias: insuranceCompany,
        product: product,
        termPayoutOption: "L",
        termSumAssured: sumAssured,
        termCoverupto: coverTillAge,
        termPayTill: payTillAge,
        termPayFrequency:
          payTillAge?.code === 1
            ? { code: "4", display_name: "Single Pay" }
            : frequency,
      }),
    );
  };

  return {
    termCompaniesList,
    productsList,
    sumAssuredList,
    coverTillAgeList,
    payTillAgeList,
    frequencyList,
    isFilterLoading,
    isProductLoading,
    checkValues,
    handleAddToList,
    isCompany,
    isProduct,
    isSumAssured,
    isCoverTillAge,
    isPayTillAge,
    isFrequency,
  };
};

export default useNewCompare;
