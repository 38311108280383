import React from "react";
import Navbar from "../../components/navbar/Navbar-component";
import { LandingWrapper } from "./Landing.style";
import "styled-components/macro";
import LandingMessage from "./components/LandingMessage/LandingMessage";
import LandingForm from "./components/LandingForm/LandingForm";
import * as mq from "../../styles/mediaQueries";
import { useSelector } from "react-redux";
const Landing = () => {
  const { termsettings } = useSelector(
    state => state.frontendBoot.frontendData,
  );

  return (
    <>
      <Navbar />
      <LandingWrapper background_image={termsettings?.background_image}>
        <div
          css={`
            display: flex;
            flex-direction: row;
            height: 100%;
            padding-top: 30px;
            width: 100%;
            ${mq.sm} {
              display: block;
              padding-top: 20px;
            }
            ${mq.md} {
              display: block;
              padding-top: 20px;
            }
          `}
        >
          <LandingMessage />
          <LandingForm />
        </div>
      </LandingWrapper>
    </>
  );
};

export default Landing;
