import { Link, useLocation } from "react-router-dom";
import styled from "styled-components/macro";

import * as mq from "../../../../styles/mediaQueries";
import FullScreenModal from "../../../../components/modal/FullScreenModal";
import { boxShadow } from "../../../../styles";
import { IoIosArrowBack } from "react-icons/io";
import { IoChevronDownOutline, IoChevronUpOutline } from "react-icons/io5";
import { Accordion, AccordionContext } from "react-bootstrap";
import { useContext, useEffect } from "react";
import * as colors from "../../../../styles/colors";
import { useDispatch, useSelector } from "react-redux";
import {
  changeFilter,
  selectFilterOptions,
  selectTermFilters,
} from "../../Filters/Filters.slice";
import { useState } from "react";
import { selectCompanies } from "../../../frontendBoot/frontendBoot.slice";
import { LiWrapper } from "../../components/SelectInsurer-component";
import { LoadEnquiry } from "../../../../components";
import useUrlQuery from "../../../../custom-hooks/useUrlQuery";
// import { useDefaultFilters } from "../../Filters/helper";
import { useHistory } from "react-router-dom";
import { selectSortBy, setSortBy, sortQuotes } from "../../Quotes/quotes.slice";

function FilterModal({ onHide, active, setActive, ...props }) {
  const location = useLocation();
  const {
    term_pay_till,
    term_coverupto: cover_upto,
    term_sum_assured,
    term_pay_frequency,
    selected_insurers,
  } = useSelector(selectTermFilters);

  const { termDefaultFilters } = useSelector(state => state.inputForm); // default values
  const selectedSortBy = useSelector(selectSortBy);
  const [payTillAge, setPayTillAge] = useState(term_pay_till);
  const [coverUpto, setCoverUpto] = useState(cover_upto);
  const [sumAssured, setSumAssured] = useState(term_sum_assured);
  const [payFrequency, setPayFrequency] = useState(term_pay_frequency);
  const [insuranceCompanies, setInsuranceCompanies] = useState(
    selected_insurers || [],
  );
  const [defaultValues, setDefaultValues] = useState({
    sumAssured: term_sum_assured,
    coverUpto: cover_upto,
    payTill: term_pay_till,
    payFrequency: term_pay_frequency,
  });

  useEffect(() => {
    termDefaultFilters?.forEach(item => {
      if (item?.group_name === "Sum Assured") {
        setDefaultValues(prev => ({
          ...prev,
          sumAssured: item?.default_value,
        }));
      }
      if (item?.group_name === "Pay Terms") {
        setDefaultValues(prev => ({
          ...prev,
          coverUpto: item?.default_value,
          payTill: item?.default_value,
        }));
      }
      if (item?.group_name === "Pay Frequency") {
        setDefaultValues(prev => ({
          ...prev,
          payFrequency: parseInt(item?.default_value),
        }));
      }
    });
  }, [termDefaultFilters]);

  useEffect(() => {
    if (
      sumAssured !== defaultValues?.sumAssured ||
      coverUpto !== defaultValues?.coverUpto ||
      payTillAge !== defaultValues?.payTill ||
      payFrequency !== defaultValues?.payFrequency ||
      insuranceCompanies?.length > 0
    )
      setActive(true);
    else setActive(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insuranceCompanies, sumAssured, coverUpto, payTillAge, payFrequency]);

  const handleReset = () => {
    if (location.pathname === "/compare") {
      setSumAssured(defaultValues.sumAssured);
      setCoverUpto(defaultValues.coverUpto);
      setPayTillAge(defaultValues.payTill);
    } else {
      setInsuranceCompanies([]);
    }
    setPayFrequency(parseInt(defaultValues?.payFrequency));
  };
  const handleChange = () => {
    onHide && onHide();
  };
  return (
    <LoadEnquiry>
      <FullScreenModal
        css={`
          font-size: min(calc(16 * 100vw / 360), 16px);
        `}
        {...props}
      >
        <Header handleReset={handleReset} />
        <Filters
          location={location}
          payTillAge={payTillAge}
          setPayTillAge={setPayTillAge}
          payFrequency={payFrequency}
          setPayFrequency={setPayFrequency}
          selectedSortBy={selectedSortBy}
          coverUpto={coverUpto}
          setCoverUpto={setCoverUpto}
          sumAssured={sumAssured}
          setSumAssured={setSumAssured}
          insuranceCompanies={insuranceCompanies}
          setInsuranceCompanies={setInsuranceCompanies}
          onChange={handleChange}
        />
      </FullScreenModal>
    </LoadEnquiry>
  );
}

function Filters({
  onChange,
  location,
  sumAssured,
  setSumAssured,
  coverUpto,
  setCoverUpto,
  payTillAge,
  setPayTillAge,
  payFrequency,
  selectedSortBy,
  setPayFrequency,
  insuranceCompanies,
  setInsuranceCompanies,
  ...props
}) {
  const dispatch = useDispatch();

  const handlePayTillAgeChange = value => {
    setPayTillAge(value);
  };

  const handleSumAssuredChange = value => {
    setSumAssured(value);
  };

  const handleCoverTillAgeChange = value => {
    setCoverUpto(value);
  };

  const handleInsuranceCompanyChange = changeEvent => {
    const {
      company: { alias },
      checked,
    } = changeEvent;

    if (!checked) {
      setInsuranceCompanies(insuranceCompanies =>
        insuranceCompanies.filter(company => company !== alias),
      );
      return;
    }
    setInsuranceCompanies(insuranceCompanies => [...insuranceCompanies, alias]);
  };

  const handlePayFrequencyChange = event => {
    const {
      frequency: { code },
      checked,
    } = event;
    if (checked) setPayFrequency(parseInt(code));
  };

  const handleSortValueChange = event => {};

  const enquiryId = useUrlQuery("enquiryId");
  const history = useHistory();
  const handleUpdateClick = () => {
    dispatch(
      changeFilter({
        selected_insurers: insuranceCompanies,
        term_sum_assured: sumAssured,
        term_pay_till: payTillAge,
        cover_upto: coverUpto,
        term_pay_frequency: payFrequency.toString(),
      }),
    ).then(() => {
      onChange && onChange();
      history.push(`?enquiryId=${enquiryId}`);
    });
  };

  return (
    <div {...props}>
      <FilterAccordion defaultActiveKey="insurance-company">
        {location.pathname === "/compare" ? (
          <>
            <SumAssuredAccordionItem
              onChange={handleSumAssuredChange}
              selected={sumAssured}
            />
            <CoverUptoAgeAccordionItem
              onChange={handleCoverTillAgeChange}
              selected={coverUpto}
            />
            <PayTillAgeAccordionItem
              onChange={handlePayTillAgeChange}
              selected={payTillAge}
              location={location}
            />
          </>
        ) : (
          <>
            <InsuranceCompanyAccordionItem
              onChange={handleInsuranceCompanyChange}
              selectedCompanies={insuranceCompanies}
            />
          </>
        )}
        <PayFrequencyAccordionItem
          onChange={handlePayFrequencyChange}
          selected={payFrequency}
        />
        <SortByAccordionItem
          onChange={handleSortValueChange}
          selected={selectedSortBy}
        />
      </FilterAccordion>
      <UpdateButton onClick={handleUpdateClick}>Update</UpdateButton>
    </div>
  );
}

const UpdateButton = styled.button`
  background-color: var(--primary-color);
  color: #fff;
  font-family: "Inter";
  font-weight: 700;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3.37em;
`;

function FilterAccordion({ children, defaultActiveKey, ...props }) {
  return (
    <div
      css={`
        padding: 1em;
        max-height: 76%;
        overflow: auto;
      `}
      {...props}
    >
      <Accordion defaultActiveKey={defaultActiveKey}>{children}</Accordion>
    </div>
  );
}

function SortByAccordionItem({ onChange, selected, ...props }) {
  const options = [
    {
      code: "Low To High",
      display_name: "Price Low To High",
    },
    {
      code: "High To Low",
      display_name: "Price High To Low ",
    },
    { code: "Claim Ratio", display_name: "Claim Ratio" },
  ];
  const dispatch = useDispatch();

  const handleClick = value => {
    dispatch(setSortBy(value));
    dispatch(sortQuotes());
  };

  return (
    <AccordionItem
      eventKey="sort-by"
      title="Sort By"
      selected={selected}
      {...props}
    >
      {options.map(({ code, display_name }) => (
        <StyledLiWrapper
          css={`
            ${mq.sm} {
              font-size: 10px;
            }
          `}
          key={code}
          name={display_name}
          value={code}
          id_for={code}
          checked={code === selected ? true : false}
          onClick={evt => handleClick(code)}
        />
      ))}
    </AccordionItem>
  );
}

function PayFrequencyAccordionItem({ onChange, selected, ...props }) {
  const { term_pay_frequency } = useSelector(selectFilterOptions);

  const handleClick = value => onChange && onChange(value);

  const selectedFrequency = term_pay_frequency.filter(
    item => parseInt(item.code) === selected,
  )[0].display_name;

  return (
    <>
      <AccordionItem
        eventKey="pay-frequency"
        title="Pay Frequency"
        selected={selectedFrequency}
        {...props}
      >
        {term_pay_frequency.map(({ code, display_name }) => (
          <StyledLiWrapper
            accordion
            key={code}
            name={display_name}
            value={code}
            id_for={code}
            checked={parseInt(code) === selected ? true : false}
            onClick={evt =>
              handleClick({
                frequency: { code, display_name },
                checked: evt.target.checked,
              })
            }
          />
        ))}
      </AccordionItem>
    </>
  );
}

function SumAssuredAccordionItem({ onChange, selected, ...props }) {
  const { term_sum_assured } = useSelector(selectFilterOptions);
  const handleClick = value => onChange && onChange(value);
  return (
    <AccordionItem
      eventKey="life-cover"
      title="Life Cover"
      selected={selected}
      {...props}
    >
      <div
        css={`
          display: flex;
          flex-wrap: wrap;
        `}
      >
        {term_sum_assured.map(({ code, display_name }) => (
          <OptionButton
            key={code}
            value={code}
            onClick={handleClick}
            isSelected={selected === code}
          >
            {display_name}
          </OptionButton>
        ))}
      </div>
    </AccordionItem>
  );
}
function PayTillAgeAccordionItem({ onChange, selected, location, ...props }) {
  const { term_pay_till } = useSelector(selectFilterOptions);

  const handleClick = value => onChange && onChange(value);

  return (
    <AccordionItem
      eventKey="pay-till-age"
      title="Pay till age"
      selected={selected === 1 ? "Single Pay" : selected + " Years"}
      {...props}
    >
      <div
        css={`
          display: flex;
          flex-wrap: wrap;
        `}
      >
        {term_pay_till.map(({ code, display_name }) => (
          <OptionButton
            key={code}
            value={code}
            onClick={handleClick}
            isSelected={selected === code}
          >
            {display_name}
          </OptionButton>
        ))}

        {location.pathname === "/compare" && (
          <OptionButton
            key={1}
            value={1}
            onClick={handleClick}
            isSelected={selected === 1}
          >
            Pay Once
          </OptionButton>
        )}
      </div>
    </AccordionItem>
  );
}

function CoverUptoAgeAccordionItem({ onChange, selected, ...props }) {
  const { term_coverupto: cover_upto } = useSelector(selectFilterOptions);

  const handleClick = value => onChange && onChange(value);

  return (
    <AccordionItem
      eventKey="cover-till-age"
      title="Cover till age"
      selected={selected + " Years"}
      {...props}
    >
      <div
        css={`
          display: flex;
          flex-wrap: wrap;
        `}
      >
        {cover_upto.map(({ code, display_name }) => (
          <OptionButton
            key={code}
            value={code}
            onClick={handleClick}
            isSelected={selected === code}
          >
            {display_name}
          </OptionButton>
        ))}
      </div>
    </AccordionItem>
  );
}

export function OptionButton({ value, onClick, children, ...props }) {
  const handleClick = () => {
    onClick && onClick(value);
  };

  return (
    <OptionButtonWrap {...props} onClick={handleClick}>
      {children}
    </OptionButtonWrap>
  );
}

const OptionButtonWrap = styled.button`
  border-radius: 0.37em;
  height: 3em;
  margin: 0.21em;
  width: ${props =>
    props.total === 2 ? "calc(50% - 0.67em)" : "calc(33% - 0.67em)"};
  background-color: ${props =>
    props.isSelected ? "var(--primary-color)" : "rgba(79, 92, 111, 0.1)"};
  font-size: 0.89em;
  color: ${props => (props.isSelected ? "#fff" : colors.font1)};
  font-family: "Inter";
  font-weight: 700;
`;

function getSelectedText(numberOfCompaniesSelected) {
  if (numberOfCompaniesSelected === 1) return "1 Company";
  if (numberOfCompaniesSelected > 1)
    return `${numberOfCompaniesSelected} Companies`;
  return "All Companies";
}

export const StyledLiWrapper = styled(LiWrapper)`
  & label {
    padding: 8px 0 !important;
    font-family: Inter;
    font-weight: 500;
  }
  & .form-check-label {
    max-width: calc(100% - 3em);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: ${props => (props.accordion ? "0.76em" : "1.1em")};
  }
  & .filters-width-divs {
    width: 0;
  }
  & img {
    display: none;
  }
`;

function InsuranceCompanyAccordionItem({
  onChange,
  selectedCompanies = [],
  ...props
}) {
  let companies = useSelector(selectCompanies);

  companies = Object.values(companies);

  const checkIsCompanySelected = alias =>
    selectedCompanies.some(company => company === alias);

  const handleClick = data => onChange && onChange(data);

  const numberOfCompaniesSelected = selectedCompanies.length;

  let selectedText = getSelectedText(numberOfCompaniesSelected);

  return (
    <AccordionItem
      eventKey="insurance-company"
      title="Insurance Company"
      selected={selectedText}
      {...props}
    >
      {companies.map(({ alias, name, logo }) => (
        <StyledLiWrapper
          accordion
          key={alias}
          name={name}
          value={alias}
          id_for={alias}
          logo={logo}
          checked={checkIsCompanySelected(alias)}
          onClick={evt =>
            handleClick({
              company: { name, alias },
              checked: evt.target.checked,
            })
          }
        />
      ))}
    </AccordionItem>
  );
}

function AccordionItem({ children, eventKey, title, selected, ...props }) {
  return (
    <div
      css={`
        margin: 1em 0;
      `}
      {...props}
    >
      <AccordionHead eventKey={eventKey} title={title} selected={selected} />
      <Accordion.Collapse eventKey={eventKey}>
        <div
          css={`
            padding: 1em 0;
            max-height: 21em;
            overflow: auto;
          `}
        >
          {children}
        </div>
      </Accordion.Collapse>
    </div>
  );
}

function AccordionHead({ eventKey, title, selected, ...props }) {
  const currentEventKey = useContext(AccordionContext);

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <StyledAccordionToggle eventKey={eventKey} {...props}>
      <AccordionHeaderWrap>
        <div
          css={`
            color: ${colors.font1};
          `}
        >
          {title}
        </div>
        <div
          css={`
            display: flex;
            align-items: center;
          `}
        >
          <div
            css={`
              margin-right: 1em;
              color: ${colors.primary};
              white-space: nowrap;
              max-width: 10em;
              overflow: hidden;
              text-overflow: ellipsis;
            `}
          >
            {selected}
          </div>
          <div
            css={`
              font-size: 1.37em;
            `}
          >
            {isCurrentEventKey ? (
              <IoChevronUpOutline />
            ) : (
              <IoChevronDownOutline />
            )}
          </div>
        </div>
      </AccordionHeaderWrap>
    </StyledAccordionToggle>
  );
}

const StyledAccordionToggle = styled(Accordion.Toggle)`
  width: 100%;
`;

const AccordionHeaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.97em;
  padding: 1em;
  border-radius: 0.66em;
  box-shadow: ${boxShadow};
  font-family: "Inter";
  font-weight: 700;
  font-size: 0.79em;
`;

function Header({ handleReset, ...props }) {
  const enquiryId = useUrlQuery("enquiryId");
  return (
    <div
      css={`
        box-shadow: ${boxShadow};
        height: 3.97em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 1em;
        font-family: "Inter";
        font-weight: 700;
        font-size: 0.9em;
      `}
      {...props}
    >
      <div
        css={`
          display: flex;
          align-items: center;
        `}
      >
        <StyledLink to={`?enquiryId=${enquiryId}`}>
          <IoIosArrowBack
            css={`
              font-size: 1.37em;
              margin-right: 0.37em;
            `}
          />
        </StyledLink>
        <h3
          css={`
            margin: 0;
            font-size: inherit;
            font-weight: 700;
            margin-top: 0.173em;
          `}
        >
          Filter plans by
        </h3>
      </div>
      <button
        css={`
          margin-top: 0.173em;
          font-weight: 700;
        `}
        onClick={handleReset}
      >
        Reset
      </button>
    </div>
  );
}

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

export default FilterModal;
