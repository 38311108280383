import styled from "styled-components";
import * as mq from "../../../../styles/mediaQueries";
export const Heading = styled.div`
  display: inline-block;
  font-size: clamp(26px, 2vw, 30px);
  font-family: Inter;
  font-weight: 600;
  margin-bottom: 12px;
  & span {
    color: var(--primary-color);
  }
  ${mq.sm} {
    font-size: 15px;
    font-weight: 600;
  }
`;
export const CompanyWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding: 20px 0;
  border-bottom: 1px solid rgba(100, 111, 128, 0.55);
  margin-bottom: 12px;
  ${mq.sm} {
    padding-top: 60px;
  }
`;
export const CompanyInfo = styled.div`
  display: flex;
  ${mq.sm} {
    flex-wrap: wrap;
    margin-bottom: 80px;
  }
`;
export const CompanyInfoCard = styled.div`
  width: 25%;
  padding: 16px 12px;
  border-left: 0.5px solid #ebe9e4;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  ${mq.sm} {
    width: 50%;
    border-radius: 10px;
    margin-bottom: 10px;
    height: 15em;
    box-shadow: ${props =>
      props.active === true ? "0 3px 15px 0 rgba(0, 0, 0, 0.16);" : "none"};
  }
  &:hover {
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    span {
      color: var(--primary-color);
    }
  }
`;
export const InfoName = styled.span`
  font-family: Inter;
  font-weight: 500;
  font-size: 18px;
  color: #000;
  margin-bottom: 40px;
  ${mq.sm} {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 20px;
    color: ${props => props.active === true && "var(--primary-color)"};
  }
`;
export const InfoValue = styled.div`
  font-family: Inter;
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 40px;
  font-weight: 600;
  ${mq.sm} {
    font-size: 20px;
    margin-bottom: 14px;
  }
`;
export const InfoDesc = styled.div`
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  ${mq.sm} {
    font-size: 11px;
    color: grey;
  }
`;
