import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { Checkbox } from "@material-ui/core";
import MuiTableHead from "@material-ui/core/TableHead";
import { images } from "../../../assets/images/logo/logo";

import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import styled from "styled-components/macro";

import { useDispatch, useSelector } from "react-redux";
import {
  selectQuotes,
  setSelectedQuotesToShare,
  setShareQuotesCanvas,
  selectDifferentQuotes,
} from "../Quotes/quotes.slice";
import {
  useGetQuoteQuery,
  useGetDifferentQuoteQuery,
  useGetCompareQuotePremiumQuery,
} from "../../riders/services/rider";
import { selectTermFilters } from "../Filters/Filters.slice";
import { getAnnualIncome } from "../../../components/navbar/helper";
import { usePlanDetails } from "../../riders/component/PlanCard/planCard.util";
import ShareLoader from "../../../components/CardSkeletonLoader/ShareQuoteLoader/ShareLoader";
import { useLocation } from "react-router-dom";
import { setSelectCompareQuoteToShare } from "../../NewCompare/slices/NewCompare.slice";
import ButtonLoader from "../../../components/CardSkeletonLoader/ButtonLoader/ButtonLoader";

const TableHead = withStyles(theme => ({}))(MuiTableHead);

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    border: "none",
  },
  tableHead: {
    borderRadius: "5px !important",
    backgroundColor: "rgb(237,238,240)",
    color: "#111",
    position: "sticky",
    top: 0,
    zIndex: 1000,
  },

  tableRow: {},
});

const QuotesTable = ({ allProductIds, allQuotesData }) => {
  const quotes = useSelector(selectQuotes);
  const diffQuotes = useSelector(selectDifferentQuotes);
  const { showDifferentQuotes } = useSelector(state => state.quotes);
  const { quotesList } = useSelector(state => state?.newCompare);
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [compareQuotesToShare, setCompareQuotesToShare] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const isNewCompare = location.pathname.includes("new-compare");

  useEffect(() => {
    if (selected) {
      console.log({ selected });
      const sendToBack = selected.map(product => product?.product?.id);
      dispatch(setSelectedQuotesToShare(sendToBack));
      dispatch(setShareQuotesCanvas(selected));
    } else {
      dispatch(setSelectedQuotesToShare([]));
      dispatch(setShareQuotesCanvas([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (compareQuotesToShare) {
      dispatch(setSelectCompareQuoteToShare(compareQuotesToShare));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareQuotesToShare]);

  const quotesListWithIds = quotesList?.map((quote, i) => {
    return {
      ...quote,
      id: i,
    };
  });

  return (
    <>
      {selected.length === 0 && (
        <div
          css={`
            color: red;
          `}
        >
          * Select atleast one quote to share
        </div>
      )}
      <TableContainer component={Paper} sx={{ maxHeight: "365px" }}>
        <Table
          stickyHeader
          className={classes.table}
          sx={{ borderSpacing: "0 5px", borderCollapse: " separate" }}
        >
          <TableHead
            style={{
              borderRadius: "5px !important",
              backgroundColor: "#607a8d",
              color: "#fff",
              position: "sticky",
              top: "0",
              zIndex: "1000",
            }}
            id="shareNav"
          >
            <TableRow sx={{ position: "sticky", top: "0" }}>
              <TableCell align="center">
                {isNewCompare ? (
                  <Checkbox
                    checked={
                      quotesList?.length === compareQuotesToShare?.length
                    }
                    onClick={e => {
                      if (e.target.checked) {
                        setCompareQuotesToShare(quotesListWithIds);
                      } else {
                        setCompareQuotesToShare([]);
                      }
                    }}
                  />
                ) : (
                  <Checkbox
                    checked={allProductIds.length === selected.length}
                    onClick={e => {
                      if (e.target.checked) {
                        setSelected(allProductIds);
                      } else {
                        setSelected([]);
                      }
                    }}
                  />
                )}

                {/* <Checkbox
                  checked={allProductIds.length === selected.length}
                  onClick={e => {
                    if (e.target.checked) {
                      setSelected(allQuotesData);
                    } else {
                      setSelected([]);
                    }
                  }}
                /> */}
              </TableCell>
              <TableCell align="center">Product Name</TableCell>
              <TableCell align="left">Life Cover</TableCell>
              <TableCell align="left">Cover Till Age</TableCell>
              <TableCell align="left">Pay Till Age</TableCell>
              <TableCell align="left">Premium</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isNewCompare ? (
              <>
                {quotesListWithIds?.map((quote, index) => {
                  return (
                    <NewCompareRow
                      quote={quote}
                      index={index}
                      compareQuotesToShare={compareQuotesToShare}
                      setCompareQuotesToShare={setCompareQuotesToShare}
                    />
                  );
                })}
              </>
            ) : (
              <>
                {Object.keys(quotes).map(company_alias => (
                  <Row
                    company_alias={company_alias}
                    selected={selected}
                    setSelected={setSelected}
                  />
                ))}
              </>
            )}

            {showDifferentQuotes && diffQuotes && (
              <TableRow>
                <TableCell
                  colSpan={6}
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    background: "rgb(237,238,240)",
                  }}
                >
                  Plans with slightly different criteria
                </TableCell>
              </TableRow>
            )}
            {Object.keys(quotes).map(company_alias => (
              <Row
                id={company_alias}
                company_alias={company_alias}
                selected={selected}
                setSelected={setSelected}
                isDifferent
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default QuotesTable;

const Row = ({ company_alias, selected, setSelected, isDifferent }) => {
  const { selected_insurers, ...termFilters } = useSelector(selectTermFilters);
  const {
    isLoading: isDifferentQuoteLoading,
    isFetching: isDifferentQuoteFetching,
    // isUninitialized,
    // isError,
    data: differentQuoteData,
  } = useGetDifferentQuoteQuery(
    {
      company_alias,
    },
    { skip: !isDifferent },
  );
  const {
    frontendData: { termcompanies },
  } = useSelector(state => state.frontendBoot);
  const { data, isLoading, isFetching } = useGetQuoteQuery(
    {
      company_alias,
      termFilters,

      ...(isDifferent ? { differentQuote: true } : {}),
    },
    { skip: isDifferent },
  );
  const { payFrequency, paymentOption } = usePlanDetails();

  const classes = useStyles();
  const quote = isDifferent
    ? differentQuoteData
      ? differentQuoteData.data[0]
      : null
    : data
    ? data.data[0]
    : null;
  // let name = quote?.product?.name.split("-")[0];
  const productName = quote?.product?.name.split("-")[0];
  const planName =
    quote?.product?.name.split("-").length === 2
      ? quote?.product?.name.split("-")[1]
      : quote?.product?.name.split("-")[1] +
        "-" +
        quote?.product?.name.split("-")[2];
  if (
    isLoading ||
    isFetching ||
    isDifferentQuoteLoading ||
    isDifferentQuoteFetching
  )
    return <ShareLoader />;

  if (!quote) return null;

  return (
    <TableRow
      // key={row.name}
      id={quote?.product?.id}
      className={classes.tableRow}
      sx={{}}
    >
      <TableCell align="right">
        <Checkbox
          value={quote?.product?.id}
          checked={
            selected &&
            selected?.some(item => item?.product?.id === quote?.product?.id)
          }
          onClick={e => {
            if (e.target.checked) {
              setSelected([...selected, quote]);
            } else {
              setSelected(
                selected.filter(
                  item => item?.product?.id !== quote?.product?.id,
                ),
              );
            }
          }}
        />
      </TableCell>
      <TableCell
        align="left"
        sx={{
          border: "1px solid #607a8d",
          borderRadius: "10px",
          display: "flex",
          gap: "10px",
          padding: "10px 16px",
          alignItems: "center",
        }}
      >
        <img
          css={`
            max-height: 3em;
            width: 5em;
          `}
          src={termcompanies[quote?.company_alias].logo}
          alt="company_logo"
        />
        <div
          css={`
            display: flex;
            flex-direction: column;
          `}
        >
          <CellValue
            css={`
              font-size: 14px;
            `}
          >
            {productName}
          </CellValue>

          <CellValue
            css={`
              font-size: 12px;
              font-weight: 400;
            `}
          >
            {planName}
          </CellValue>
          <CellDesc
            css={`
              color: #00a364;
            `}
          >
            Claims Ratio {quote?.product?.company?.csr}%
          </CellDesc>
        </div>
      </TableCell>

      <TableCell align="left">
        <CellValue> ₹ {getAnnualIncome(quote?.term_sum_assured)}</CellValue>
        <br />
        <CellDesc>
          Maximum ₹ {getAnnualIncome(quote?.max_cover_amount)}
        </CellDesc>
      </TableCell>
      <TableCell align="left">
        <CellValue> {quote?.term_coverupto} yrs</CellValue>
        <br /> <CellDesc>Maximum {quote?.max_cover_upto}&nbsp;yrs</CellDesc>
      </TableCell>
      <TableCell align="left">
        <CellValue>{quote?.term_pay_till} yrs</CellValue>

        <br />
        <CellDesc>
          {/* {paymentOption} */}
          {quote?.term_pay_till !== 1 &&
          !(quote?.term_pay_till < quote?.term_coverupto)
            ? "Regular Pay"
            : paymentOption}
        </CellDesc>
      </TableCell>

      <TableCell
        align="center"
        css={`
          background: rgba(0, 75, 131, 0.14);
          border-radius: 6px;
        `}
      >
        <span
          css={`
            color: #2e4d62;
            font-size: 20px;
            font-weight: bold;
          `}
        >
          ₹ {(quote?.premium).toLocaleString("en-IN")}
        </span>
        <br />
        {paymentOption === "Single Pay" ? "Pay Once" : payFrequency}
      </TableCell>
    </TableRow>
  );
};

const NewCompareRow = ({
  quote,
  compareQuotesToShare,
  setCompareQuotesToShare,
  index,
}) => {
  const classes = useStyles();
  const {
    companyAlias,
    product,
    termPayoutOption,
    termSumAssured,
    termCoverupto,
    termPayTill,
    termPayFrequency,
  } = quote;
  const { data, isLoading } = useGetCompareQuotePremiumQuery({
    company_alias: companyAlias?.code,
    product_id: product?.code,
    term_payout_option: termPayoutOption,
    term_sum_assured: termSumAssured?.code,
    term_coverupto: termCoverupto?.code,
    term_pay_till: termPayTill?.code,
    term_pay_frequency: termPayFrequency?.code,
  });

  return (
    <>
      <TableRow
        // key={row.name}
        className={classes.tableRow}
        sx={{}}
      >
        <TableCell align="right">
          <Checkbox
            value={quote?.id}
            checked={compareQuotesToShare
              .map(item => item?.id)
              .includes(quote?.id)}
            onClick={e => {
              if (e.target.checked) {
                setCompareQuotesToShare([...compareQuotesToShare, quote]);
              } else {
                setCompareQuotesToShare(
                  compareQuotesToShare.filter(item => item !== quote?.id),
                );
                // removeCompareQuote(index);
              }
            }}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{
            border: "1px solid #607a8d",
            borderRadius: "10px",
            display: "flex",
            // gap: "10px",
            //padding: "10px 16px",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <img
            css={`
              max-height: 3em;
              width: 5em;
            `}
            src={images?.[quote?.companyAlias?.code]}
            alt="company_logo"
          />
          <div
            css={`
              display: flex;
              flex-direction: column;
            `}
          >
            <CellValue
              css={`
                font-size: 14px;
              `}
            >
              {quote?.product?.display_name}
            </CellValue>

            <CellValue
              css={`
                font-size: 12px;
                font-weight: 400;
              `}
            ></CellValue>
            <CellDesc
              css={`
                color: #00a364;
              `}
            ></CellDesc>
          </div>
        </TableCell>

        <TableCell align="left">
          <CellValue>{quote?.termSumAssured?.display_name}</CellValue>
        </TableCell>
        <TableCell align="left">
          <CellValue> {quote?.termCoverupto?.display_name}</CellValue>
        </TableCell>
        <TableCell align="left">
          <CellValue>{quote?.termPayTill?.display_name}</CellValue>
        </TableCell>

        <TableCell
          align="center"
          css={`
            background: rgba(0, 75, 131, 0.14);
            border-radius: 6px;
          `}
        >
          {isLoading ? (
            <ButtonLoader colored={true} />
          ) : (
            <>
              <span
                css={`
                  color: #2e4d62;
                  font-size: 18px;
                  font-weight: bold;
                `}
              >
                ₹ {data?.data?.[0]?.total_premium}
                <br />
                {quote?.termPayFrequency?.display_name}
              </span>
            </>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

const CellValue = styled.span`
  font-weight: bold;
  font-size: 18px;
`;
const CellDesc = styled.span`
  font-size: 13px;
`;
