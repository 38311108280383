import styled from "styled-components";
import landing from "./../../assets/images/landing.png";
import * as mq from "../../styles/mediaQueries";
export const LandingWrapper = styled.div`
  ${"" /* background-image: url(${landing}); */}
  background-image: url(${props =>
    props.background_image ? props.background_image : landing});
  background-attachment: fixed;
  background-position: center center;
  max-height: fit-content;
  width: 100%;
  min-height: 100vh;
  ${"" /* background-size: contain ; */}
  background-size: cover;
  background-repeat: no-repeat;
  ${mq.sm} {
    min-height: 100%;
  }
`;
export const MessageContainer = styled.div`
  padding: 27px;
  margin: auto;
  color: rgb(0, 0, 0);
  background: rgba(237, 240, 244, 0.62);
  border-radius: 0px;
  max-width: 580px;

  & h3 {
    color: var(--primary-color);
    background-color: rgb(236, 246, 255);
    border: 1px solid var(--primary-color);
    border-radius: 15px;
    width: max-content;
    font-size: 24px;
    padding: 3px 9px;
    margin-bottom: 13px;
    ${mq.sm} {
      font-size: 12px;
    }
  }

  & h1 {
    font-weight: 400;
    font-size: 36px;
    margin-bottom: 24px;
    ${mq.sm} {
      font-size: 13px;
      font-weight: 700;
      margin-bottom: 10px;
    }
  }
  & .down {
    display: none;
    ${mq.xs} {
      display: inline;
      font-size: 9px;
    }
  }
  ${mq.md} {
      max-width: 90%;
    margin-bottom: 20px;
    box-shadow: rgb(0 0 0 / 20%) 0px 4px 8px 0px;
  }
  ${mq.sm} {
    padding: 10px;
    background: rgb(235, 239, 243);
    height: 100%;
    margin-bottom: 20px;
    box-shadow: rgb(0 0 0 / 20%) 0px 4px 8px 0px;
  }
  ${mq.xs} {
    padding: 10px;
    background: rgb(235, 239, 243);
    height: 100%;
    margin-bottom: 20px;
    box-shadow: rgb(0 0 0 / 20%) 0px 4px 8px 0px;
  }
`;
export const FormCard = styled.div`
  width: 499px;
  padding: 0px;
  margin: auto;
  color: rgb(0, 0, 0);
  background: rgb(255, 255, 255);
  border-radius: 0px;
  box-shadow: rgb(0 0 0 / 20%) 0px 4px 8px 0px,
    rgb(0 0 0 / 19%) 0px 6px 20px 0px;
  position: relative;
  ${mq.sm} {
    width: 90%;
  }
  ${mq.md} {
    width: 90%;
  }
`;
export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const RadioWrapper = styled.div`
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
  ${mq.md} {
    gap: 30px;
  }
  ${mq.sm} {
    gap: 15px;
  }
`;
export const Radio = styled.div`
  flex: 1 1;
  cursor: pointer;
  & input {
    display: none;
    &:checked + label {
      border: 2px solid var(--primary-color);
      color: var(--primary-color);
      font-weight: 600;
      & img {
        filter: grayscale(0%);
      }
    }
  }
  & label {
    cursor: pointer;
    width: 100%;
    height: 65px;
    border: 2px solid #ced4da;
    border-radius: 4px;
    font-size: 16px;
    display: flex;
    align-items: center;
    padding: 0 30px;
    & img {
      height: 39px;
      width: auto;
      filter: grayscale(100%);
      margin-right: 20px;
      transition: all 0.5s;
      ${mq.sm} {
        height: 25px;
        margin-right: 10px;
      }
    }
    & span {
      flex: 2 2;
    }
    transition: border 0.5s;
    ${mq.md} {
      width: 100%;
      padding: 0 10px;
      font-size: 14px;
    }
    ${mq.sm} {
      width: 100%;
      padding: 0 10px;
      font-size: 12px;
    }
  }
`;
export const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  position: relative;
`;
export const AgeWrapper = styled.div`
  position: absolute;
  border-radius: 4px;
  padding: 4px 8px;
  right: 20px;
  top: 18px;
  font-family: "Inter";
  font-weight: 700;
  font-size: 14px;
  background-color: transparent;
  color: rgb(43 71 139);
`;
export const Button = styled.button`
  width: 100%;
  background: var(--primary-color);
  padding: 20px 0;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  font-family: Inter;
  ${mq.md} {
    width: 100%;
    font-size: 14px;
  }
  ${mq.sm} {
    width: 100%;
    font-size: 15px;
  }
`;

export const DynamicWrap = styled.div`
  width: 100%;
  ul {
    list-style-type: none;
    display: auto;
    padding-left: 0px;
    ${mq.md} {
      display: auto;
      width: 100%;
    }
    ${mq.sm} {
      display: auto;
      margin-bottom: 0;
    }
    ${mq.xs} {
      display: ${props => (props.show ? "block" : "none")};
      width: 100%;
      transition: all 0.2s ease;
      margin-left: -14px;
    }
  }
  li {
    font-size: 16px;
    margin: 0px 0px;
    ${mq.sm} {
      font-size: 10px;
      margin: 0px;
      padding: 0 5px;
    }
  }
  li::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f058";
    margin-right: 10px;
    color: var(--primary-color);
    display: inline;
    ${mq.md} {
      margin-right: 8px;
    }
    ${mq.sm} {
      margin-right: 6px;
    }
  }
`;
