import { createSlice } from "@reduxjs/toolkit";

const compareSlice = createSlice({
  name: "compare",
  initialState: {
    ridersAdded: {},
    showDifference: false,
    addedShortNames: { data: [], lastFetched: {} },
    shortNameToRemove: "",
    compareFeaturesData: {},
    removedCompareProducts: [],
    showSelected: false,
    selectedFeatures: [],
    childCount: {
      "Selected Details": false,
      "Basic Features": false,
      "Plan Type": false,
      "Premium Payment Options": false,
      "Plan Benefits": false,
      "Insurer Details": false,
    },
    isDownloading: false,
  },
  reducers: {
    setShowDifference: (state, action) => {
      state.showDifference = action.payload;
    },
    setSelectedFeatures: (state, { payload }) => {
      state.selectedFeatures = payload;
    },
    addRider: (state, action) => {
      const { product_id, riderToAdd } = action.payload;
      if (state.ridersAdded[product_id]) {
        state.ridersAdded[product_id] = [
          ...state.ridersAdded[product_id],
          riderToAdd,
        ];
        return;
      }

      state.ridersAdded[product_id] = [riderToAdd];
    },
    removeRider: (state, action) => {
      const { product_id, riderIdToRemove } = action.payload;

      if (state.ridersAdded[product_id])
        state.ridersAdded[product_id] = state.ridersAdded[product_id].filter(
          rider => rider.rider_id !== riderIdToRemove,
        );
    },
    updateCompareRiderShortName: (state, { payload }) => {
      state.addedShortNames.data = payload.data;
      state.addedShortNames.lastFetched = payload.productId;
    },
    removeCompareShortName: (state, { payload }) => {
      state.shortNameToRemove = payload;
      state.addedShortNames.data = state.addedShortNames.data.filter(
        item => item !== payload,
      );
    },
    addCompareFeaturesData: (state, action) => {
      const { product_id, data } = action.payload;
      state.compareFeaturesData[product_id] = data;
    },
    removeCompareFaturesData: (state, action) => {
      const { product_id } = action.payload;
      state.compareFeaturesData[product_id] = undefined;
    },
    removedCompareProductData: (state, { payload }) => {
      state.removedCompareProducts = payload;
    },

    hasChildCount: (state, action) => {
      state.childCount[action.payload] = true;
    },
    clearCompareData: state => {
      state.ridersAdded = {};
      state.compareFeaturesData = {};
      state.showDifference = false;
    },
    setShowSelected: (state, { payload }) => {
      state.showSelected = payload;
    },
    resetChildCount: state => {
      state.childCount = {
        "Selected Details": false,
        "Basic Features": false,
        "Plan Type": false,
        "Premium Payment Options": false,
        "Plan Benefits": false,
        "Insurer Details": false,
      };
    },

    initDownloading: state => {
      state.isDownloading = true;
    },
    clearDownloading: state => {
      state.isDownloading = false;
    },
  },
});

export const {
  addRider,
  removeRider,
  setShowDifference,
  addCompareFeaturesData,
  updateCompareRiderShortName,
  removeCompareShortName,
  removeCompareFaturesData,
  removedCompareProductData,
  hasChildCount,
  resetChildCount,
  clearCompareData,
  setShowSelected,
  setSelectedFeatures,
  initDownloading,
  clearDownloading,
} = compareSlice.actions;

export default compareSlice.reducer;

export const selectRidersAdded = product_id => state =>
  state.quotes.addedRiders[product_id] || [];

export const selectShowDifference = state => state.compare.showDifference;

export const selectCompareFeatureesData = state =>
  state.compare.compareFeaturesData;

export const selectRemovedData = state =>
  state.compare.removedCompareProductData;
