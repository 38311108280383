import React from "react";
import "styled-components/macro";
import { yupResolver } from "@hookform/resolvers/yup";
import FormWithTitle from "../../FormWithTitle/FormWithTitle";
import * as mq from "../../../../../styles/mediaQueries";
import styled from "styled-components";
import { useForm } from "react-hook-form";

import Select from "../../Select/Select";
import {
  financialAndFamilyGoal,
  lifeStage,
  riskProfile,
} from "../../QuestionOption";
import { useDispatch, useSelector } from "react-redux";
import { setData } from "../../../input.slice";
import { form3 } from "../../../validations";

function Question({ setCurrent }) {
  const dispatch = useDispatch();
  const { terminputpage } = useSelector(
    state => state.frontendBoot.frontendData,
  );

  const getLocalData = () => {
    const field_2 = localStorage.getItem("Term Question Details");
    if (field_2) return JSON.parse(field_2);
    else
      return {
        risk_profile: "",
        life_stage: "",
        financial_and_familyGoal: "",
      };
  };
  const {
    // register,
    // getValues,
    trigger,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(form3),
    mode: "onChange",
    defaultValues: getLocalData(),
  });

  const [risk, setRisk] = React.useState(getLocalData().risk_profile);
  const [life, setLife] = React.useState(getLocalData().life_stage);
  const [financial, setFinancial] = React.useState(
    getLocalData().financial_and_familyGoal,
  );

  const onSubmit = data => {
    setCurrent(prev => prev + 1);
    dispatch(setData({ suitability: { ...data } }));
    localStorage.setItem(
      "Term Question Details",
      JSON.stringify({
        risk_profile: data.risk_profile,
        life_stage: data.life_stage,
        financial_and_familyGoal: data.financial_and_familyGoal,
      }),
    );
  };

  // const [selectedValue, setSelectedValue] = React.useState({
  //   risk_profile: "",
  //   life_stage: "",
  //   financial_and_familyGoal: "",
  // });

  return (
    <div>
      <FormWithTitle
        onSubmit={handleSubmit(onSubmit)}
        current={2}
        title={
          terminputpage?.terminputpage2
            ? terminputpage?.terminputpage2
            : "Some More Questions"
        }

        // title={"Some More Questions"}
      >
        <ProffessionForm>
          <ProfessionInputWrapper>
            <div className="w-100 ">
              <Select
                label="Risk Profile*"
                options={riskProfile}
                error={errors.risk_profile && errors.risk_profile.message}
                handleChange={val => {
                  setRisk(val);
                  // setSelectedValue((prev) => ({ ...prev, risk_profile: item }));
                  setValue("risk_profile", val);
                  trigger("risk_profile");
                }}
                noSearch
                selectedValue={
                  risk
                  // selectedValue.risk_profile.length > 0
                  //   ? selectedValue.risk_profile
                  //   : riskProfile[0].code
                }
              />
            </div>

            <div className="w-100 mt-4">
              <Select
                label="Life Stage*"
                options={lifeStage}
                error={errors.life_stage && errors.life_stage.message}
                handleChange={val => {
                  setLife(val);
                  // setSelectedValue((prev) => ({ ...prev, life_stage: item }));
                  setValue("life_stage", val);
                  trigger("life_stage");
                }}
                noSearch
                selectedValue={
                  life
                  // selectedValue.life_stage.length > 0
                  //   ? selectedValue.life_stage
                  //   : lifeStage[0].code
                }
              />
            </div>

            <div className="w-100 mt-4">
              <Select
                label="Financial and Family Goal*"
                options={financialAndFamilyGoal}
                error={
                  errors.financial_and_familyGoal &&
                  errors.financial_and_familyGoal.message
                }
                noSearch
                handleChange={val => {
                  setFinancial(val);
                  // setSelectedValue((prev) => ({
                  //   ...prev,
                  //   financial_and_familyGoal: item,
                  // }));
                  setValue("financial_and_familyGoal", val);
                  trigger("financial_and_familyGoal");
                }}
                selectedValue={
                  financial
                  // selectedValue.financial_and_familyGoal.length > 0
                  //   ? selectedValue.financial_and_familyGoal
                  //   : financialAndFamilyGoal[0].code
                }
              />
            </div>
          </ProfessionInputWrapper>

          <div
            css={`
              display: flex;
              gap: 30px;
              width: 100%;
              margin: auto;
              ${mq.sm} {
                width: 100%;
                gap: 1px;
              }
            `}
          >
            <Button
              css={`
                flex: 1 1;
                background-color: rgba(220, 220, 220, 0.5);
                color: var(--secondary-text);
                font-weight: 900;
                height: 50px;
                padding-top: 10px;
                width: 5rem;
                ${mq.sm} {
                  padding-top: 16px;
                }
              `}
              onClick={() => {
                setCurrent(prev => prev - 1);
              }}
            >
              Back
            </Button>
            <Button
              style={{
                display: "flex",
                flex: "1 1",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "900",
                height: "50px",
              }}
              type="submit"
            >
              Continue{" "}
              {/* <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                marginLeft: "10px",
              }}
            >
              <path
                fill="none"
                stroke-linecap="square"
                stroke-miterlimit="10"
                stroke-width="48"
                d="M268 112l144 144-144 144m124-144H100"
              ></path>
            </svg> */}
            </Button>
          </div>
        </ProffessionForm>
      </FormWithTitle>
    </div>
  );
}

export default Question;

const ProffessionForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${"" /* margin-bottom: 20px; */}
  ${mq.sm} {
    width: 100%;
  }
`;

// const ProffessionTitle = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-start;
//   align-items: center;
//   width: 100%;
//   height: 3.5em;

//   ${mq.sm} {
//     width: 100%;
//   }
// `;

const ProfessionInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;

  ${mq.sm} {
    width: 100%;
  }
`;

// const ProffessionButton = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   font-size: 1.5em;

//   ${mq.sm} {
//     width: 100%;
//   }
// `;

export const Button = styled.button`
  width: 100%;
  background: var(--primary-color);
  padding: 20px 0;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  font-family: Inter;
  ${mq.md} {
    width: 100%;
    font-size: 14px;
  }
  ${mq.sm} {
    width: 100%;
    font-size: 14px;
  }
`;
