import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import {
  selectDifferentQuotes,
  selectQuotes,
  setSelectedQuotesToShare,
} from "../Quotes/quotes.slice";
import { Checkbox } from "@material-ui/core";
import { selectTermFilters } from "../Filters/Filters.slice";
import {
  useGetDifferentQuoteQuery,
  useGetQuoteQuery,
} from "../../riders/services/rider";
import { usePlanDetails } from "../../riders/component/PlanCard/planCard.util";
import { getAnnualIncome } from "../../../components/navbar/helper";
import { ShareLoaderMobile } from "../../../components/CardSkeletonLoader/ShareQuoteLoader/ShareLoader";

const ShareQuotesMobile = ({ allProductIds, selected, setSelected }) => {
  const quotes = useSelector(selectQuotes);
  const dispatch = useDispatch();
  const diffQuotes = useSelector(selectDifferentQuotes);
  const { showDifferentQuotes } = useSelector(state => state.quotes);
  React.useEffect(() => {
    if (selected) {
      dispatch(setSelectedQuotesToShare(selected));
    }
    // if (selected.length === 0) {
    //   dispatch(setSelectedQuotesToShare(allProductIds));
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);
  return (
    <>
      {Object.keys(quotes).map(company_alias => (
        <Card
          company_alias={company_alias}
          selected={selected}
          setSelected={setSelected}
        />
      ))}
      {showDifferentQuotes && diffQuotes && (
        <div
          css={`
            text-align: center;
            padding: 12px;
            font-size: 13px;
            margin: 12px;
            background: rgb(237, 238, 240);
          `}
        >
          Plans with slightly different criteria
        </div>
      )}
      {Object.keys(quotes).map(company_alias => (
        <Card
          company_alias={company_alias}
          selected={selected}
          setSelected={setSelected}
          different
        />
      ))}
    </>
  );
};
export default ShareQuotesMobile;

const Card = ({ company_alias, selected, setSelected, different }) => {
  const { selected_insurers, ...termFilters } = useSelector(selectTermFilters);
  const { payFrequency, paymentOption } = usePlanDetails();
  const {
    frontendData: { termcompanies },
  } = useSelector(state => state.frontendBoot);

  const {
    isLoading: isDifferentQuoteLoading,
    isFetching: isDifferentQuoteFetching,
    // isUninitialized,
    // isError,
    data: differentQuoteData,
  } = useGetDifferentQuoteQuery(
    {
      company_alias,
    },
    { skip: !different },
  );
  const { data, isLoading, isFetching } = useGetQuoteQuery(
    {
      company_alias,
      termFilters,
      ...(different ? { differentQuote: true } : {}),
    },
    { skip: different },
  );

  const quote = different
    ? differentQuoteData
      ? differentQuoteData.data[0]
      : null
    : data
    ? data.data[0]
    : null;
  let name = quote?.product?.name.split("-")[0];
  if (
    isLoading ||
    isFetching ||
    isDifferentQuoteFetching ||
    isDifferentQuoteLoading
  )
    return <ShareLoaderMobile />;
  if (!quote) return null;

  return (
    <CardWrapper id={quote?.product?.id}>
      <Upper>
        <img
          css={``}
          className="company_logo"
          src={termcompanies[quote?.company_alias].logo}
          alt="company_logo"
        />
        <span
          css={`
            text-align: left;
          `}
        >
          <span
            css={`
              font-weight: 600;
              font-size: 0.8em;
            `}
          >
            {name}
          </span>
          <br />
          <span
            css={`
              font-size: 0.8em;
              color: #00a364;
            `}
          >
            Claims Ratio {quote?.product?.company?.csr}%
          </span>
        </span>
        <Checkbox
          className="checkbox"
          value={quote?.product?.id}
          checked={selected && selected.includes(quote?.product?.id)}
          onClick={e => {
            if (e.target.checked) {
              setSelected([...selected, quote?.product?.id]);
            } else {
              setSelected(selected.filter(item => item !== quote?.product?.id));
            }
          }}
        />
      </Upper>
      <HR />

      <Lower>
        <Section>
          <span className="section_name">Life Cover</span>
          <span className="section_value">
            ₹ {getAnnualIncome(quote?.term_sum_assured)}
          </span>
          <span className="section_desc">
            {" "}
            Maximum ₹ {getAnnualIncome(quote?.max_cover_amount)}
          </span>
        </Section>
        <Section>
          <span className="section_name">Cover Till Age</span>
          <span className="section_value">{quote?.term_coverupto} yrs</span>
          <span className="section_desc">
            Maximum {quote?.max_cover_upto}&nbsp;yrs
          </span>
        </Section>
        <Section>
          <span className="section_name">Pay Till Age</span>
          <span className="section_value">{quote?.term_pay_till} yrs</span>
          <span className="section_desc">{paymentOption}</span>
        </Section>
        <Premium>
          {" "}
          ₹ {(quote?.premium).toLocaleString("en-IN")}
          <br />
          <span>{payFrequency}</span>
        </Premium>
      </Lower>
    </CardWrapper>
  );
};

const CardWrapper = styled.div`
  padding: 1.5em 1em;
  box-shadow: 0 5px 15px 0 rgb(0 0 0 / 16%);

  margin-bottom: 0 10px 13px 10px;
`;
const Upper = styled.div`
  display: flex;
  gap: 1em;
  justify-content: flex-start;
  align-items: center;
  & .checkbox {
    margin-left: auto;
  }
  & .company_logo {
    max-width: 4em;
    height: 2em;
  }
`;
const HR = styled.div`
  background: #e6e6e6;
  margin: 4px 0;
  height: 0.5px;
`;
const Lower = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Premium = styled.div`
  border-radius: 7px;
  background-color: var(--secondary-color);
  border: none;
  color: #fff;
  padding: 6px 10px;
  text-align: center;
  font-family: "Inter";
  font-weight: 500;
  span {
    font-size: 8px;
  }
`;
const Section = styled.div`
  display: flex;
  flex-direction: column;
  & .section_name {
    font-size: 8.5px;
  }
  & .section_value {
    font-weight: 600;
  }
  & .section_desc {
    font-size: 8.5px;
    // font-weight:bold;
  }
`;
