import React, { useEffect, useState } from "react";
import { Checkbox } from "@material-ui/core";
import "styled-components/macro";
import whatsapp from "../../../../assets/images/whatsapp-icon.svg";
import * as mq from "../../../../styles/mediaQueries";
import { useDispatch } from "react-redux";
import { setData } from "../../input.slice";

const WhatsappUpdate = () => {
  const [checked, setChecked] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setData({ whatsapp_flag: checked ? "Yes" : "No" }));
  }, [checked, dispatch]);
  return (
    <>
      <div
        css={`
          display: flex;
          justify-content: flex-start;
          width: auto;
          margin-left: -10px;
        `}
      >
        <Checkbox
          size="small"
          defaultChecked={checked}
          onChange={() => {
            setChecked(!checked);
          }}
        />

        <h6
          css={`
            margin-top: 11px;
            ${mq.sm} {
              // margin-bottom: 0px;
            }
          `}
        >
          Get Updates on{" "}
          <img
            src={whatsapp}
            alt="Whatsapp-icon"
            css={`
              height: 40px;
              width: 20px;
              margin-bottom: 2px;
              ${mq.sm} {
                height: 35px;
                width: 18px;
                margin-bottom: 4px;
              }
            `}
          />{" "}
          Whatsapp
        </h6>
      </div>
    </>
  );
};

export default WhatsappUpdate;
