import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import logo from "../../assets/images/logo/logo.png";
import styled from "styled-components";
import "styled-components/macro";
import ShareQuoteBtn from "./ShareQuoteBtn";
import TalkToUsBtn from "./TalkToUsBtn-component";
import EditDetailPanel from "./EditDetailPanel-component";
import { useDispatch, useSelector } from "react-redux";
import { getAge, getAnnualIncome, getGender, tobaccoHabit } from "./helper";
import { useLocation } from "react-router";
import "./navbar-stylesheet.scss";
import { TraceId } from "..";
import * as mq from "../../styles/mediaQueries";
import useMediaQuery from "../../custom-hooks/useMediaQuery";
import { Avatar, Box, Typography } from "@mui/material";
import { clearQuotes } from "../../pages/product-page/components/comparePlanPopup.slice";
import { useGetSellerInfoQuery } from "../../pages/riders/services/rider";
import useUrlQuery from "../../custom-hooks/useUrlQuery";
import HtmlTooltip from "../Tooltip/HtmlTooltip";

export function Logo(props) {
  const {
    // isLoading,
    frontendData,
  } = useSelector(state => state.frontendBoot);
  return (
    <>
      <LogoWrapper className="logo" {...props}>
        <a href="/">
          <img
            src={frontendData?.termsettings?.logo || logo}
            alt=""
            style={{
              width: "80%",
              height: "auto",
            }}
          />
        </a>
      </LogoWrapper>
    </>
  );
}

const Navbar = () => {
  const [showEditDetailPanel, setShowEditDetailPanel] = useState(false);
  const { data } = useSelector(state => state.inputForm);
  const location = useLocation();
  const isNewCompare = location.pathname.includes("new-compare");
  const isMobile = useMediaQuery(`${mq.smForHook}`);
  const isMobileOrTablet = useMediaQuery(`${mq.mdForHook}`);
  const history = useHistory();
  const [isHover, setIsHover] = useState(false);

  const dispatch = useDispatch();
  const token = useUrlQuery("token");
  const { data: sellerInfo } = useGetSellerInfoQuery(
    {
      token: token,
    },
    {
      skip: !token,
    },
  );
  useEffect(() => {
    if (
      data?.payment_status === "success" &&
      ["/quote", "/riders", "/compare"].includes(location.pathname)
    ) {
      history.push({
        pathname: "/thankyou",
        search: window.location.search,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const { seller_id, seller_type, seller_name } =
    useSelector(
      state => state?.inputForm?.data?.params?.other_req_data?.data,
    ) || {};
  const sellerData = sellerInfo || { seller_id, seller_type, seller_name };

  return (
    <>
      <div className="navbar border-bottom p-7 flex-nowrap">
        <div className="container">
          <Logo />
          <div
            css={`
              display: flex;
              align-items: center;
            `}
          >
            {(location.pathname === "/quote" ||
              location.pathname === "/new-compare" ||
              location.pathname === "/shortlisted-quote") && (
              <NavLinksWrapper>
                <ul
                  style={{
                    margin: "auto",
                    textTransform: "capitalize",
                    fontFamily: "Inter",
                    fontWeight: "500",
                    whiteSpace: "nowrap",
                  }}
                >
                  <li>{getGender(data.gender)}</li>
                  <li>{getAge(data.dob)}</li>
                  {!isMobileOrTablet && (
                    <>
                      <li>{tobaccoHabit(data.tobacco_habit)}</li>
                      <li>{data.occupation?.split("_").join(" ")}</li>
                      <li>{data.education?.split("_").join(" ")}</li>
                      <li>₹ {getAnnualIncome(data.term_annual_income)}</li>
                    </>
                  )}

                  {location.pathname !== "/shortlisted-quote" && (
                    <li
                      style={{
                        color: "var(--primary-color)",
                        fontFamily: "Inter",
                        fontWeight: "500",
                      }}
                      onClick={() => {
                        dispatch(clearQuotes());
                        setShowEditDetailPanel(!showEditDetailPanel);
                      }}
                    >
                      {isNewCompare ? "View" : "Edit "}
                    </li>
                  )}
                </ul>
              </NavLinksWrapper>
            )}

            <div>
              <NavBtnWrapper>
                {!isMobile &&
                  (location.pathname === "/quote" ||
                    location.pathname === "/new-compare" ||
                    location.pathname === "/shortlisted-quote") && (
                    <>
                      <ShareQuoteBtn />
                      <TalkToUsBtn />
                    </>
                  )}
                {(isMobile && location.pathname === "/") ||
                  ((location.pathname === "/insurance-information" ||
                    location.pathname === "/riders-information") && (
                    <>
                      <TalkToUsBtn />
                    </>
                  ))}
                {location.pathname === "/document_upload" ||
                location.pathname === "/riders" ||
                location.pathname === "/success" ||
                location.pathname === "/proposal" ? (
                  <TraceId />
                ) : (
                  <></>
                )}

                <Box
                  display={"flex"}
                  gap={"6px"}
                  marginLeft={isMobile ? "0px" : "10px"}
                >
                  {sellerData?.seller_name &&
                    (isMobileOrTablet ? (
                      <HtmlTooltip
                        title={
                          <>
                            <Box padding="5px">
                              <Typography
                                color="inherit"
                                fontWeight="600"
                                textTransform={"capitalize"}
                              >
                                {sellerData?.seller_name}
                              </Typography>
                              <Typography fontWeight="600">
                                {sellerData?.seller_type} :{" "}
                                {sellerData?.seller_id}
                              </Typography>
                            </Box>
                          </>
                        }
                      >
                        <Avatar
                          sx={{
                            background: "var(--primary-color)",
                            position: "relative",
                          }}
                          onClick={() =>
                            isMobileOrTablet && setIsHover(!isHover)
                          }
                        >
                          {sellerData?.seller_name &&
                            sellerData?.seller_name[0]?.toUpperCase()}
                        </Avatar>
                      </HtmlTooltip>
                    ) : (
                      <Avatar
                        sx={{
                          background: "var(--primary-color)",
                          position: "relative",
                        }}
                        onClick={() => isMobileOrTablet && setIsHover(!isHover)}
                      >
                        {sellerData?.seller_name &&
                          sellerData?.seller_name[0]?.toUpperCase()}
                      </Avatar>
                    ))}
                  {isMobileOrTablet ? (
                    <></>
                  ) : (
                    <Box>
                      <div
                        css={`
                          font-weight: 600;
                          font-size: 14px;
                          white-space: nowrap;
                          text-transform: capitalize;
                        `}
                      >
                        {sellerData?.seller_name}
                      </div>
                      <div>
                        {sellerData?.seller_type}{" "}
                        {sellerData?.seller_type &&
                          sellerData?.seller_id &&
                          ":"}{" "}
                        {sellerData?.seller_id}
                      </div>
                    </Box>
                  )}
                </Box>
              </NavBtnWrapper>
            </div>
          </div>
        </div>
      </div>
      <EditDetailPanel
        showEditDetailPanel={showEditDetailPanel}
        setShowEditDetailPanel={setShowEditDetailPanel}
        className={showEditDetailPanel ? "d-block" : "d-none"}
      />
    </>
  );
};

const LogoWrapper = styled.div`
  width: 160px;
  height: auto;
`;

const NavLinksWrapper = styled.div`
  padding-right: 10px;
  ${mq.sm} {
    padding: 0;
  }
  & ul {
    display: flex;
    list-style: none;
    justify-content: center;
    align-items: center;
    ${mq.sm} {
      padding: 0;
    }
    & li {
      font-size: 12px;
      color: #555;
      border-radius: none !important;
      padding: 0px 5px;
      &:last-child {
        cursor: pointer;
      }
    }
    & li:not(:last-child) {
      border-right: 2px solid #ccc;
    }
    ${mq.sm} {
      ${
        "" /* & li:nth-last-child(-n + 5) {
        display: none;
      } */
      }
      & li:last-child {
        display: inline;
      }
    }
  }
`;

const NavBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & button {
    min-width: 136px;
    font-weight: bold;
    border-radius: 6px;
    font-size: 14px;
  }
`;

export default Navbar;
