import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useEffect, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useDispatch } from "react-redux";
import styled from "styled-components";

const CompareFilterDropdown = ({
  options,
  onChangeHandler = () => {},
  isReset = false,
  isDisable = false,
  isFullWidthOnMobile = false,
  previousSelectedValue = "",
}) => {
  const dispatch = useDispatch();
  if (!isReset) {
    options = [
      isDisable
        ? { code: "4", display_name: "Single Pay" }
        : { code: "select_option", display_name: "Select Option" },
      ...options,
    ];
  }
  const [selectedOption, setSelectedOption] = useState(
    previousSelectedValue ? previousSelectedValue : options?.[0]?.code,
  );

  useEffect(() => {
    !isReset && setSelectedOption(options?.[0]?.code);
  }, [options, isReset]);

  const handleChange = e => {
    const data = options?.filter(
      option => e.target.value === option?.code,
    )?.[0];
    setSelectedOption(e.target.value);
    dispatch(onChangeHandler(data));
  };
  const menuItemStyles = {
    maxHeight: 180,
    "::-webkit-scrollbar": {
      width: "5px",
    },
    "::-webkit-scrollbar-thumb": {
      display: "block",
      outline: "none",
      backgroundColor: "#dfdfdf",
    },
    "@media (max-width:575px)": {
      width: isFullWidthOnMobile ? "100%" : "normal",
    },
  };

  return (
    <FormControl
      disabled={isDisable}
      sx={{
        m: 1,
        width: "100%",
        margin: 0,
        padding: "3px 0",
        lineHeight: "18px",
        height: "30px",
        borderRadius: "4px",
        "@media (max-width:768px)": {
          fontSize: ".6rem",
        },
      }}
    >
      <SelectStyled
        onChange={handleChange}
        variant="standard"
        disableUnderline
        value={isReset ? selectedOption : options?.[0]?.code}
        IconComponent={ExpandMoreIcon}
        MenuProps={{
          PaperProps: {
            sx: { ...menuItemStyles },
          },
        }}
      >
        {options?.map(option => (
          <MenuItemStyled key={option?.code} value={option?.code}>
            {option?.display_name}
          </MenuItemStyled>
        ))}
      </SelectStyled>
    </FormControl>
  );
};

export default CompareFilterDropdown;

const SelectStyled = styled(Select)`
  font-size: 0.9rem !important;
  font-weight: bold !important;
  :focus {
    background-color: none !important;
  }
  text-overflow: ellipsis !important;
`;
const MenuItemStyled = styled(MenuItem)`
  font-size: 0.9rem !important;
  font-weight: bold !important;
  width: 100% !important;
  white-space: pre-wrap !important;
`;
