import React, { useState } from "react";
import "styled-components/macro";
import { FormCard, FormWrapper } from "../../Landing.style";
import Eligibility from "./forms/Eligibility";
import Personal from "./forms/Personal";
import Premium from "./forms/Premium";
import * as mq from "../../../../styles/mediaQueries";
import WhatsappUpdate from "./WhatsappUpdate";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import Question from "./forms/Question";
import { Checkbox } from "@mui/material";
import { setData } from "../../input.slice";

const LandingForm = () => {
  const { terminputpage } = useSelector(
    state => state.frontendBoot?.frontendData,
  );
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(1);
  const isEnquiry = window.location.href.includes("enquiryId");
  return (
    <>
      <div
        css={`
          width: 50%;
          ${mq.sm} {
            display: block;
            width: 100%;
          }
          ${mq.md} {
            display: block;
            width: 100%;
            margin: 0 auto;
          }
        `}
      >
        <FormCard>
          <FormWrapper>
            {current === 1 && <Premium setCurrent={setCurrent} />}
            {current === 2 && <Eligibility setCurrent={setCurrent} />}
            {!isEnquiry && (
              <>
                {current === 3 && <Question setCurrent={setCurrent} />}
                {current === 4 && <Personal setCurrent={setCurrent} />}
              </>
            )}
          </FormWrapper>
        </FormCard>
        {/* {terminputpage?.termsuitabilityflagstatus &&
        current === 3 &&
        terminputpage?.termsuitabilityflagstatus === "Yes" ? (
          <div
            css={`
              display: flex;
              width: 77%;
              justify-content: flex-start;
              margin-left: 68px;
              padding: 12px;
              ${mq.sm} {
                width: 100%;
                margin-left: 11px;
              }
              ${mq.md} {
                width: 100%;
                justify-content: flex-start;
                margin-left: 0px;
              }
              @media screen and (min-width: 768px) and (max-width: 1024px) {
                width: 92%;
                margin-left: 17px;
              }
              @media screen and (min-width: 1440px) and (max-width: 1520px) {
                width: 74%;
                margin-left: 87px;
              }
            `}
          >
            <Checkbox
              size="small"
              name={"suitability_check"}
              css={`
                color: var(--primary-color);
                padding: 0px;
                top: -36px;
                @media screen and (max-width: 768px) {
                  top: -53px;
                }
                @media screen and (min-width: 768px) and (max-width: 1024px) {
                  top: -28px;
                }
                @media screen and (min-width: 1440px) and (max-width: 1520px) {
                  top: -35px;
                }
              `}
              // defaultChecked={checked}
              onChange={e =>
                dispatch(
                  setData({
                    suitability: { suitability_check: e.target.checked },
                  }),
                )
              }
            />

            {terminputpage?.termsuitabilitydisclaimer && current === 3 && (
              <span>{parse(terminputpage?.termsuitabilitydisclaimer)}</span>
            )}
          </div>
        ) : (
          <></>
        )} */}
        {terminputpage?.termsuitabilityflagstatus &&
        current === 3 &&
        terminputpage?.termsuitabilityflagstatus === "Yes" ? (
          <>
            <div
              css={`
                display: flex;
                justify-content: center;
                width: 74%;
                margin: 0 auto;
                margin-top: 10px;
                ${mq.sm} {
                  width: 92%;
                }
                ${mq.md} {
                  width: 91%;
                }
                @media screen and (min-width: 1440px) and (max-width: 1650px) {
                  width: 72%;
                }
              `}
            >
              <div>
                <Checkbox
                  size="small"
                  name={"suitability_check"}
                  css={`
                    top: -7px;
                    left: -8px;
                    @media screen and (min-width: 1440px) and (max-width: 1650px) {
                      left: 0px;
                    }
                  `}
                  // defaultChecked={checked}
                  onChange={e =>
                    dispatch(
                      setData({
                        suitability: { suitability_check: e.target.checked },
                      }),
                    )
                  }
                />
              </div>
              <div>
                {terminputpage?.termsuitabilitydisclaimer && current === 3 && (
                  <span>{parse(terminputpage?.termsuitabilitydisclaimer)}</span>
                )}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        {current === 4 && (
          <>
            {terminputpage?.termwhatsappflagstatus &&
            terminputpage?.termwhatsappflagstatus === "No" ? (
              <></>
            ) : (
              <div
                css={`
                  margin: 0px auto;
                  width: 500px;
                  ${mq.sm} {
                    width: 90%;
                    margin: 20px auto;
                  }
                  ${mq.md} {
                    width: 90%;
                    margin: 20px auto 0px auto;
                  }
                `}
              >
                <WhatsappUpdate />
              </div>
            )}
            {/* {terminputpage?.termdisclaimer && current === 4 && (
              <div
                css={`
                  margin: 0px auto;
                  width: 500px;
                  ${mq.sm} {
                    width: 90%;
                    margin: 20px auto;
                  }
                  ${mq.md} {
                    width: 90%;
                    margin: 0px auto 20px auto;
                  }
                `}
              >
                {parse(terminputpage.termdisclaimer)}
              </div>
            )} */}
          </>
        )}

        {current === 1 && (
          <>
            {/* {terminputpage?.termwhatsappflagstatus &&
            terminputpage?.termwhatsappflagstatus === "No" ? (
              <></>
            ) : (
              <div
                css={`
                  margin: 0px auto;
                  width: 500px;
                  ${mq.sm} {
                    width: 90%;
                    margin: 20px auto;
                  }
                  ${mq.md} {
                    width: 90%;
                    margin: 20px auto 0px auto;
                  }
                `}
              >
                <WhatsappUpdate />
              </div>
            )} */}
            {terminputpage?.termdisclaimer && current === 1 && (
              <div
                css={`
                  width: 500px;
                  margin: 20px auto;

                  ${mq.md} {
                    width: 90%;
                  }
                `}
              >
                {parse(terminputpage.termdisclaimer)}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default LandingForm;
