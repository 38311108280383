import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
// import SimpleModal from "../modal/SimpleModal-component";
import CancelCircular from "../../assets/svg-icons/CancelCircular";
// import { useNumberInput, useNameInput } from "../../utils";
import TextInput from "../../pages/product-page/EligibilityCard/components/TextInput/TextInput";
import { useDispatch, useSelector } from "react-redux";
import {
  // setContactDetails,
  shareContactDetails,
} from "../../pages/Landing/input.slice";
import styled from "styled-components/macro";
import {
  Form,
  // Button,
  // ErrorMsg,
  InputWrapper,
} from "../../pages/input/component/Fieldset1.style";
import { form3 } from "../../pages/input/validations";
import { allowOnlyNumbers } from "../../pages/ProposalPage/inputUtils/onInput";
import { allowOnlyAlphabets } from "../../pages/ProposalPage/inputUtils/onInput";
import { lowerCase } from "../../pages/ProposalPage/inputUtils/onInput";

import * as mq from "../../styles/mediaQueries";
import useUrlQuery from "../../custom-hooks/useUrlQuery";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { capitalize } from "../../utils";
import parse from "html-react-parser";
import { clearQuotes } from "../../pages/product-page/components/comparePlanPopup.slice";

export const ModalBodyContent = ({ show, handleClose, content, isActive }) => {
  const { termquotepage } = useSelector(
    state => state.frontendBoot?.frontendData,
  );

  const history = useHistory();
  const enquiryId = useUrlQuery("enquiryId");
  const handleReplace = () => {
    isActive && history.replace(`?enquiryId=${enquiryId}`);
  };

  const dispatch = useDispatch();
  const { data } = useSelector(state => state.inputForm);
  const proposerName = capitalize(data?.name?.split(" ")[0]);

  const {
    register,
    setValue,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(form3),
    mode: "onChange",

    defaultValues: { name: data.name, email: data.email, mobile: data.mobile },
  });

  const [mobile, setMobile] = useState(data.mobile);
  const [email, setEmail] = useState(data.email);
  const [name, setName] = useState(data.name);
  const [shared, isShared] = useState();
  // const { onChange: onEmailChange } = register("email");
  // const { onChange, ...nameRegisterProps } = register("name");

  const onSubmit = details => {
    dispatch(shareContactDetails(details));
    isShared(true);
    setTimeout(() => {
      isShared(false);
    }, 4000);
  };

  const replaceData = () => {
    setEmail(data.email);
    setMobile(data.mobile);
    setName(data.name);
    setValue("name", data.name);
    setValue("phone", data.mobile);
    setValue("email", data.email);
  };
  return (
    <Modal
      centered
      show={show}
      onHide={() => {
        handleClose();
        replaceData();
        handleReplace();
      }}
    >
      <Modal.Header css={``}>
        <CancelCircular
          width="27"
          onClick={() => {
            handleClose();
            replaceData();
            handleReplace();
          }}
          style={{
            position: "absolute",
            right: "-10px",
            top: "-10px",
          }}
        />
        <Modal.Title
          css={`
            & h1 {
              font-size: 1.2rem;
              font-family: Inter;
              font-weight: 500;
              margin: 0;
              padding-right: 2.67em;
              ${mq.sm} {
                font-size: 1rem;
              }
              &::before {
                content: "Hi ${proposerName}, ";
              }
            }
            &::before {
              content: "";
              position: absolute;
              height: 23px;
              width: 7px;
              top: 1;
              left: 0;
              background: #ffcb00;
              border-radius: 0 45px 45px 0;
            }
          `}
        >
          {termquotepage?.contactuspopupheading
            ? parse(termquotepage?.contactuspopupheading)
            : parse(`<h1>Please Share Your Contact Details</h1>`)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className=" position-relative  d-flex flex-column align-items-center justify-content-center "
        css={`
          padding: 2rem;
          font-size: 14px;
          ${mq.sm} {
            padding: 1.5rem 0rem;
            font-size: 10px;
          }
        `}
      >
        <div className="text-center mx-3">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <InputWrapper style={{ margin: "0 0 10px" }}>
              <TextInput
                {...register("name")}
                label={"Enter your name"}
                onChange={e => {
                  setValue("name", e.target.value);
                  trigger("name");
                  setName(e.target.value);
                }}
                onInput={allowOnlyAlphabets}
                capitalize
                value={name}
                error={errors.name && errors.name.message}
                inputProps={{ maxLength: "50" }}
              />
            </InputWrapper>

            {/* <StyledInput
              type="name"
              maxLength={50}
              {...nameRegisterProps}
              defaultValue={data.name}
              style={{ textTransform: "capitalize" }}
              placeholder="Enter Your Name"
              className="w-75 rounded "
              onChange={evt => {
                setName(evt);
                onChange(evt);
              }}
            />
            {errors.name && (
              <ErrorMsg style={{ marginTop: "2px", marginBottom: "-12px" }}>
                {errors.name.message}
              </ErrorMsg>
            )} */}
            <br />
            <InputWrapper style={{ margin: "0 0 10px" }}>
              <TextInput
                label={"Enter your mobile number"}
                {...register("mobile")}
                onChange={e => {
                  setValue("mobile", e.target.value);
                  trigger("mobile");

                  setMobile(e.target.value);
                }}
                error={errors.mobile && errors.mobile.message}
                onInput={allowOnlyNumbers}
                value={mobile}
                inputProps={{ maxLength: "10" }}
              />
            </InputWrapper>

            <br />
            <InputWrapper>
              <TextInput
                label={"Enter your email"}
                {...register("email")}
                onChange={evt => {
                  evt.target.value = evt.target.value.replace(" ", "");
                  lowerCase(evt);
                  // register("email").onChange(evt);
                  setValue("email", evt.target.value);
                  trigger("email");
                  setEmail(evt.target.value);
                }}
                value={email}
                error={errors.email && errors.email.message}
                inputProps={{ minLength: 2, maxLength: 50, inputMode: "email" }}
              />
            </InputWrapper>

            {shared && <p>Our team will contact you ASAP</p>}
            <button
              className="btn btn-primary"
              style={{
                fontSize: "15px",
                border: "none",
                padding: "12px 16px",
                marginTop: "5px",
                background: "var(--primary-color)",
              }}
              type="submit"
            >
              Get A Callback
            </button>
          </Form>
          {/* <div className="d-flex flex-column">
            <div
              css={`
                margin-top: 15px;
                margin-bottom: 10px;
                color: #737d85;
                ${mq.sm} {
                  margin-top: 5px;
                  margin-bottom: 5px;
                }
              `}
            >
              ——— OR ———
            </div>
            <div
              css={`
                margin: 10px;
                color: #4d5778;
                ${mq.sm} {
                  margin: 5px;
                }
              `}
            >
              <span>Is it super urgent? Call us on our Toll Free Number.</span>
              <br />

              <span
                css={`
                  font-size: 20px;
                  color: #4d5778;
                  ${mq.sm} {
                    font-weight: 600;
                    font-size: 15px;
                  }
                `}
              >
                99999 99999
              </span>
            </div>
            <div
              css={`
                margin: 10px;
                color: #737d85;
                ${mq.sm} {
                  margin: 5px;
                }
              `}
            >
              ——— OR ———
            </div>
        
            <div
              css={`
                fontsize: 20px;
                color: #4d5778;
                ${mq.sm} {
                  font-size: 15px;
                }
              `}
              style={{}}
            >
              Email us at <a href="mailto: abc@txt.com">abc@txt.com</a>
            </div>
          </div> */}
          <ContactPopupWrap>
            {termquotepage?.contactuspopupdata ? (
              parse(termquotepage?.contactuspopupdata)
            ) : (
              <>
                <p className="contact-us-or">——— OR ———</p>
                <p className="contact-us-text">
                  If urgent please call us on our Toll Free Number
                </p>
                <p className="contact-us-number">9999999999</p>
                <p className="contact-us-or">——— OR ———</p>
                <p className="contact-us-email">
                  Email us at <a href="mailto: abc@txt.com">abc@txt.com</a>
                </p>
              </>
            )}
          </ContactPopupWrap>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const TalkToUsBtn = () => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => setShowModal(false);
  // const handleShow = () => showModal(true);
  return (
    <>
      <button
        className="btn btn-outline-primary "
        css={`
          margin-left: 10px;
          font-family: "Inter";
          font-weight: 700;
          font-size: 13px;
        `}
        onClick={() => {
          dispatch(clearQuotes());
          setShowModal(true);
        }}
      >
        {" "}
        <i className="fa fa-phone-alt icon-left" aria-hidden="true"></i>
        {"  "}Contact Us
      </button>

      {/* ----------------modal content--------------- */}

      <ModalBodyContent show={showModal} handleClose={handleClose} />
    </>
  );
};

export default TalkToUsBtn;

const ContactPopupWrap = styled.div`
  padding: 5px;
  margin: auto;
  margin-top: 5px;
  ${mq.sm} {
    margin-top: 0px;
  }
  p {
    margin: 5px;
  }
  .contact-us-or {
    color: #737d85;
  }
  .contact-us-text {
    color: #4d5778;
    font-size: 14px;
    margin-top: 10px;
    color: #4d5778;
    ${mq.sm} {
      margin-top: 5px;
      font-size: 12px;
    }
  }
  .contact-us-number {
    font-size: 1rem;
    color: #4d5778;
    margin: 0px 0px 10px 0px;

    ${mq.sm} {
      font-weight: 600;
      font-size: 15px;
      margin: 0px 0px 10px 0px;
    }
  }

  .contact-us-email {
    color: #4d5778;
    font-size: 14px;
    margin-top: 10px;
    color: #4d5778;
    ${mq.sm} {
      font-size: 14px;
    }
  }
`;

// const StyledInput = styled.input`
//   margin: 10px auto;
//   border: 2px solid #ccc;
//   height: 30px;
//   padding: 20px 18px;
//   font-size: 12px;
//   border-radius: 4px;
//   color: #666;
//   ${mq.sm} {
//     padding: 16px 18px;
//     margin: 7px auto;
//   }
// `;
