export const toUpperCase = e => {
  e.target.value = e.target.value.toUpperCase();
};
export const toCapitalize = e => {
  e.target.value = e.target.value.replace(/^(.)|\s+(.)/g, c => c.toUpperCase());
};
export const restrictSpace = e => {
  e.target.value = e.target.value.replace(" ", "");
};
//this allows space
export const allowOnlyAlphabets = e => {
  e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
};
export const allowOnlyAlphabetsNoSpace = e => {
  e.target.value = e.target.value.replace(/[^A-Za-z]/g, "");
};

export const lowerCase = event => {
  event.target.value = event.target.value.replace(" ", "");
  event.target.value = event.target.value.toLowerCase();
};

export const allowOnlyUniqueAlphabetsNoSpace = e => {
  let last = e.target.value[e.target.value.length - 1];
  e.target.value = e.target.value.replace(/([a-zA-Z])\1\1/gi, last + last);
  if (last === " ") {
    e.target.value = e.target.value.replace("  ", " ");
  }
  if (last) {
    e.target.value = e.target.value.replace(/[^a-zA-Z ]/g, "");
  }
};

export const allowOnlyNumbers = e => {
  e.target.value = e.target.value.replace(/[^0-9]/g, "");
};
export const allowAlphaNumeric = e => {
  e.target.value = e.target.value.replace(/[^A-Za-z0-9]/g, "");
};

//For Tata Names in some cases
export const allowOnlyAlphabetsAndApostrophe = e => {
  e.target.value = e.target.value.replace(/[^A-Za-z'\s]/g, "");
};
const checkValue = (str, max) => {
  if (str.charAt(0) !== "0" || str === "00") {
    var num = parseInt(str);
    if (isNaN(num) || num <= 0 || num > max) num = 1;
    str =
      num > parseInt(max.toString().charAt(0)) && num.toString().length === 1
        ? "0" + num
        : num.toString();
  }
  return str;
};

export const allowAddressHdfc = e => {
  // eslint-disable-next-line no-useless-escape
  e.target.value = e.target.value.replace(/[^A-Za-z0-9\s\-#\/.'()]/g, "");
};

export const allowAddressIcici = e => {
  e.target.value = e.target.value.replace(/[^A-Za-z0-9\s\-:/,.]/g, "");
};

export const allowAddressTata = e => {
  // eslint-disable-next-line no-useless-escape
  e.target.value = e.target.value.replace(/[^A-Za-z0-9\s\,.()'-:]/g, "");
  // e.target.value = e.target.value.replace(/[^A-Za-z0-9\s\-\/,.]/g, "");
};
export const allowSomeSpecialChar = e => {
  // eslint-disable-next-line no-useless-escape
  e.target.value = e.target.value.replace(/[^A-Za-z0-9\s\/,.:'()_]/g, "");
};
export const allowDrivingLicenseNumber = e => {
  e.preventDefault();
  toUpperCase(e);
  allowAlphaNumeric(e);
  if (e.target.value.length === 4) e.target.value += " ";
};

export const allowDate = event => {
  event.preventDefault();
  var input = event.target.value;
  if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
  var values = input.split("/").map(function (v) {
    return v.replace(/\D/g, "");
  });
  if (values[0]) values[0] = checkValue(values[0], 31);
  if (values[1]) values[1] = checkValue(values[1], 12);
  var output = values.map(function (v, i) {
    return v.length === 2 && i < 2 ? v + "/" : v;
  });
  event.target.value = output.join("").substr(0, 10);
};

// Bajaj Date MM/YYYY Format
export const allowNewDate = event => {
  event.preventDefault();
  var input = event.target.value;
  if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
  var values = input.split("/").map(function (v) {
    return v.replace(/\D/g, "");
  });
  if (values[0]) values[0] = checkValue(values[0], 12);
  var output = values.map(function (v, i) {
    return v.length === 2 && i < 1 ? v + "/" : v;
  });
  event.target.value = output.join("").substr(0, 7);
};

// allow digits and character but no special char
export const allowAlphabetsAndDigits = e => {
  e.target.value = e.target.value.replace(/[^A-Za-z0-9\s]/g, "");
};
