import { useEffect, useState } from "react";

import styled from "styled-components";
import correctImg from "../../../assets/images/correct.png";
import CardModal from "../../../components/modal/Modal-component";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setSelectedInsurer } from "../Filters/Filters.slice";
import { usePatchEnquiriesMutation } from "../../riders/services/rider";

import "styled-components/macro";
import { Checkbox, FormControlLabel } from "@material-ui/core";
export const LiWrapper = ({
  name,
  id_for,
  value,
  checked,
  onClick = () => {},
  logo,
  ...props
}) => {
  return (
    <InsurerWrapper checkedbgimg={correctImg} {...props}>
      <Insurer
        htmlFor={id_for}
        css={`
          padding: 7px 11px;
        `}
        className={`col-md-12 d-flex flex-nowrap  rounded mb-2 ${
          checked ? `active` : ``
        }`}
      >
        <Checkbox
          onClick={onClick}
          checked={checked}
          defaultChecked
          value={value}
          id={value}
        />
        <div
          css={`
            width: 100px;
            & img {
              height: 20px;
            }
          `}
          className="filters-width-divs"
        >
          <img
            style={{ width: "65px", height: "25px" }}
            src={logo}
            alt="logo"
          />
        </div>
        <span className="form-check-label" for="inlineCheckbox2">
          {name}
        </span>
      </Insurer>
    </InsurerWrapper>
  );
};

const ModalBodyContent = (selected, setSelected, termcompanies, allICs) => {
  return (
    <ul className="container">
      {allICs &&
        allICs
          .sort((a, b) => a?.company_alias?.localeCompare(b?.company_alias))
          .map(item => (
            <LiWrapper
              key={item?.company_alias}
              name={termcompanies[item?.company_alias]?.short_name}
              id_for={item?.company_alias}
              value={item?.company_alias}
              checked={selected && selected.includes(item?.company_alias)}
              logo={termcompanies[item?.company_alias]?.logo}
              onClick={e => {
                if (e.target.checked) {
                  setSelected([...selected, e.target.value]);
                } else {
                  setSelected(selected.filter(item => item !== e.target.value));
                }
              }}
            />
          ))}

      {/* {termcompanies &&
        Object.keys(termcompanies).map(item => (
          <LiWrapper
            name={termcompanies[item].short_name}
            id_for={item}
            value={item}
            checked={selected && selected.includes(item)}
            logo={termcompanies[item].logo}
            onClick={e => {
              if (e.target.checked) {
                setSelected([...selected, e.target.value]);
              } else {
                setSelected(selected.filter(item => item !== e.target.value));
              }
            }}
          />
        ))} */}
    </ul>
  );
};

const ModalFoterContent = selected => {
  const dispatch = useDispatch();

  const [patchEnquiries] = usePatchEnquiriesMutation();

  const handleClick = () => {
    patchEnquiries({ input: { selected_insurers: selected } });
    dispatch(setSelectedInsurer(selected));
  };

  return (
    <button
      className="btn btn-primary w-100 rounded-0 shadow-none"
      onClick={handleClick}
    >
      Update
    </button>
  );
};

const SelectInsurer = ({ showModal, setShowModal }) => {
  const dispatch = useDispatch();

  const [patchEnquiries] = usePatchEnquiriesMutation();
  const handleClose = () => {
    patchEnquiries({ input: { selected_insurers: selected } });
    dispatch(setSelectedInsurer(selected));
    setShowModal(false);
  };
  // const handleShow = () => showModal(true);
  const {
    filters: { selected_insurers },
  } = useSelector(state => state.filters);
  const [selected, setSelected] = useState(selected_insurers || []);
  const { quotes, differentQuotes } = useSelector(state => state.quotes);

  //For Normal Quotes
  let normalQuotes = Object.keys(quotes).map(item => quotes[item]);
  let normalQuotesData = normalQuotes
    .map(quote => quote !== null && quote[0])
    .filter(item => item !== false);

  //For Different Quotes
  let diffQuotes = Object.keys(differentQuotes).map(
    item => differentQuotes[item],
  );
  let differentQuotesData = diffQuotes
    .map(quote => quote !== null && quote[0])
    .filter(item => item !== false);

  //Combined both normal and different quotes
  let allICs = [...normalQuotesData, ...differentQuotesData];
  let allCompanyAlias = allICs?.map(i => i?.company_alias);
  let {
    frontendData: { termcompanies },
  } = useSelector(state => state.frontendBoot);

  useEffect(() => {
    setSelected(selected_insurers || []);
  }, [selected_insurers]);
  return (
    <>
      {/*---------------------modal content----------------------*/}

      <CardModal
        show={showModal}
        handleClose={handleClose}
        heading={
          <div className="pb-2 mt-1">
            Preferred Insurer
            <div>
              {" "}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selected.length === allICs?.length}
                    onClick={e => {
                      if (e.target.checked) {
                        setSelected(allCompanyAlias);
                      } else {
                        setSelected([]);
                      }
                    }}
                  />
                }
                label={"Select All"}
              >
                {" "}
              </FormControlLabel>
            </div>
            <p
              style={{
                position: "absolute",
                color: "var(--primary-color)",
                fontSize: "14px",
              }}
            >
              {selected.length !== 0
                ? `You have selected ${
                    selected.length === 1
                      ? "(1) insurer"
                      : "(" + selected.length + ") insurers"
                  }`
                : null}
            </p>
          </div>
        }
        bodyContent={ModalBodyContent(
          selected,
          setSelected,
          termcompanies,
          allICs,
        )}
        footerContent={ModalFoterContent(selected)}
        maxHeight={"500px"}
      />
    </>
  );
};

export default SelectInsurer;

const InsurerWrapper = styled.li`
  & input[type="checkbox"] {
    display: none;
  }
  & input[type="checkbox"]:checked + label {
    background: #f4f6fa;
    .mod_icon {
      border-color: var(--primary-color);

      background-image: url(${correctImg}) !important;
      background-size: 32px;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .PrivateSwitchBase-root-1 {
    padding: 4px;
    margin-right: 5px;
  }
  .MuiSvgIcon-root {
    font-size: 20px;
  }
  .mod_icon {
    border: 1px solid grey;
    background: white;
    border-radius: 100%;
    width: 20px;
    height: 20px;
  }
  list-style: none;
`;

// const DownArrow = styled.div`
//   width: 10px;
//   height: 20px;
//   & img {
//     width: 100%;
//     height: auto;
//   }
// `;

const Insurer = styled.label`
  align-items: center;
  border: 1px solid #d2d8e2;
  & img {
    height: 10px;
  }
`;
