import React from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components/macro";
import * as mq from "../../../src/styles/mediaQueries";
import CancelCircular from "../../assets/svg-icons/CancelCircular";
// import { ShareQuotePopup } from "../product-page/components/ShareQuotePopup";
import { useSelector } from "react-redux";
// import { borderBottom } from "@mui/system";
import ShareProposalPopup from "./ShareProposalPopup";
export const ProposalHeaderModal = ({
  company_alias,
  handleClose,
  showPopup,
  productName,
  sumAssuured,
}) => {
  const { data } = useSelector(state => state.inputForm);
  const proposerName = data?.name?.split(" ")[0];

  return (
    <>
      <Modal
        centered
        show={showPopup}
        onHide={handleClose}
        css={`
          & .modal-dialog {
            max-width: 650px;
          }
        `}
      >
        <Modal.Header>
          <Modal.Title
            css={`
              font-size: 1.2rem;

              font-family: Inter;
              font-weight: 500;
              padding-right: 2.67em;
              ${mq.sm} {
                font-size: 0.8rem;
              }

              &::before {
                content: "";
                position: absolute;
                height: 30px;
                width: 10px;
                top: 1;
                left: 0;
                background: #ffcb00;
                border-radius: 0 45px 45px 0;
              }
            `}
            // style={{ fontSize: "1.2rem", fontFamily: "Inter",font-weight:  "600"; }}
          >
            Hi {proposerName}, Please choose the way you wish to share your Life
            Insurance Proposal.
          </Modal.Title>

          <CancelCircular
            width="27"
            onClick={handleClose}
            css={`
              position: absolute;
              right: 20px;
              top: 15px;
            `}
          />
        </Modal.Header>
        <Modal.Body
          css={`
            padding-top: 0px;
            padding-bottom: 0px;
            padding-left: 0px;
          `}
        >
          <CompWrapper>
            {" "}
            <ShareProposalPopup
              company_alias={company_alias}
              productName={productName}
              sumAssuured={sumAssuured}
            />
          </CompWrapper>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default ProposalHeaderModal;

const CompWrapper = styled.div`
  padding: 1rem 1rem 0;
  ${mq.sm} {
    padding-top: 1rem;
    padding-left: 0px;
    padding-right: 0.1rem;
  }
`;
