import styled from "styled-components";
import * as mq from "../../../../styles/mediaQueries";
export const DownloadWrapper = styled.div`
  width: 90%;
  border-radius: 15px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  padding: 12px;
  margin: 16px auto;
  margin-left: 60px;
  ${mq.md}{
    margin-top:188px;
    margin-bottom:0px;
  }
  ${mq.sm} {
    margin-left: 18px;
      display: flex;
      align-items: center;
      margin-bottom: 80px;
      width: 90%;
      margin-top: 0px;
      
    }
  @media screen and (min-width:820px) and (max-width:884px){
    margin-top:348px;
    margin-bottom:56px;
  }
  & h6 {
    font-weight: 700;
    font-family: "Inter";
    
    font-size: clamp(12px, 2vw, 20px);
    ${mq.sm} {
      //text-align: center;
      font-size: 0.8rem;
      margin-bottom:0;
      margin-left:6px;
    }
  }
  & p {
    margin-bottom: 0;
     font-family: "Inter";
     font-weight: 400;
      font-size: 13px;
     ${mq.sm} {
     padding:5px;

    }
  }
  }
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  width: 100%;

  ${mq.sm} {
    margin-top: 5px;

    gap: 0.1em;
  }
`;
export const DownloadButton = styled.a`
  text-align: center;
  border-radius: 8px;
  color: var(--secondary-text);
  background: var(--tertiary-shade);
  padding: 8px;
  // margin-left:50px;

  ${mq.sm} {
    width: 46%;
    border-radius: 5px;
  }
  & i {
    color: var(--primary-color-dark);
    ${mq.sm} {
      margin-left: 5px;
    }
  }
  &,
  &:link,
  &:visited {
    text-decoration: none;
  }
  font-size: clamp(12px, 2vw, 16px);
  color: ;
`;
