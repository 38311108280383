import React from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import MaskedInput from "react-text-mask";
import { Label } from "../../pages/product-page/EligibilityCard/EligibilityCard.style";
import "styled-components/macro";
import * as mq from "../../styles/mediaQueries";
const DateComp = ({
  label,
  placeholder,
  type,
  required,
  onChange,
  error,
  onBlur,
  onKeyDown,
  reference,
  value,
  onKeyPress,
  maxLength,
  textTransform,
  onInput,
  readOnly,
  startDate,
  endDate,
  age,
}) => {
  // const [innerValue, setInnerValue] = useState(value);
  // useEffect(() => {
  //   setInnerValue(value);
  // }, [value]);
  let newDate = new Date();
  let currentYear = newDate.getFullYear();
  let currentMonth = newDate.getMonth();
  let currentDate = newDate.getDate();
  return (
    <InputContainer error={error}>
      <DatePicker
        showYearDropdown
        dateFormat={"dd/MM/yyyy"}
        selected={
          value && value !== "Invalid date" && value !== "value"
            ? moment(value, "DD/MM/YYYY")?.toDate()
            : ""
        }
        minDate={
          age && age[1] >= 0
            ? new Date(
                currentYear - (age[1] + 1),
                currentMonth,
                currentDate - 1,
              )
            : ""
        }
        maxDate={
          age && age[0] >= 1
            ? new Date(currentYear - age[0], currentMonth, currentDate)
            : age[0]
            ? new Date(
                currentYear,
                currentMonth - Number(age[0].toString().split(".")[1]),
                currentDate,
              )
            : ""
        }
        placeholderText={placeholder}
        readOnly={readOnly}
        onChange={date => {
          onChange({ target: { value: moment(date).format("DD/MM/YYYY") } });
        }}
        customInput={
          <MaskedInput
            guide={false}
            mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
          />
        }
      />

      <Label
        css={`
          top: -6px;
          left: 12px;
          background-color: #fff;
          ${mq.sm} {
            top: 5px !important;
            left: 0px !important;
          }
        `}
      >
        {label}
      </Label>
      {/* <Calendar error={error} src={calendar} alt="calendar" /> */}
      <p className="formbuilder__error">{error}</p>
    </InputContainer>
  );
};

export default DateComp;
// const Calendar = styled.img`
//   position: absolute;
//   height: 20px;
//   width: 20px;
//   top: 50%;
//   right: 20px;
//   transform: ${props =>
//     props.error ? "translateY(calc(-50% - 8px))" : "translateY(-50%)"};
// `;
const InputContainer = styled.div`
  height: 100%;
  & .react-datepicker__input-container {
    height: 100%;
  }
  & .react-datepicker__navigation--years-upcoming {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-bottom: 5px solid black;
  }
  & .react-datepicker__navigation--years-previous {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-top: 5px solid black;
  }
  position: relative;
  z-index: 5;
  margin-bottom: 12px !important;
  @media (max-width: 768px) {
    margin-bottom: 0px !important;
  }
  & > div {
    width: 100%;
    height: 100%;
  }
  & input {
    border: ${props => (props.error ? "solid 1px #c7222a" : "solid 1px #ccc")};
    border-radius: 4px;
    background: ${props => (props.error ? "#fff6f7" : "transparent")};
    height: 50px;
    font-family: inherit;
    line-height: inherit;
    overflow: visible;
    outline: none;
    box-shadow: none;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    width: 100%;
    font-size: 16px;
    position: relative;
    padding: 22px 10px;
    &:focus {
      border-color: ${props =>
        props.error ? "#c7222a" : "solid 1px  #393939"};
      color: black;
    }
    &:hover {
      border: 1px solid #233d63;
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    }
    @media (max-width: 767px) {
      height: 50px;
      padding: 15px 0 15px 15px !important;
      border-radius: 6px;
      color: #000;
      font-size: 14px;
    }
  }
`;
// const Input = styled.input`
//   list-style: none;
//   list-style-type: none;
//   user-select: none;
//   -webkit-tap-highlight-color: transparent;
//   box-sizing: border-box;
//   margin: 0;
//   text-transform: ${props => props.textTransform};
// `;
