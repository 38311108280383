import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useUrlQuery from "../../../../../custom-hooks/useUrlQuery";
import { useNameInput, useNumberInput } from "../../../../../utils";
import {
  allowOnlyNumbers,
  // allowOnlyAlphabets,
  allowOnlyUniqueAlphabetsNoSpace,
} from "../../../../ProposalPage/inputUtils/onInput";
import {
  saveData,
  setData,
  updateEnquiriesOnInput,
  saveOtpData,
} from "../../../input.slice";
import { Button, InputWrapper } from "../../../Landing.style";
import { form4 } from "../../../validations";
import FormWithTitle from "../../FormWithTitle/FormWithTitle";
import TextInput from "../../TextInput/TextInput";
import "styled-components/macro";
import * as mq from "../../../../../styles/mediaQueries";
import { lowerCase } from "../../../../../pages/ProposalPage/inputUtils/onInput";
import OTPFormGeneral from "./OTPPopup";
import { toast } from "react-toastify";
import ButtonLoader from "../../../../../components/CardSkeletonLoader/ButtonLoader/ButtonLoader";
import { useGetSellerInfoQuery } from "../../../../riders/services/rider";
const getLocalData = () => {
  const field_3 = localStorage.getItem("Personal Info");
  if (field_3) return JSON.parse(field_3);
  else return {};
};

const Personal = ({ setCurrent }) => {
  const { terminputpage, termsettings } = useSelector(
    state => state.frontendBoot.frontendData,
  );
  const history = useHistory();
  const {
    register,
    handleSubmit,
    trigger,
    clearErrors,
    formState: { errors },
    // watch,
  } = useForm({
    resolver: yupResolver(form4),
    mode: "onChange",
    defaultValues: getLocalData(),
  });
  const dispatch = useDispatch();
  const enquiryId = useUrlQuery("enquiryId");
  const USERTYPE = useUrlQuery("USERTYPE");
  const USERID = useUrlQuery("USERID");
  const token = useUrlQuery("token");
  const { data } = useGetSellerInfoQuery(
    {
      token: token,
    },
    {
      skip: !token,
    },
  );
  const params = {
    USERTYPE,
    USERID,
    token,
    other_req_data: data?.other_req_data,
  };

  const [name, onNameChange] = useNameInput(getLocalData().name);
  const [email, setEmail] = useState(getLocalData().email);
  const [mobileNumber, setMobileNumber] = useNumberInput(getLocalData().mobile);
  const { otpResponse, isEnquiryLoading } = useSelector(
    state => state.inputForm,
  );
  const [otpVerified, setOtpVerified] = useState(false);
  const [otp, setOtp] = useState(false);

  useEffect(() => {
    if (otpResponse?.status === true && otpResponse.message === "Verified") {
      setOtpVerified(true);
      toast.success("OTP Verified");
    }
  }, [otpResponse?.status, otpResponse.message]);

  const onSubmit = data => {
    dispatch(setData({ ...data, params }));
    localStorage.setItem(
      "Personal Info",
      JSON.stringify({
        name: name,
        email: email,
        mobile: mobileNumber,
      }),
    );
    if (enquiryId) {
      dispatch(
        updateEnquiriesOnInput(() => {
          history.push(`/quote?enquiryId=${enquiryId}`);
        }),
      );
    } else
      dispatch(
        saveData(enquiryId => {
          history.push(`/quote?enquiryId=${enquiryId}`);
        }),
      );
  };
  return (
    <>
      <FormWithTitle
        onSubmit={handleSubmit(onSubmit)}
        current={4}
        title={
          terminputpage?.terminputpage3
            ? terminputpage?.terminputpage3
            : "<h1>How can we reach out to you ?</h1>"
        }
      >
        <div>
          <InputWrapper>
            <TextInput
              {...register("name")}
              label="Full Name*"
              size="large"
              capitalize
              onInput={allowOnlyUniqueAlphabetsNoSpace}
              onChange={e => {
                onNameChange(e);
                register("name").onChange(e);
              }}
              error={errors.name && errors.name.message}
              value={name}
              inputProps={{ minLength: 2, maxLength: 30, autoFocus: true }}
            />
          </InputWrapper>
          <InputWrapper>
            <TextInput
              label="Email*"
              size="large"
              {...register("email")}
              onChange={e => {
                e.target.value = e.target.value.replace(" ", "");
                lowerCase(e);
                register("email").onChange(e);
                setEmail(e.target.value);
              }}
              error={errors.email && errors.email.message}
              value={email}
              inputProps={{ minLength: 2, maxLength: 50, inputMode: "email" }}
            />
          </InputWrapper>
          <InputWrapper>
            <TextInput
              label="Mobile Number*"
              size="large"
              {...register("mobile")}
              onChange={e => {
                setMobileNumber(e);
                register("mobile").onChange(e);
                trigger("mobile");
                clearErrors("mobile");
              }}
              error={errors.mobile}
              helperText={errors.mobile && errors.mobile.message}
              value={mobileNumber}
              onInput={allowOnlyNumbers}
              inputProps={{ maxLength: 10, inputMode: "numeric" }}
            />
          </InputWrapper>
        </div>
        <div
          css={`
            display: flex;
            gap: 30px;
            width: 100%;
            margin: auto;
            ${mq.sm} {
              width: 100%;
              gap: 1px;
            }
          `}
        >
          <Button
            css={`
              flex: 1 1;
              background-color: rgba(220, 220, 220, 0.5);
              color: var(--secondary-text);
              font-weight: 900;
              height: 50px;
              width: 5rem;
              padding-top: 10px;
              ${mq.sm} {
                padding-top: 16px;
              }
            `}
            onClick={() => {
              if (terminputpage?.termsuitabilityflagstatus === "Yes")
                setCurrent(prev => prev - 1);
              else setCurrent(prev => prev - 2);
            }}
          >
            Back
          </Button>
          {termsettings?.otp_journey === "Yes" && otpVerified === false ? (
            <Button
              style={{
                display: "flex",
                flex: "1 1",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "900",
                height: "50px",
              }}
              onClick={e => {
                e.preventDefault();
                trigger("name");
                trigger("email");
                trigger("mobile");
                if (
                  email &&
                  !errors?.email &&
                  mobileNumber &&
                  !errors?.mobile
                ) {
                  dispatch(
                    saveOtpData({
                      mobile: mobileNumber,
                      email: email,
                      section: "term",
                    }),
                  );
                  setOtp(true);
                }
              }}
            >
              View Quotes
            </Button>
          ) : otpResponse?.status === true &&
            otpResponse.message === "Verified" ? (
            <Button
              style={{
                display: "flex",
                flex: "1 1",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "900",
                height: "50px",
              }}
              type="submit"
              disabled={isEnquiryLoading && true}
            >
              {isEnquiryLoading ? <ButtonLoader /> : "View Quotes"}
            </Button>
          ) : (
            <Button
              style={{
                display: "flex",
                flex: "1 1",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "900",
                height: "50px",
              }}
              type="submit"
              disabled={isEnquiryLoading && true}
            >
              {isEnquiryLoading ? <ButtonLoader /> : "View Quotes"}
            </Button>
          )}
        </div>
        <OTPFormGeneral
          mobileNumber={mobileNumber}
          email={email}
          setOtp={setOtp}
          otp={otp}
        />
      </FormWithTitle>
    </>
  );
};

export default Personal;
