// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
import PrintIcon from "../../../assets/images/print.png";
import styled from "styled-components/macro";
// import { CompareHeader } from "../header";
// import { useGetCompareProductsQuery } from "../../riders/services/rider";
import { useDispatch, useSelector } from "react-redux";
import { downloadCompare } from "../../Landing/serviceApi";
import { clearDownloading, initDownloading } from "../compare.slice";
import ButtonLoader from "../../../components/CardSkeletonLoader/ButtonLoader/ButtonLoader";
// export const download = url => {

//   const link = document.createElement("a");
//   link.href = url;

//   link.download = "Term_Comparison";
//   link.target = "_blank";
//   link.click();

//   document.body.appendChild(link);
//   document.body.removeChild(link);
// };
export const download = url => {
  // saveAs(url);
  const a = document.createElement("a");
  a.href = url;
  document.body.appendChild(a);
  a.target = "_blank";
  a.click();

  document.body.removeChild(a);
};

const StyledPrintButton = styled.button`
  color: #fff;
  font-family: "Inter";
  font-weight: 700;
  border-radius: 6px;
  border: none;
  background-color: var(--primary-color);

  align-items: center;
  gap: 0.3em;
  /* border: 1px solid rgb(233, 233, 233); */
  padding: 0.3em 0.6em;
  font-size: 1rem;
  box-shadow: 0 0 0.1em #00000033;
  height: 3em;
  width: 50%;
`;

const PrintButtonIcon = styled.img`
  max-width: 2em;

  margin-top: -10px;
  margin-left: 85px;
`;

function DownloadCompareButton() {
  // const { data, isLoading } = useGetCompareProductsQuery();

  const { addedRiders } = useSelector(state => state.quotes);
  const { showDifference, selectedFeatures, isDownloading } = useSelector(
    state => state.compare,
  );

  const dispatch = useDispatch();

  return (
    <StyledPrintButton
      onClick={() => {
        dispatch(initDownloading());
        downloadCompare({
          selected_features: selectedFeatures,
          show_difference: showDifference,
          selected_riders: addedRiders,
        })
          .then(({ data }) => {
            download(data.data);
            dispatch(clearDownloading());
          })
          .catch(err => {
            console.error(err);
            dispatch(clearDownloading());
          });
      }}
    >
      {isDownloading ? (
        <ButtonLoader />
      ) : (
        <div>
          Download&nbsp;&nbsp;<i className="far fa-arrow-alt-circle-down"></i>
        </div>
      )}
    </StyledPrintButton>
  );
}
function DownloadPrintButton() {
  const { addedRiders } = useSelector(state => state.quotes);
  const { showDifference, selectedFeatures } = useSelector(
    state => state.compare,
  );
  return (
    <div
      css={`
        float: right;
      `}
      onClick={() => {
        downloadCompare({
          selected_features: selectedFeatures,
          show_difference: showDifference,
          selected_riders: addedRiders,
        })
          .then(({ data }) => download(data.data))
          .catch(err => console.error(err));
      }}
    >
      <PrintButtonIcon src={PrintIcon} alt="print" />
    </div>
  );
}

export { DownloadCompareButton, DownloadPrintButton };
