import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setData } from "../../../input.slice";
import { Radio, RadioWrapper } from "../../../Landing.style";
import { form1Schema } from "../../../validations";
import male from "./../../../../../assets/images/male.png";
import female from "./../../../../../assets/images/female.png";
import cigarrete from "../../../../../assets/images/cigarrete.png";
import dontSmoke from "../../../../../assets/images/dont-smoke.png";
import FormWithTitle from "../../FormWithTitle/FormWithTitle";
import TextInput from "../../TextInput/TextInput";
import { dateUtil } from "../../../../input/validations";
import { InputAdornment } from "@mui/material";
import styled from "styled-components";

const getLocalData = () => {
  const field_1 = localStorage.getItem("Basic Info");
  if (field_1) return JSON.parse(field_1);
  else
    return {
      gender: "M",
      tobacco_habit: "NS",
    };
};
const Premium = ({ setCurrent }) => {
  const { terminputpage } = useSelector(
    state => state.frontendBoot.frontendData,
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    watch,
    reset,
    trigger,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(form1Schema({ minAge: 18, maxAge: 65 })),
    defaultValues: {},
  });
  const [age, setAge] = useState(getLocalData()?.age);
  useEffect(() => {
    reset(getLocalData());
  }, [reset]);
  const dispatch = useDispatch();

  const onSubmit = data => {
    dispatch(
      setData({ ...data, dob: data.dob.split(" / ").reverse().join("-") }),
    );
    setCurrent(prev => prev + 1);
    localStorage.setItem(
      "Basic Info",
      JSON.stringify({
        tobacco_habit: data?.tobacco_habit,
        dob: data?.dob && data.dob.split("-").reverse().join(" / "),
        gender: data?.gender,
        age: age,
      }),
    );
  };

  const date = watch("dob");
  return (
    <>
      <FormWithTitle
        title={
          terminputpage?.terminputpage1
            ? terminputpage?.terminputpage1
            : "<h1>Let's start with some basic details</h1>"
        }
        onSubmit={handleSubmit(onSubmit)}
        current={0}
        showButton
      >
        <div>
          <RadioWrapper>
            <Radio>
              <input
                type="radio"
                id="gen_m"
                value="M"
                name="gender"
                {...register("gender")}
              />
              <label htmlFor="gen_m">
                <img src={male} alt="gender" />
                <span>Male</span>
              </label>
            </Radio>
            <Radio>
              <input
                type="radio"
                id="gen_f"
                value="F"
                name="gender"
                {...register("gender")}
              />
              <label htmlFor="gen_f">
                <img src={female} alt="gender" />
                <span>Female</span>
              </label>
            </Radio>
          </RadioWrapper>
          <RadioWrapper>
            <Radio>
              <input
                type="radio"
                id="smoker"
                value="S"
                name="smoker"
                {...register("tobacco_habit")}
              />
              <label htmlFor="smoker">
                <img src={cigarrete} alt="smoker" />
                <span>Smoker</span>
              </label>
            </Radio>
            <Radio>
              <input
                type="radio"
                id="non-smoker"
                value="NS"
                name="smoker"
                {...register("tobacco_habit")}
              />
              <label htmlFor="non-smoker">
                <img src={dontSmoke} alt="non-smoker" />
                <span>Non Smoker</span>
              </label>
            </Radio>
          </RadioWrapper>

          <BirthDate>
            <TextInput
              className=" w-100 "
              name="dob"
              label="Date of Birth*"
              placeholder="DD / MM / YYYY"
              // register={register}
              {...register("dob")}
              error={errors.dob}
              errorIcon={errors.dob ? true : false}
              helperText={errors.dob && errors.dob.message}
              clearErrors={clearErrors}
              onChange={e => {
                const age = dateUtil(e);
                setAge(age);
                // register("dob").onChange(e);
                setValue("dob", e.target.value);
                trigger("dob");
                clearErrors("dob");
              }}
              value={date}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {/* {errors.dob && errors.dob.message ? (
                      <span className=" fs-5">
                        <MdError />
                      </span>
                    ) : ( */}
                    {age && `${age} years`}
                    {/* )} */}
                  </InputAdornment>
                ),
              }}
              inputProps={{
                maxLength: 14,
                inputMode: "numeric",
              }}
            />
          </BirthDate>
        </div>
      </FormWithTitle>
    </>
  );
};

export default Premium;
const BirthDate = styled.div`
  width: 100%;
  padding-bottom: 20px;
  ${
    "" /* input:focus {
    outline: none;
  } */
  }
`;
