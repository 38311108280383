/* eslint-disable no-useless-computed-key */
import moment from "moment";
import "styled-components/macro";
import ButtonLoader from "../../../../../components/CardSkeletonLoader/ButtonLoader/ButtonLoader";
import { useEffect, useState } from "react";
import {
  checkDocType,
  checkSize,
  convert,
} from "../../../../DocumentUpload-page/docUtils";
import { uploadDoc } from "../../../../DocumentUpload-page/serviceApi";
import Select from "../../../components/Select";
import Title from "../../../components/Title";
import {
  addressProofOptions,
  incomeProofOptions,
  idProofOptions,
  ageProofOptions,
} from "../../../options/icici/identificationOptions";
import {
  useGetApplicationNumberQuery,
  useGetCartQuery,
  // useGetEnquiriesQuery,
  useGetProposalDataQuery,
} from "../../../../riders/services/rider";
import {
  ErrorMsg,
  FileInputWrapper,
  UploaderBody,
  // UploadPanel,
  UploadWrap,
} from "../../../ProposalPage.style";
import {
  clearDocumentUploadLoading,
  initDocumentUploadLoading,
} from "../../../ProposalPage.slice";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";

const IciciDocumentUpload = ({ setDisable, disable }) => {
  const [documents, setDocuments] = useState({});
  const [previews, setPreviews] = useState({});
  const { data: cartData } = useGetCartQuery();
  const [errors, setErrors] = useState({});
  const [uploaded, setUploaded] = useState("");

  const dispatch = useDispatch();
  const { isDocumentUploadLoading } = useSelector(state => state.proposal);
  //   useEffect(() => {
  //     if (!isEnquiryLoading && data) {
  //       const {
  //         data: { input },
  //       } = data;
  //       setOccupation(occupationToCode[input?.selected_product?.occupation]);
  //     }
  //   }, [isEnquiryLoading, data]);
  const appData = useGetApplicationNumberQuery();
  const proposalData = useGetProposalDataQuery();

  const currentDate = moment().format("DDMMYYYY");
  const identificationDetails =
    proposalData?.data?.data["Insured Basic Identification Details"];

  const appNo = appData?.data?.data?.proposal_reference_id;

  const [docName, setDocName] = useState({
    proposer_photo: `${appNo}_ProposerPhoto_${currentDate}_5.pdf`,
    id_proof: `${appNo}_IdProof_${currentDate}_4.pdf`,
    address_proof: `${appNo}_AddressProof_${currentDate}_3.pdf`,
    income_proof: `${appNo}_IncomeProof_${currentDate}_2.pdf`,
    age_proof: `${appNo}_AgeProof_${currentDate}_1.pdf`,
  });

  const onSubmit = async (e, item) => {
    const newData = await convert(documents[item]?.upload_file);

    try {
      dispatch(initDocumentUploadLoading());
      const { data } = await uploadDoc({
        company_alias: cartData?.product?.company?.alias,
        application_no: appNo,
        upload_file_name: documents[item]?.upload_file_name,
        upload_file_id: documents[item]?.upload_file_id,
        upload_file: newData.split(",")[1],
      });
      if (data.data[0].doc_response?.uploadStatus === "Failure") {
        throw new Error("Upload Failed");
      }
      setDisable(prev => ({ ...prev, [item]: false }));
      // setIsUploaded(prev => ({
      //   ...prev,
      //   [item]: true,
      // }));
      toast.success(`${item} Uploaded!`, {
        toastId: item,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
      });
      dispatch(clearDocumentUploadLoading());
      return (document.getElementById(item).innerHTML = "Uploaded");
    } catch (err) {
      toast.error(`${item} Upload Failed!`, {
        toastId: `${item}_error`,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
      });
      dispatch(clearDocumentUploadLoading());
    }
  };
  // const [isUploaded, setIsUploaded] = useState({
  //   "Age Proof": false,
  //   "Address Proof": false,
  //   "ID Proof": false,
  //   "Income Proof": false,
  // });

  useEffect(() => {
    if (
      documents["Proposer Photo"]?.upload_file === undefined ||
      documents["Age Proof"]?.upload_file === undefined ||
      documents["ID Proof"]?.upload_file === undefined ||
      documents["Address Proof"]?.upload_file === undefined ||
      documents["Income Proof"]?.upload_file === undefined
    ) {
      return setDisable(prev => ({
        ...prev,
        ["Proposer Photo"]: true,
        ["Age Proof"]: true,
        ["ID Proof"]: true,
        ["Address Proof"]: true,
        ["Income Proof"]: true,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function createPreview(fileInput) {
    let blob = new Blob(fileInput?.target?.files, {
      type: fileInput?.target?.files[0]?.type,
    });

    return URL.createObjectURL(blob);
  }

  function removeDocument(document) {
    setDocuments(prev => {
      let temp = { ...prev };
      temp[document].upload_file = null;
      temp[document].upload_file_id = null;
      temp[document].upload_file_name = null;
      return temp;
    });

    setPreviews(prev => {
      let temp = { ...prev };
      temp[document] = {};
      return temp;
    });
    setDisable(prev => ({ ...prev, [document]: true }));
  }
  return (
    // <div>
    //   <Navbar />
    //   <div className="container mt-4 col-md-12">
    //     <UploadPanel>
    //       {/* ============================== HEADINGS =============================== */}
    //       <div className="col-md-12 panel_heading ">
    //         <p className="text-center">Almost done! Please upload documents.</p>
    //       </div>
    //       <div className="col-md-12 panel_heading_desc">
    //         <p>
    //           Please upload JPEG, JPG, PDF, TIF, TIFF file types only size (upto
    //           5MB)
    //         </p>
    //       </div>
    <div className="doc-form__wrapper my-4">
      {/* ================================= Proposer Photo ============================ */}
      <UploaderBody
        className="col-md-12 select_wrapper"
        active={documents["Proposer Photo"]?.upload_file ? true : false}
      >
        <div className="upload__body">
          <UploadWrap>
            {" "}
            <Title label={"Proposer Photo"} />
          </UploadWrap>
          <FileInputWrapper className="col-md-6">
            <span className="w-100 file_name_container">
              {docName.proposer_photo}
              <input
                type="file"
                name="myfile"
                id={docName.proposer_photo}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    e.target.files[0] &&
                    (!checkSize(e, 5) ||
                      !checkDocType(e, [
                        "jpg",
                        "jpeg",
                        "pdf",
                        "tiff",
                        "bmp",
                        "png",
                      ]))
                  ) {
                    setErrors({
                      ...errors,
                      ["Proposer Photo"]: "File type or size not allowed",
                    });
                  } else {
                    setDocName(prev => ({
                      ...prev,
                      proposer_photo: `${appNo}_ProposerPhoto_${currentDate}_5.${
                        e.target.files[0]?.name?.split(".")?.reverse()[0]
                      }`,
                    }));
                    setErrors({
                      ...errors,
                      ["Proposer Photo"]: "",
                    });
                    setDocuments({
                      ...documents,
                      ["Proposer Photo"]: {
                        ...(documents["Proposer Photo"]
                          ? documents["Proposer Photo"]
                          : {}),
                        upload_file: e.target.files[0],
                        upload_file_id: 2,
                        upload_file_name: `${appNo}_ProposerPhoto_${currentDate}_2.${
                          e.target.files[0]?.name?.split(".")?.reverse()[0]
                        }`,
                      },
                    });

                    setPreviews({
                      ...previews,
                      ["Proposer Photo"]: {
                        ...(previews["Proposer Photo"]
                          ? previews["Proposer Photo"]
                          : {}),
                        blob_file: createPreview(e),
                      },
                    });
                  }
                }}
              />
            </span>
          </FileInputWrapper>

          {!documents["Proposer Photo"]?.upload_file ? (
            <label className="Choose_file_btn" htmlFor={docName.proposer_photo}>
              Choose a file
            </label>
          ) : (
            <button
              disabled={disable["Proposer Photo"] === false}
              className={`col-4 Choose_file_btn btn `}
              onClick={e => {
                onSubmit(e, "Proposer Photo");
                setUploaded("Proposer Photo");
              }}
            >
              {uploaded === "Proposer Photo" && isDocumentUploadLoading ? (
                <ButtonLoader />
              ) : (
                <p id="Proposer Photo">Upload</p>
              )}
            </button>
          )}

          {previews["Proposer Photo"]?.blob_file && (
            <a
              className={`col-4 upload_btn btn `}
              href={previews["Proposer Photo"]?.blob_file}
              target="_blank"
              rel="noopener noreferrer"
            >
              Preview
            </a>
          )}

          {documents["Proposer Photo"]?.upload_file && (
            <i
              className="fas fa-trash-alt trash-icon"
              onClick={() => removeDocument("Proposer Photo")}
            />
          )}
        </div>
        {errors["Proposer Photo"] && (
          <ErrorMsg>{errors["Proposer Photo"]}</ErrorMsg>
        )}
      </UploaderBody>

      {/* ================================= Age ============================ */}

      <UploaderBody
        className="col-md-12 select_wrapper"
        active={documents["Age Proof"]?.upload_file ? true : false}
      >
        <div className="upload__body">
          <UploadWrap>
            <Select
              options={ageProofOptions}
              label={"Age Proof*"}
              readOnly
              selectedValue={identificationDetails?.age_proof}
              noSearch
              // handleChange={value =>
              //   setDocuments({
              //     ...documents,
              //     ["Age Proof"]: {
              //       ...(documents["Age Proof"] ? documents["Age Proof"] : {}),
              //       file_type: value,
              //     },
              //   })
              // }
            />
          </UploadWrap>
          <FileInputWrapper className="col-md-6">
            <span className="w-100 file_name_container">
              {docName.age_proof}
              <input
                type="file"
                name="myfile"
                id={docName.age_proof}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    e.target.files[0] &&
                    (!checkSize(e, 5) ||
                      !checkDocType(e, [
                        "jpg",
                        "jpeg",
                        "pdf",
                        "tiff",
                        "bmp",
                        "png",
                      ]))
                  ) {
                    setErrors({
                      ...errors,
                      ["Age Proof"]: "File type or size not allowed",
                    });
                  } else {
                    setDocName(prev => ({
                      ...prev,
                      age_proof: `${appNo}_AgeProof_${currentDate}_1.${
                        e.target.files[0]?.name?.split(".")?.reverse()[0]
                      }`,
                    }));

                    setErrors({
                      ...errors,
                      ["Age Proof"]: "",
                    });

                    setDocuments({
                      ...documents,
                      ["Age Proof"]: {
                        ...(documents["Age Proof"]
                          ? documents["Age Proof"]
                          : {}),
                        upload_file: e.target.files[0],
                        upload_file_id: 1,
                        upload_file_name: `${appNo}_AgeProof_${currentDate}_1.${
                          e.target.files[0]?.name?.split(".")?.reverse()[0]
                        }`,
                        file_type: identificationDetails?.age_proof,
                      },
                    });
                    setPreviews({
                      ...previews,
                      ["Age Proof"]: {
                        ...(previews["Age Proof"] ? previews["Age Proof"] : {}),
                        blob_file: createPreview(e),
                      },
                    });
                  }
                }}
              />
            </span>
          </FileInputWrapper>

          {!documents["Age Proof"]?.upload_file ? (
            <label className="Choose_file_btn" htmlFor={docName.age_proof}>
              Choose a file
            </label>
          ) : (
            <button
              disabled={disable["Age Proof"] === false}
              className={`col-4 Choose_file_btn btn `}
              onClick={e => {
                return (
                  documents["Age Proof"]?.upload_file?.name === undefined
                    ? null
                    : onSubmit(e, "Age Proof"),
                  setUploaded("Age Proof")
                );
              }}
            >
              {uploaded === "Age Proof" && isDocumentUploadLoading ? (
                <ButtonLoader />
              ) : (
                <p id="Age Proof">Upload</p>
              )}
            </button>
          )}

          {previews["Age Proof"]?.blob_file && (
            <a
              className={`col-4 upload_btn btn `}
              href={previews["Age Proof"]?.blob_file}
              target="_blank"
              rel="noopener noreferrer"
            >
              Preview
            </a>
          )}

          {documents["Age Proof"]?.upload_file && (
            <i
              className="fas fa-trash-alt trash-icon"
              onClick={() => removeDocument("Age Proof")}
            />
          )}
        </div>
        {errors["Age Proof"] && <ErrorMsg>{errors["Age Proof"]}</ErrorMsg>}
      </UploaderBody>
      {/* ================================= ID ============================ */}
      <UploaderBody
        className="col-md-12 select_wrapper"
        active={documents["ID Proof"]?.upload_file ? true : false}
      >
        <div className="upload__body">
          <UploadWrap>
            <Select
              options={idProofOptions}
              label={"ID Proof*"}
              readOnly
              selectedValue={identificationDetails?.id_proof}
              noSearch
              // handleChange={value =>
              //   setDocuments({
              //     ...documents,
              //     ["ID Proof"]: {
              //       ...(documents["ID Proof"] ? documents["ID Proof"] : {}),
              //       file_type: value,
              //     },
              //   })
              // }
            />
          </UploadWrap>
          <FileInputWrapper className="col-md-6">
            <span className="w-100 file_name_container">
              {docName.id_proof}
              <input
                type="file"
                name="myfile"
                id={docName.id_proof}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    e.target.files[0] &&
                    (!checkSize(e, 5) ||
                      !checkDocType(e, [
                        "jpg",
                        "jpeg",
                        "pdf",
                        "tiff",
                        "bmp",
                        "png",
                      ]))
                  ) {
                    setErrors({
                      ...errors,
                      ["ID Proof"]: "File type or size not allowed",
                    });
                  } else {
                    setDocName(prev => ({
                      ...prev,
                      id_proof: `${appNo}_IdProof_${currentDate}_4.${
                        e.target.files[0]?.name?.split(".")?.reverse()[0]
                      }`,
                    }));
                    setErrors({
                      ...errors,
                      ["ID Proof"]: "",
                    });
                    setDocuments({
                      ...documents,
                      ["ID Proof"]: {
                        ...(documents["ID Proof"] ? documents["ID Proof"] : {}),
                        upload_file: e.target.files[0],
                        upload_file_id: 4,
                        upload_file_name: `${appNo}_IdProof_${currentDate}_4.${
                          e.target.files[0]?.name?.split(".")?.reverse()[0]
                        }`,
                        file_type: identificationDetails?.id_proof,
                      },
                    });
                    setPreviews({
                      ...previews,
                      ["ID Proof"]: {
                        ...(previews["ID Proof"] ? previews["ID Proof"] : {}),
                        blob_file: createPreview(e),
                      },
                    });
                  }
                }}
              />
            </span>
          </FileInputWrapper>

          {!documents["ID Proof"]?.upload_file ? (
            <label className="Choose_file_btn" htmlFor={docName.id_proof}>
              Choose a file
            </label>
          ) : (
            <button
              disabled={disable["ID Proof"] === false}
              className={`col-4 Choose_file_btn btn `}
              onClick={e => {
                return (
                  documents["ID Proof"]?.upload_file?.name === undefined
                    ? null
                    : onSubmit(e, "ID Proof"),
                  setUploaded("ID Proof")
                );
              }}
            >
              {uploaded === "ID Proof" &&
              documents["ID Proof"] &&
              isDocumentUploadLoading ? (
                <ButtonLoader />
              ) : (
                <p id="ID Proof">Upload</p>
              )}
            </button>
          )}

          {previews["ID Proof"]?.blob_file && (
            <a
              className={`col-4 upload_btn btn `}
              href={previews["ID Proof"]?.blob_file}
              target="_blank"
              rel="noopener noreferrer"
            >
              Preview
            </a>
          )}

          {documents["ID Proof"]?.upload_file && (
            <i
              className="fas fa-trash-alt trash-icon"
              onClick={() => removeDocument("ID Proof")}
            />
          )}
        </div>
        {errors["ID Proof"] && <ErrorMsg rel>{errors["ID Proof"]}</ErrorMsg>}
      </UploaderBody>
      {/* ================================= Address ============================ */}
      <UploaderBody
        className="col-md-12 select_wrapper"
        active={documents["Address Proof"]?.upload_file ? true : false}
      >
        <div className="upload__body">
          <UploadWrap>
            <Select
              options={addressProofOptions}
              label={"Address Proof*"}
              readOnly
              selectedValue={identificationDetails?.address_proof}
              noSearch
              // handleChange={value =>
              //   setDocuments({
              //     ...documents,
              //     ["Address Proof"]: {
              //       ...(documents["Address Proof"]
              //         ? documents["Address Proof"]
              //         : {}),
              //       file_type: value,
              //     },
              //   })
              // }
            />
          </UploadWrap>
          <FileInputWrapper className="col-md-6">
            <span className="w-100 file_name_container">
              {docName.address_proof}
              <input
                type="file"
                name="myfile"
                id={docName.address_proof}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    e.target.files[0] &&
                    (!checkSize(e, 5) ||
                      !checkDocType(e, [
                        "jpg",
                        "jpeg",
                        "pdf",
                        "tiff",
                        "bmp",
                        "png",
                      ]))
                  ) {
                    setErrors({
                      ...errors,
                      ["Address Proof"]: "File type or size not allowed",
                    });
                  } else {
                    setDocName(prev => ({
                      ...prev,
                      address_proof: `${appNo}_AddressProof_${currentDate}_3.${
                        e.target.files[0]?.name?.split(".")?.reverse()[0]
                      }`,
                    }));

                    setErrors({
                      ...errors,
                      ["Address Proof"]: "",
                    });
                    setDocuments({
                      ...documents,
                      ["Address Proof"]: {
                        ...(documents["Address Proof"]
                          ? documents["Address Proof"]
                          : {}),
                        upload_file: e.target.files[0],
                        upload_file_id: 3,
                        upload_file_name: `${appNo}_AddressProof_${currentDate}_3.${
                          e.target.files[0]?.name?.split(".")?.reverse()[0]
                        }`,
                        file_type: identificationDetails?.address_proof,
                      },
                    });
                    setPreviews({
                      ...previews,
                      ["Address Proof"]: {
                        ...(previews["Address Proof"]
                          ? previews["Address Proof"]
                          : {}),
                        blob_file: createPreview(e),
                      },
                    });
                  }
                }}
              />
            </span>
          </FileInputWrapper>

          {!documents["Address Proof"]?.upload_file ? (
            <label className="Choose_file_btn" htmlFor={docName.address_proof}>
              Choose a file
            </label>
          ) : (
            <button
              disabled={disable["Address Proof"] === false}
              className={`col-4 Choose_file_btn btn `}
              onClick={e => {
                return (
                  documents["Address Proof"]?.upload_file?.name === undefined
                    ? null
                    : onSubmit(e, "Address Proof"),
                  setUploaded("Address Proof")
                );
              }}
            >
              {uploaded === "Address Proof" && isDocumentUploadLoading ? (
                <ButtonLoader />
              ) : (
                <p id="Address Proof">Upload</p>
              )}
            </button>
          )}

          {previews["Address Proof"]?.blob_file && (
            <a
              className={`col-4 upload_btn btn `}
              href={previews["Address Proof"]?.blob_file}
              target="_blank"
              rel="noopener noreferrer"
            >
              Preview
            </a>
          )}

          {documents["Address Proof"]?.upload_file && (
            <i
              className="fas fa-trash-alt trash-icon"
              onClick={() => removeDocument("Address Proof")}
            />
          )}
        </div>
        {errors["Address Proof"] && (
          <ErrorMsg rel>{errors["Address Proof"]}</ErrorMsg>
        )}
      </UploaderBody>
      {/* ================================= Income ============================ */}
      <UploaderBody
        className="col-md-12 select_wrapper"
        active={documents["Income Proof"]?.upload_file ? true : false}
      >
        <div className="upload__body">
          <UploadWrap>
            <Select
              options={incomeProofOptions}
              label={"Income Proof*"}
              readOnly
              selectedValue={identificationDetails?.income_proof}
              noSearch
              // handleChange={value =>
              //   setDocuments({
              //     ...documents,
              //     ["Income Proof"]: {
              //       ...(documents["Income Proof"]
              //         ? documents["Income Proof"]
              //         : {}),
              //       file_type: identificationDetails?.income_proof,
              //     },
              //   })
              // }
            />
          </UploadWrap>
          <FileInputWrapper className="col-md-6">
            <span className="w-100 file_name_container">
              {docName.income_proof}
              <input
                type="file"
                name="myfile"
                id={docName.income_proof}
                onClick={e => {
                  e.target.value = null;
                }}
                onChange={e => {
                  if (
                    e.target.files[0] &&
                    (!checkSize(e, 5) ||
                      !checkDocType(e, [
                        "jpg",
                        "jpeg",
                        "pdf",
                        "tiff",
                        "bmp",
                        "png",
                      ]))
                  ) {
                    setErrors({
                      ...errors,
                      ["Income Proof"]: "File type or size not allowed",
                    });
                  } else {
                    setDocName(prev => ({
                      ...prev,
                      income_proof: `${appNo}_IncomeProof_${currentDate}_2.${
                        e.target.files[0]?.name?.split(".")?.reverse()[0]
                      }`,
                    }));
                    setErrors({
                      ...errors,
                      ["Income Proof"]: "",
                    });
                    setDocuments({
                      ...documents,
                      ["Income Proof"]: {
                        ...(documents["Income Proof"]
                          ? documents["Income Proof"]
                          : {}),
                        upload_file: e.target.files[0],
                        upload_file_id: 2,
                        upload_file_name: `${appNo}_IncomeProof_${currentDate}_2.${
                          e.target.files[0]?.name?.split(".")?.reverse()[0]
                        }`,
                        file_type: identificationDetails?.income_proof,
                      },
                    });
                    setPreviews({
                      ...previews,
                      ["Income Proof"]: {
                        ...(previews["Income Proof"]
                          ? previews["Income Proof"]
                          : {}),
                        blob_file: createPreview(e),
                      },
                    });
                  }
                }}
              />
            </span>
          </FileInputWrapper>

          {!documents["Income Proof"]?.upload_file ? (
            <label className="Choose_file_btn" htmlFor={docName.income_proof}>
              Choose a file
            </label>
          ) : (
            <button
              disabled={disable["Income Proof"] === false}
              className={`col-4 Choose_file_btn btn `}
              onClick={e => {
                return (
                  documents["Income Proof"]?.upload_file?.name === undefined
                    ? null
                    : onSubmit(e, "Income Proof"),
                  setUploaded("Income Proof")
                );
              }}
            >
              {uploaded === "Income Proof" && isDocumentUploadLoading ? (
                <ButtonLoader />
              ) : (
                <p id="Income Proof">Upload</p>
              )}
            </button>
          )}
          {previews["Income Proof"]?.blob_file && (
            <a
              className={`col-4 upload_btn btn `}
              href={previews["Income Proof"]?.blob_file}
              target="_blank"
              rel="noopener noreferrer"
            >
              Preview
            </a>
          )}
          {documents["Income Proof"]?.upload_file && (
            <i
              className="fas fa-trash-alt trash-icon"
              onClick={() => removeDocument("Income Proof")}
            />
          )}
        </div>
        {errors["Income Proof"] && (
          <ErrorMsg rel>{errors["Income Proof"]}</ErrorMsg>
        )}
      </UploaderBody>
    </div>

    // {/* ================================= FOOTER ============================ */}
    // <div className="text-center panel_fotter">
    //   <p>*All fields are required</p>
    //   <p>*File should not be password protected.</p>
    //   <div className="col-md-4 mx-auto">
    //     <button
    //       disabled={!Object.values(isUploaded).every(item => item)}
    //       onClick={() => {
    //         dispatch(
    //           submitProposalData("", data => {
    //             if (data.redirect_to === "document_upload") {
    //               history.push("/upload");
    //             } else window.open(data.redirect_to, "_self");
    //           }),
    //         );
    //       }}
    //       className="btn"
    //     >
    //       SUBMIT
    //     </button>
    //   </div>
    // </div>
    //   </UploadPanel>
    // </div>
  );
};

export default IciciDocumentUpload;

/* ================================= IT ============================
          <UploaderBody
            className="col-md-12 select_wrapper"
            active={documents["IT Proof"]?.upload_file ? true : false}
          >
            <div className="d-flex align-items-center justify-content-between">
              <UploadWrap>
                <Select
                  options={itProofOptions}
                  label={"IT Proof"}
                  selectedValue={documents["IT Proof"]?.file_type}
                  noSearch
                  handleChange={value =>
                    setDocuments({
                      ...documents,
                      ["IT Proof"]: {
                        ...(documents["IT Proof"]
                          ? documents["IT Proof"]
                          : {}),
                        file_type: value,
                      },
                    })
                  }
                />
              </UploadWrap>
              <FileInputWrapper className="col-md-6">
                <input
                  type="file"
                  name="myfile"
                  id={"it_proof_doc"}
                  onChange={e => {
                    if (
                      !checkSize(e, 5) ||
                      !checkDocType(e, [
                        "jpeg",
                        "pdf",
                        
                        "tif",
                        "tiff",
                        "jpg",
                      ])
                    ) {
                      setErrors({
                        ...errors,
                        ["IT Proof"]: "File type or size not allowed",
                      });
                    } else {
                      setErrors({
                        ...errors,
                        ["IT Proof"]: "",
                      });
                      setDocuments({
                        ...documents,
                        ["IT Proof"]: {
                          ...(documents["IT Proof"]
                            ? documents["IT Proof"]
                            : {}),
                          upload_file: e.target.files[0],
                          upload_file_id: 5,
                          upload_file_name: "it_proof_doc",
                        },
                      });
                    }
                  }}
                />
                <span className="w-100 file_name_container"></span>
              </FileInputWrapper>

              <label className="Choose_file_btn" htmlFor={"it_proof_doc"}>
                Choose a file
              </label>
            </div>
            {errors["IT Proof"] && (
              <ErrorMsg rel>{errors["IT Proof"]}</ErrorMsg>
            )}
            <div className="col-md-12 my-2">
              <button
                className={`w-100 upload_btn btn `}
                onClick={e => {
                  onSubmit(e, "IT Proof");
                }}
              >
                Upload
              </button>
            </div>
          </UploaderBody>*/

/* ================================= Scanned Photograph ============================
          <UploaderBody
            className="col-md-12 select_wrapper"
            active={
              documents["Scanned Photograph"]?.upload_file ? true : false
            }
          >
            <div className="d-flex align-items-center justify-content-between">
              <UploadWrap>
                <Select
                  options={photoOption}
                  label={"Scanned Photograph"}
                  selectedValue={documents["Scanned Photograph"]?.file_type}
                  noSearch
                  handleChange={value =>
                    setDocuments({
                      ...documents,
                      ["Scanned Photograph"]: {
                        ...(documents["Scanned Photograph"]
                          ? documents["Scanned Photograph"]
                          : {}),
                        file_type: value,
                      },
                    })
                  }
                />
              </UploadWrap>
              <FileInputWrapper className="col-md-6">
                <input
                  type="file"
                  name="myfile"
                  id={"scanned_photo_doc"}
                  onChange={e => {
                    if (
                      !checkSize(e, 5) ||
                      !checkDocType(e, [
                        "jpeg",
                        "pdf",
                        
                        "tif",
                        "tiff",
                        "jpg",
                      ])
                    ) {
                      setErrors({
                        ...errors,
                        ["Scanned Photograph"]:
                          "File type or size not allowed",
                      });
                    } else {
                      setErrors({
                        ...errors,
                        ["Scanned Photograph"]: "",
                      });
                      setDocuments({
                        ...documents,
                        ["Scanned Photograph"]: {
                          ...(documents["Scanned Photograph"]
                            ? documents["Scanned Photograph"]
                            : {}),
                          upload_file: e.target.files[0],
                          upload_file_id: 6,
                          upload_file_name: "scanned_photo_doc",
                        },
                      });
                    }
                  }}
                />
                <span className="w-100 file_name_container"></span>
              </FileInputWrapper>

              <label
                className="Choose_file_btn"
                htmlFor={"scanned_photo_doc"}
              >
                Choose a file
              </label>
            </div>
            {errors["Scanned Photograph"] && (
              <ErrorMsg rel>{errors["Scanned Photograph"]}</ErrorMsg>
            )}
            <div className="col-md-12 my-2">
              <button
                className={`w-100 upload_btn btn `}
                onClick={e => {
                  onSubmit(e, "Scanned Photograph");
                }}
              >
                Upload
              </button>
            </div>
          </UploaderBody>*/

/* ================================= Others ============================
        <UploaderBody
          className="col-md-12 select_wrapper"
          active={documents["Others"]?.upload_file ? true : false}
        >
          <div className="d-flex align-items-center justify-content-between">
            <UploadWrap>
              <Select
                options={othersOptions}
                label={"Others"}
                selectedValue={documents["Others"]?.file_type}
                noSearch
                handleChange={value =>
                  setDocuments({
                    ...documents,
                    ["Others"]: {
                      ...(documents["Others"] ? documents["Others"] : {}),
                      file_type: value,
                    },
                  })
                }
              />
            </UploadWrap>
            <FileInputWrapper className="col-md-6">
              <input
                type="file"
                name="myfile"
                id={"other_proof_doc"}
                onChange={e => {
                  if (
                    !checkSize(e, 5) ||
                    !checkDocType(e, [
                      "jpeg",
                      "pdf",
                      
                      "tif",
                      "tiff",
                      "jpg",
                    ])
                  ) {
                    setErrors({
                      ...errors,
                      ["Others"]: "File type or size not allowed",
                    });
                  } else {
                    setErrors({
                      ...errors,
                      ["Others"]: "",
                    });
                    setDocuments({
                      ...documents,
                      ["Others"]: {
                        ...(documents["Others"] ? documents["Others"] : {}),
                        upload_file: e.target.files[0],
                        upload_file_id: 7,
                        upload_file_name: "other_proof_doc",
                      },
                    });
                  }
                }}
              />
              <span className="w-100 file_name_container"></span>
            </FileInputWrapper>

            <label className="Choose_file_btn" htmlFor={"other_proof_doc"}>
              Choose a file
            </label>
          </div>
          {errors["Others"] && <ErrorMsg rel>{errors["Others"]}</ErrorMsg>}
          <div className="col-md-12 my-2">
            <button
              className={`w-100 upload_btn btn `}
              onClick={e => {
                onSubmit(e, "Others");
              }}
            >
              Upload
            </button>
          </div>
        </UploaderBody>*/

/* ================================= Additional ============================
          {count > 0 ? (
            <>
              <UploaderBody
                className="col-md-12 select_wrapper"
                active={
                  documents["Income Proof 1"]?.upload_file ? true : false
                }
              >
                <div className="d-flex align-items-center justify-content-between">
                  <FileInputWrapper className="col-md-6">
                    <span className="w-100 file_name_container">
                      <input
                        type="file"
                        name="myfile"
                        id={"income_proof1_doc"}
                        onChange={e => {
                          if (
                            !checkSize(e, 5) ||
                            !checkDocType(e, [
                              "jpeg",
                              "pdf",
                              
                              "tif",
                              "tiff",
                              "jpg",
                            ])
                          ) {
                            setErrors({
                              ...errors,
                              ["Income Proof 1"]:
                                "File type or size not allowed",
                            });
                          } else {
                            setErrors({
                              ...errors,
                              ["Income Proof 1"]: "",
                            });
                            setDocuments({
                              ...documents,
                              ["Income Proof 1"]: {
                                ...(documents["Income Proof 1"]
                                  ? documents["Income Proof 1"]
                                  : {}),
                                upload_file: e.target.files[0],
                                upload_file_id: 5,
                                upload_file_name: "income_proof1_doc",
                              },
                            });
                          }
                        }}
                      />
                    </span>
                  </FileInputWrapper>

                  <label
                    className="Choose_file_btn"
                    htmlFor={"income_proof1_doc"}
                  >
                    Choose a file
                  </label>
                </div>
                {errors["Income Proof 1"] && (
                  <ErrorMsg rel>{errors["Income Proof 1"]}</ErrorMsg>
                )}
                <div className="col-md-12 my-2">
                  <button
                    className={`w-100 upload_btn btn `}
                    onClick={e => {
                      onSubmit(e, "Income Proof 1");
                    }}
                  >
                    Upload
                  </button>
                </div>
              </UploaderBody>
              {count > 1 ? (
                <UploaderBody
                  className="col-md-12 select_wrapper"
                  active={
                    documents["Income Proof 2"]?.upload_file ? true : false
                  }
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <FileInputWrapper className="col-md-6">
                      <span className="w-100 file_name_container">
                        <input
                          type="file"
                          name="myfile"
                          id={"income_proof2_doc"}
                          onChange={e => {
                            if (
                              !checkSize(e, 5) ||
                              !checkDocType(e, [
                                "jpeg",
                                "pdf",
                                
                                "tif",
                                "tiff",
                                "jpg",
                              ])
                            ) {
                              setErrors({
                                ...errors,
                                ["Income Proof 2"]:
                                  "File type or size not allowed",
                              });
                            } else {
                              setErrors({
                                ...errors,
                                ["Income Proof 2"]: "",
                              });
                              setDocuments({
                                ...documents,
                                ["Income Proof 2"]: {
                                  ...(documents["Income Proof 2"]
                                    ? documents["Income Proof 2"]
                                    : {}),
                                  upload_file: e.target.files[0],
                                  upload_file_id: 6,
                                  upload_file_name: "income_proof2_doc",
                                },
                              });
                            }
                          }}
                        />
                      </span>
                    </FileInputWrapper>

                    <label
                      className="Choose_file_btn"
                      htmlFor={"income_proof2_doc"}
                    >
                      Choose a file
                    </label>
                  </div>
                  {errors["Income Proof 2"] && (
                    <ErrorMsg rel>{errors["Income Proof 2"]}</ErrorMsg>
                  )}
                  <div className="col-md-12 my-2">
                    <button
                      className={`w-100 upload_btn btn `}
                      onClick={e => {
                        onSubmit(e, "Income Proof 2");
                      }}
                    >
                      Upload
                    </button>
                  </div>
                </UploaderBody>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
          {count < 2 && (
            <div className="">
              <button
                style={{
                  width: "40%",
                  textAlign: "center",
                  backgroundColor: "var(--primary-color)",
                  borderRadius: "6px",
                  padding: "11px 20px",
                  cursor: "pointer",
                  display: "inline-block",
                  color: "#fff",
                  fontSize: "14px",
                  border: "1px dotted",
                }}
                onClick={() => {
                  if (count < 2) {
                    setCount(count + 1);
                  }
                }}
              >
                Add Additional Document
              </button>
            </div>
              )} */
