import RiderCardWithoutCovers from "../../../riders/component/RiderCard/RiderCardWithoutCovers";
import "styled-components/macro";
import * as mq from "../../../../styles/mediaQueries";
import {
  // useGetCartQuery,
  // useGetQuoteQuery,
  useGetRiderPremiumMutation,
} from "../../../riders/services/rider";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isCIRider, sendRiderFormat } from "../../../riders/helpers";
import {
  insertAddedRiders,
  removeAddedRiders,
  removeShortName,
  updateRiderListPremium,
  updateRiderShortName,
} from "../../Quotes/quotes.slice";
import { useDidMountEffect } from "../../../../custom-hooks";
function AddonCoverages({ quote, riders, ...props }) {
  const { product } = quote;
  const [hideRiders, setHideRiders] = useState([]);
  const [recalculateRiders, setRecalculateRiders] = useState([]);
  const addToReCalculateRiders = rider_shortname =>
    setRecalculateRiders([...recalculateRiders, ...rider_shortname]);
  const removeFromReCalculateRiders = recalc_riders =>
    setRecalculateRiders(
      recalculateRiders.filter(
        rider => !recalc_riders.includes(rider.rider_shortname),
      ),
    );
  const addToHideRiders = rider_shortname =>
    setHideRiders([...hideRiders, ...rider_shortname]);
  // const dispatch = useDispatch();
  const removeFromHideRiders = rider_shortname =>
    setHideRiders(hideRiders.filter(rider => rider_shortname === rider));

  return (
    <div
      css={`
        width: 90%;
        ${mq.sm} {
          width: 100%;
        }
      `}
      {...props}
    >
      <ul
        css={`
          padding: 0;
          list-style: none;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 0.6em;
          margin: 10px auto 40px auto;

          ${mq.sm} {
            flex-direction: column;
          }
        `}
      >
        {riders[product.id] ? (
          riders[product.id].map(rider => (
            <li
              key={rider.rider_shortname}
              css={`
                flex: 0 0 calc(50% - 0.3em);
                display: flex;
                ${mq.sm} {
                }
              `}
            >
              {rider.rider_type !== "Free" && (
                <KnowMoreRiderCard
                  item={rider}
                  productId={product.id}
                  quote={quote}
                  hideRiders={hideRiders}
                  addToHideRiders={addToHideRiders}
                  removeFromHideRiders={removeFromHideRiders}
                  recalculateRiders={recalculateRiders}
                  addToReCalculateRiders={addToReCalculateRiders}
                  removeFromReCalculateRiders={removeFromReCalculateRiders}
                />
              )}
            </li>
          ))
        ) : (
          <div
            style={{ fontFamily: "Inter", fontWeight: "400", fontSize: "18px" }}
          >
            No Addon Available for this Product.
          </div>
        )}
      </ul>
    </div>
  );
}

function KnowMoreRiderCard({
  item,
  quote,
  productId,
  hideRiders,
  addToHideRiders,
  removeFromReCalculateRiders,
  removeFromHideRiders,
  recalculateRiders,
  addToReCalculateRiders,
  onPremiumClick,
  ...props
}) {
  const [getRiderPremium, { isLoading: isRiderPremiumLoading }] =
    useGetRiderPremiumMutation();

  const {
    addedRiders,
    addedShortNames,
    shortNameToRemove,
    isDiabetic,
    ciVariant,
  } = useSelector(state => state.quotes);

  const [finalRider, setFinalRider] = useState(item);
  const { rider_shortname } = finalRider;
  const [CIVariant, setCIVariant] = useState(isCIRider(item) ? 22 : undefined);
  const dispatch = useDispatch();

  useEffect(() => {
    setFinalRider(item);
  }, [item]);

  const rider = sendRiderFormat({
    policy_term: item.default_policy_term,
    sum_assured: item.default_sum_assured,
    premiumPayingTerm: item.default_ppt,
    extra_input: { ci_variant: isCIRider(item) ? ciVariant : CIVariant },
    ...item,
  });

  useEffect(() => {
    if (
      productId !== addedShortNames.lastFetched &&
      addedShortNames.data.includes(rider_shortname)
    ) {
      if (item.rider_type === "Free") return;
      if (!isRiderAdded(rider)) add(rider);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedShortNames, productId, item, rider_shortname]);

  useEffect(() => {
    if (shortNameToRemove === rider_shortname) {
      remove(rider);
      dispatch(removeShortName(""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shortNameToRemove, rider_shortname]);

  useEffect(() => {
    if (hideRiders.includes(rider_shortname) && isRiderAdded(rider)) {
      remove(rider);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideRiders]);

  const updatePremium = useCallback(() => {
    getRiderPremium({
      termInsuranceId: quote.term_insurance_id,
      productId,
      riders: [
        {
          rider_shortname,
          rider_id: item.rider_id,
          sum_assured: item.default_sum_assured,
          policy_term: item.default_policy_term,
          premium_payment_term: item.default_ppt,
          total_premium: finalRider.net_premium,
          extra_input: { ci_variant: isCIRider(item) ? ciVariant : CIVariant },
          min_cover_amount: finalRider?.min_cover_amount,
          max_cover_amount: finalRider?.max_cover_amount,
        },
      ],
      addedRiders: addedRiders[productId]
        ? addedRiders[productId].map(item => ({
            total_premium: item.total_premium,
            rider_shortname: item.rider_shortname,
            rider_id: item.rider_id,
            sum_assured: item.term_sum_assured,
            policy_term: item.term_policy_term,
            premium_payment_term: item.term_limited_pay_term,
            premium: item.premium,
            extra_input: {
              ci_variant: isCIRider(item) ? ciVariant : CIVariant,
            },
            service_tax: item.service_tax,
          }))
        : [],
    }).then(res => {
      if (res.error) {
        return;
      }
      const {
        data: {
          data: [newRider],
        },
      } = res;
      if (newRider) setFinalRider(newRider);

      dispatch(
        updateRiderListPremium({
          rider_id: item.rider_id,
          data: newRider,
          productId,
        }),
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedRiders[productId], CIVariant]);

  useEffect(() => {
    if (recalculateRiders.includes(rider_shortname)) updatePremium();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recalculateRiders, rider_shortname]);

  const isRiderAdded = rider => {
    if (!addedRiders[productId]) {
      return false;
    } else {
      const isAdded = !!addedRiders[productId].find(
        item => item.id === rider.id,
      );
      return isAdded;
    }
  };

  useEffect(() => {
    if (
      isRiderAdded(rider) &&
      isDiabetic &&
      quote.company_alias === "max_life" &&
      rider.rider_shortname !== "ADB"
    ) {
      remove(rider);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRiderAdded, isDiabetic, rider]);

  useEffect(() => {
    if (isAdded && item.hide_riders) {
      addToHideRiders(item.hide_riders);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const add = rider => {
    if (!addedShortNames.data.includes(rider_shortname))
      dispatch(
        updateRiderShortName({
          data: [...addedShortNames.data, rider_shortname],
          productId: productId,
        }),
      );
    dispatch(insertAddedRiders({ productId, rider }));
    if (item.hide_riders) {
      addToHideRiders(item.hide_riders);
    }
    if (item.recalc_rider) {
      addToReCalculateRiders(item.recalc_rider);
    }
  };

  const remove = rider => {
    if (item.recalc_rider) {
      removeFromReCalculateRiders(item.recalc_rider);
    }
    // if (shortNameToRemove === rider_shortname) {
    //   dispatch(removeShortName(rider_shortname));
    // }
    dispatch(removeShortName(rider_shortname));
    if (item.hide_riders) {
      removeFromHideRiders(item.hide_riders);
    }
    dispatch(
      removeAddedRiders({
        id: rider.id,
        productId,
      }),
    );
    return;
  };
  const isAdded = isRiderAdded(rider);

  const onHandleAddOrRemove = rider => {
    if (isRiderAdded(rider)) {
      remove(rider);
    } else add(rider);
  };
  useDidMountEffect(() => {
    updatePremium();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CIVariant]);

  const isHidden = hideRiders.includes(rider_shortname);
  // if (isHidden) return <></>;
  return (
    <RiderCardWithoutCovers
      rider={finalRider}
      quote={quote}
      onPremiumClick={() => (isHidden ? null : onHandleAddOrRemove(rider))}
      isAdded={isAdded}
      isHidden={isHidden}
      isRiderPremiumLoading={isRiderPremiumLoading}
      CIVariant={CIVariant}
      setCIVariant={setCIVariant}
      {...props}
    />
  );
}

export default AddonCoverages;
