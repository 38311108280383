import "./App.scss";
import { useEffect, useState, Suspense, lazy } from "react";
import * as mq from "../src/styles/mediaQueries";
import KnowYourPlanNew from "./components/KnowMorePopup/KnowMorePopup";
import ComparePage from "./pages/compare/ComparePage";
import DocumentUploadPage from "./pages/DocumentUpload-page/DocumentUploadPage";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ThankYouPage from "./pages/ThankYou/ThankYouPage";
import { fetchFrontendData } from "./pages/frontendBoot/frontendBoot.slice";
import { useDispatch, useSelector } from "react-redux";
import Confirm from "./pages/MedicalConfirm/Confirm";
import { FullScreenLoader } from "./components";
import logo from "./assets/images/logo/logo.png";
import PaymentStatusPage from "./pages/PaymentStatus/PaymentStatusPage";
import Landing from "./pages/Landing/Landing";
import TataDocumentUpload from "./pages/ProposalPage/companies/TataAia/Document/TataDocumentUpload";
import { Dialog } from "@material-ui/core";
import "styled-components/macro";
import { ToastContainer } from "react-toastify";
import TermLifeInsurance from "./pages/product-page/Quotes/TermLifeInsurance";
import RidersInformation from "./pages/product-page/Quotes/RidersInformation";
import RedirectionPage from "./pages/redirection/RedirectionPage";
import AgentConfidential from "./pages/agent-confidential/AgentConfidential";
import {
  Backdrop,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { ModalBodyContent } from "./components/navbar/TalkToUsBtn-component";
import NewCompare from "./pages/NewCompare/NewCompare";
const QuotesPage = lazy(() => import("./pages/product-page/QuotesPage"));
const RiderPage = lazy(() => import("./pages/riders/RiderPage"));
const ProposalPage = lazy(() => import("./pages/ProposalPage/ProposalPage"));
const ShortlistedQuotesPage = lazy(() =>
  import(
    "./pages/product-page/components/ShortlistedQuotes/ShortlistedQuotesPage"
  ),
);

function App() {
  const [isActive, setIsActive] = useState(false);
  const [open, setOpen] = useState(false);
  const { isLoading } = useSelector(state => state.frontendBoot);
  const { loading } = useSelector(state => state.inputForm);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchFrontendData());
  }, [dispatch]);

  if (process.env.NODE_ENV === "production") {
    console.log = () => {};
    console.error = () => {};
    console.warn = () => {};
    console.debug = () => {};
  }

  const colorPickerTrigger = e => {
    if (e.ctrlKey && e.altKey && e.which === 67) {
      setShowColorPicker(true);
    }
  };
  const [showColorPicker, setShowColorPicker] = useState(false);
  const onClose = () => {
    setShowColorPicker(false);
  };
  useEffect(() => {
    window.addEventListener("keydown", colorPickerTrigger);
    return () => {
      window.removeEventListener("keydown", colorPickerTrigger);
    };
  }, []);
  const vars = [
    "--primary-color",
    "--primary-color-light",
    "--secondary-color",
    "--secondary-color-hover",
    "--primary-shade",
    "--secondary-shade",
    "--primary-color-dark",
  ];
  if (isLoading || loading) return <FullScreenLoader />;

  const handleClose = () => {
    setIsActive(false);
  };

  const handleCloseTalkToUs = () => setOpen(false);

  return (
    <>
      <div className="app">
        <Router>
          <Switch>
            <Route exact path="/">
              <Landing />
            </Route>
            <Route exact path="/compare">
              <ComparePage />
            </Route>
            <Route exact path="/know-your-plan">
              <KnowYourPlanNew />
            </Route>
            <Route exact path="/riders">
              <Suspense fallback={<FullScreenLoader />}>
                <RiderPage />
              </Suspense>
            </Route>
            <Route exact path="/quote">
              <Suspense fallback={<FullScreenLoader />}>
                <QuotesPage />
              </Suspense>
            </Route>
            <Route exact path="/proposal">
              <Suspense fallback={<FullScreenLoader />}>
                <ProposalPage />
              </Suspense>
            </Route>
            <Route exact path="/thankyou">
              <ThankYouPage />
            </Route>
            <Route exact path="/redirection">
              <RedirectionPage />
            </Route>
            <Route exact path="/document_upload">
              <DocumentUploadPage />
            </Route>
            <Route exact path="/tata_document_upload">
              <TataDocumentUpload />
            </Route>
            <Route exact path="/shortlisted-quote">
              <Suspense fallback={<FullScreenLoader />}>
                <ShortlistedQuotesPage />
              </Suspense>
            </Route>
            <Route exact path="/insurance-information">
              <TermLifeInsurance />
            </Route>
            <Route exact path="/riders-information">
              <RidersInformation />
            </Route>
            <Route exact path="/confirm">
              <Confirm />
            </Route>
            <Route exact path="/agent-confidential-report">
              <AgentConfidential />
            </Route>
            <Route exact path="/success">
              <PaymentStatusPage />
            </Route>
            <Route exact path="/new-compare">
              <NewCompare />
            </Route>
          </Switch>
        </Router>
        <Dialog
          open={showColorPicker}
          onClose={onClose}
          fullWidth
          maxWidth="xs"
        >
          <div
            css={`
              display: flex;
              flex-direction: column;
              padding: 16px;
            `}
          >
            {vars.map((item, i) => (
              <>
                <div
                  key={i}
                  css={`
                    display: flex;
                    align-items: center;
                    margin-bottom: 8px;
                    & > div,
                    & > input {
                      flex: 1 1;
                    }
                  `}
                >
                  <div
                    css={`
                      color: #111;
                      font-size: 16px;
                    `}
                  >
                    {item}
                  </div>{" "}
                  <input
                    type="color"
                    value={getComputedStyle(document.body)
                      .getPropertyValue(item)
                      .trim()}
                    onChange={e =>
                      document
                        .querySelector(":root")
                        .style.setProperty(item, e.target.value)
                    }
                  />
                </div>
              </>
            ))}
          </div>
        </Dialog>
        <ToastContainer />
        {isActive && (
          <Backdrop
            style={{
              backgroundColor: "black",
              opacity: 0.1,
              color: "white",
            }}
            // sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
            open={isActive}
            onClick={handleClose}
          >
            <Dialog
              open={isActive}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              keepMounted
              maxWidth="sm"
              fullWidth
            >
              <DialogContent style={{ textAlign: "center" }}>
                <DialogContentText id="alert-dialog-description">
                  <Stack
                    direction="column"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img
                      src={logo}
                      alt="logo"
                      style={{
                        width: "50%",
                        height: "auto",
                      }}
                    />
                    <Stack
                      direction="column"
                      spacing={0}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "bold",
                        }}
                      >
                        Hello you seem to be inactive.
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "1rem",
                        }}
                      >
                        Need help choosing the best plan ? Talk to our experts.
                      </Typography>
                    </Stack>
                  </Stack>
                </DialogContentText>
              </DialogContent>
              <DialogActionsStyled>
                <Button
                  variant="contained"
                  onClick={handleClose}
                  style={{
                    textTransform: "capitalize",
                  }}
                >
                  No,Thanks
                </Button>

                <Button
                  variant="contained"
                  style={{
                    textTransform: "capitalize",
                  }}
                  onClick={e => {
                    // e.stoppropagation();
                    setOpen(true);
                    handleClose();
                  }}
                >
                  Talk to Experts
                </Button>
              </DialogActionsStyled>
            </Dialog>
          </Backdrop>
        )}
      </div>
      <ModalBodyContent show={open} handleClose={handleCloseTalkToUs} />
      {/* <CheckConnection/> */}
    </>
  );
}

export default App;

const DialogActionsStyled = styled(DialogActions)`
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
  ${mq.sm} {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    padding-bottom: 1rem;
  }
`;
