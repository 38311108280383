import React, { useEffect, useRef } from "react";
import AboutCompany from "../AboutCompany/AboutCompany";
import AdditionalFeatures from "../AdditionalFeatures/AdditionalFeatures";
import AddonCoveragesWrap from "../AddonCoverages/AddonCoveragesWrap";
import ClaimProcess from "../ClaimProcess/ClaimProcess";
import PlanDetails from "../PlanDetails/PlanDetails";
import "styled-components/macro";
import * as mq from "../../../../styles/mediaQueries";

const MainContent = ({ setCurrentSection, currentSection }) => {
  const prevNum = useRef();
  React.useEffect(() => {
    prevNum.current = currentSection;
  }, [currentSection]);

  const [swipe, setSwipe] = React.useState("forward");
  const [state, setState] = React.useState(350);
  // const swipe = "forward";
  //****************** not used***********************/

  // const [touchStart, setTouchStart] = React.useState(0);
  // const [touchEnd, setTouchEnd] = React.useState(0);
  // useEffect(() => {
  //   if (touchStart > 350 && touchStart > touchEnd && currentSection !== 4 && touchEnd != 0) {
  //     setCurrentSection(currentSection + 1)
  //   } else if (touchStart < touchEnd && currentSection !== 0) {
  //     setCurrentSection(currentSection - 1)
  //   }
  // }, [touchEnd, touchStart])
  // function handleTouchStart(e) {
  //   setTouchStart(e.targetTouches[0].clientX);
  // }

  // function handleTouchMove(e) {
  //   setTouchEnd(e.targetTouches[0].clientX);
  // }

  // function handleTouchEnd(e) {
  //   e.preventDefault()
  //   e.target.click && e.target.click()
  //   if ((touchStart > 100) && (touchStart > touchEnd)) {
  //     if (currentSection !== 4) {
  //       setCurrentSection(currentSection + 1);
  //       setSwipe("forward");
  //     }
  //   }
  //   if (touchStart - touchEnd < 100 && touchStart < touchEnd) {
  //     if (currentSection !== 0) {
  //       setCurrentSection(currentSection - 1);
  //       setSwipe("backward");
  //     }
  //   }
  // }
  const [initialTouch, setInitialTouch] = React.useState(null);
  const distance = state - initialTouch;
  const touchVal = Math.abs(distance) > 20;
  useEffect(() => {
    if (Math.abs(distance) > 20) {
      setInitialTouch(null);
      if (distance > 0) {
        if (currentSection !== 0) {
          setCurrentSection(currentSection - 1);
          setSwipe("backward");
        }
      } else {
        if (currentSection !== 4) {
          setCurrentSection(currentSection + 1);
          setSwipe("forward");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touchVal]);
  function handleTouchStart(e) {
    setInitialTouch(e.touches[0].clientX);
  }

  function handleTouchMove(e) {
    if (!initialTouch) return;

    const currentTouch = e.touches[0].clientX;
    setState(currentTouch);
    // If the user moves more than 20 pixels in any direction, consider it a swipe
  }

  function handleTouchEnd(e) {
    if (!initialTouch) return;

    setInitialTouch(null);
  }
  if (currentSection === 0)
    return (
      <div
        className={swipe}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
        css={`
          ${mq.sm} {
            animation-duration: 0.45s;
            animation-name: ${currentSection < prevNum.current
              ? "slideLeft"
              : "slideRight"};
            @keyframes slideLeft {
              0% {
                transform: translateX(-300px);
              }

              100% {
                transform: translate(0);
              }
            }

            @keyframes slideRight {
              0% {
                transform: translateX(300px);
              }

              100% {
                transform: translate(0);
              }
            }
          }
        `}
      >
        <PlanDetails />
      </div>
    );

  if (currentSection === 1)
    return (
      <div
        className={swipe}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
        css={`
          ${mq.sm} {
            animation-duration: 0.45s;
            animation-name: ${currentSection < prevNum.current
              ? "slideLeft"
              : "slideRight"};

            @keyframes slideLeft {
              0% {
                transform: translateX(-300px);
              }

              100% {
                transform: translate(0);
              }
            }

            @keyframes slideRight {
              0% {
                transform: translateX(300px);
              }

              100% {
                transform: translate(0);
              }
            }
          }
        `}
      >
        <AddonCoveragesWrap />
      </div>
    );
  if (currentSection === 2)
    return (
      <div
        className={swipe}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
        css={`
          ${mq.sm} {
            animation-duration: 0.45s;
            animation-name: ${currentSection < prevNum.current
              ? "slideLeft"
              : "slideRight"};

            @keyframes slideLeft {
              0% {
                transform: translateX(-300px);
              }

              100% {
                transform: translate(0);
              }
            }

            @keyframes slideRight {
              0% {
                transform: translateX(300px);
              }

              100% {
                transform: translate(0);
              }
            }
          }
        `}
      >
        <AdditionalFeatures />
      </div>
    );
  if (currentSection === 3)
    return (
      <div
        className={swipe}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
        css={`
          ${mq.sm} {
            animation-duration: 0.45s;
            animation-name: ${currentSection < prevNum.current
              ? "slideLeft"
              : "slideRight"};

            @keyframes slideLeft {
              0% {
                transform: translateX(-300px);
              }

              100% {
                transform: translate(0);
              }
            }

            @keyframes slideRight {
              0% {
                transform: translateX(300px);
              }

              100% {
                transform: translate(0);
              }
            }
          }
        `}
      >
        <ClaimProcess />
      </div>
    );
  if (currentSection === 4)
    return (
      <div
        className={swipe}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
        css={`
          ${mq.sm} {
            animation-duration: 0.45s;
            animation-name: ${currentSection < prevNum.current
              ? "slideLeft"
              : "slideRight"};

            @keyframes slideLeft {
              0% {
                transform: translateX(-300px);
              }

              100% {
                transform: translate(0);
              }
            }

            @keyframes slideRight {
              0% {
                transform: translateX(300px);
              }

              100% {
                transform: translate(0);
              }
            }
          }
        `}
      >
        <AboutCompany />
      </div>
    );

  return <></>;
};

export default MainContent;
